import {
  createTheme,
  createThemedStyled,
  createThemedUseStyletron,
  createThemedWithStyle,
} from 'baseui';
import { Theme } from 'baseui/theme';

const primitives = {};

const cannonSafeColorConstants = {
  cannonColors: {
    // Primary colors
    primaryBlueDark: '#0A1738',
    primaryBlue: '#001F54',
    primaryBlueBg: '#0a1738',
    primaryBlueLight: '#034078',
    primaryGreenDark: '#2f3a19',
    primaryGreen: '#4C5B2F',
    primaryGreenLight: '#717744',
    primaryBone: '#EFE8C7',
    primaryBoneDark: '#111111',
    primaryBoneLight: '#F6F2DF',
    primaryBoneClear: '#E3D9A0',
    primaryGray: '#232323',
    primaryGrayLight: '#383838',
    primaryWhite: '#FBFCFE',
    primaryWhiteLight: '#FFFFFF',
    primaryWhiteDark: '#F4F6FB',
    primarySolidBlack: '#000000',
    // Secondary colors
    secondaryBlueDark: '#273D3F',
    secondaryBlue: '#39595D',
    secondaryBlueLight: '#4F7C82',
    secondaryCarmineDark: '#6B2924',
    secondaryCarmine: '#933831',
    secondaryCarmineLight: '#B44941',
    secondarySilverDark: '#565A61',
    secondarySilver: '#6E737C',
    secondarySilverLight: '#9EA2A9',
    secondaryOrangeDark: '#A25820',
    secondaryOrange: '#BD5800',
    secondaryOrangeLight: '#D88748',
    secondaryBrownDark: '#3D230A',
    secondaryBrown: '#5D350F',
    secondaryBrownLight: '#774413',
    //Transparent Colors
    transparentBlue: '#001F5410',
    transparentOrange: '#BD580010',
    transparentBone: '#EFE8C710',
    transparentWhite: '#FBFCFE10',
    //Extra colors
    secondaryExtraGrayLight: '#EEEFF0',
    secondaryBlackLight: '#4E4E4E',
  },
};

// THEME TODO: Temptative inclucion colors
// #F4F6FB
// #4E4E4E

const overrides = {};

const defaultTheme = createTheme(primitives, overrides);

export interface CannonSafeThemeT extends Theme {
  cannonColors: {
    // Primary colors
    primaryBlueDark: string;
    primaryBlue: string;
    primaryBlueBg: string;
    primaryBlueLight: string;
    primaryGreenDark: string;
    primaryGreen: string;
    primaryGreenLight: string;
    primaryBone: string;
    primaryBoneDark: string;
    primaryBoneLight: string;
    primaryBoneClear: string;
    primaryGray: string;
    primaryGrayLight: string;
    primaryWhite: string;
    primaryWhiteLight: string;
    primaryWhiteDark: string;
    primarySolidBlack: string;
    // Secondary colors
    secondaryBlueDark: string;
    secondaryBlue: string;
    secondaryBlueLight: string;
    secondaryCarmineDark: string;
    secondaryCarmine: string;
    secondaryCarmineLight: string;
    secondarySilverDark: string;
    secondarySilver: string;
    secondarySilverLight: string;
    secondaryOrangeDark: string;
    secondaryOrange: string;
    secondaryOrangeLight: string;
    secondaryBrownDark: string;
    secondaryBrown: string;
    secondaryBrownLight: string;
    //Transparent colors
    transparentBlue: string;
    transparentOrange: string;
    transparentBone: string;
    transparentWhite: string;
    // Extra colors
    secondaryExtraGrayLight: string;
    secondaryBlackLight: string;
  };
}

const cannonSafeTheme: CannonSafeThemeT = {
  ...defaultTheme,
  ...cannonSafeColorConstants,
};

export const cannonStyled = createThemedStyled<CannonSafeThemeT>();

export const cannonWithStyle = createThemedWithStyle<CannonSafeThemeT>();

export const useCannonStyletron = createThemedUseStyletron<CannonSafeThemeT>();

export default cannonSafeTheme as CannonSafeThemeT;
