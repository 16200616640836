import React from 'react';

import { Breadcrumbs } from 'baseui/breadcrumbs';
import { ResponsiveContainer } from '../Container';

import { cannonStyled } from '../../theme';
import { StyledLink } from 'baseui/link';

const Container = cannonStyled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const BreadCrumbResponsiveContainer = cannonStyled(ResponsiveContainer, {
  alignItems: 'flex-start',
  marginTop: '30px',
});

const BreadCrumbStyled = cannonStyled(StyledLink, (props: any) => ({
  textDecoration: 'none !important',
  color: '#4e4e4e !important',
  fontFamily: 'Titillium Web !important',
  fontSize: '16px !important',
  fontWeight: 400,
  cursor: props.href.length > 0 ? 'pointer' : 'not-allowed',
}));

interface IBreadCrumb {
  pages: Array<{ title: string; href: string }>;
}

export default (props: IBreadCrumb) => (
  <Container>
    <BreadCrumbResponsiveContainer flexDirection='column'>
      <Breadcrumbs>
        {props.pages.map((page) => (
          <BreadCrumbStyled
            onClick={(e) => {
              return page.href.length > 0 ? {} : e.preventDefault();
            }}
            key={page.href}
            href={page.href}
          >
            {page.title}
          </BreadCrumbStyled>
        ))}
      </Breadcrumbs>
    </BreadCrumbResponsiveContainer>
  </Container>
);
