import React, { useEffect, useState } from 'react';
import { cannonStyled, NSH44, TWH4, useCannonStyletron } from '../../../theme';
import useFindYourSafe from '../useFindYourSafe';

const ChoosingWrapper = cannonStyled('div', {
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const LoadingResults = () => {
  const [css, theme] = useCannonStyletron();
  const { toggleOverlay, clearParams } = useFindYourSafe();
  const [dots, setDots] = useState(1);

  useEffect(() => {
    const interval = setInterval(
      () => setDots((currentDots) => (currentDots === 3 ? 1 : currentDots + 1)),
      400
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <ChoosingWrapper>
      <NSH44
        align='center'
        color={theme.cannonColors.primaryWhite}
        weight={900}
        $style={{ marginBottom: '10px' }}
      >
        Choosing your safe{'.'.repeat(dots)}
      </NSH44>
      <TWH4 align='center' color={theme.cannonColors.primaryWhite} weight={200}>
        We're almost done selecting the best option for you
      </TWH4>
    </ChoosingWrapper>
  );
};

export default LoadingResults;