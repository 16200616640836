import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cannonStyled, NSH1, useCannonStyletron } from '../../../theme';
import { useTableOrMobile } from '../../../hooks';
import { ICannonElMobile } from '../../../@types';
import CurrentStep from './CurrentStep';
import GoBackButton from '../GoBackButton';
import useFindYourSafe from '../useFindYourSafe';
import CloseButton from '../CloseButton';
import ButtonsWrapper from '../ButtonsWrapper';

const QuizWrapper = cannonStyled('div', (props: ICannonElMobile) => ({
  width: '100%',
  height: props.$isMobile ? 'auto' : '100%',
  marginTop: props.$isMobile ? '45px' : '70px',
  paddingBottom: props.$isMobile ? '40px' : 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ContinueWrapper = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const ContinueButton = cannonStyled('button', (props: ICannonElMobile) => ({
  padding: '12px 18px',
  background: '#C86D28',
  borderRadius: '4px',
  border: 'none',
  marginBottom: props.$isMobile ? '5px' : '24px',
  color: '#FBFCFE',
  cursor: 'pointer',
  fontFamily: 'Titillium Web',
  marginTop: props.$isMobile ? '16px' : 0,
}));

const FindYourSafeQuiz: React.FC = () => {
  const {
    importantItem,
    importantProtection,
    priceRange,
    fireArmCapacity,
    setYourSafeContext,
  } = useFindYourSafe();
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const [step, setStep] = useState(0);
  const [answer, setAnswer] = useState([]);
  const [showContinueBtn, setShowContinueBtn] = useState(false);

  const whenStepChange = useMemo(() => {
    if (isMobile) {
      if (step === 0) {
        if (importantItem != '') {
          setAnswer([importantItem]);
        }
        return answer;
      } else if (step === 1) {
        if (importantProtection != '') {
          setAnswer([importantItem, importantProtection]);
        }
        return answer;
      } else if (step === 2) {
        if (priceRange != '') {
          setAnswer([importantItem, importantProtection, priceRange]);
        }
        return answer;
      } else if (step === 3) {
        if (fireArmCapacity != '') {
          setAnswer([
            importantItem,
            importantProtection,
            priceRange,
            fireArmCapacity,
          ]);
        }
        return answer;
      }
    } else {
      if (step < 3) {
        setAnswer([
          importantItem,
          importantProtection,
          priceRange,
          fireArmCapacity,
        ]);
        return answer;
      } else if (step === 4) {
        return fireArmCapacity;
      }
    }
  }, [importantItem, importantProtection, priceRange, fireArmCapacity, step]);

  const handleContinue = useCallback(() => {
    if (isMobile) {
      if (step < 2 || (step == 2 && importantItem === 'firearms')) {
        setStep((s) => s + 1);
      } else {
        setYourSafeContext('searchResults', true);
      }
    } else {
      if (importantItem === 'firearms' && step < 3) {
        setStep(4); // firearms capacity step
      } else {
        setYourSafeContext('searchResults', true);
      }
    }
  }, [importantItem, fireArmCapacity, step, isMobile]);

  const handleBack = () => {
    setStep((s) => s - 1);
  };

  useEffect(() => {
    let show = isMobile && answer.filter((i) => i != '').length > 0
    ? true
    : !isMobile && answer.filter((i) => i != '').length > 2
    ? true
    : false;
    setShowContinueBtn(show)
  }, [answer, isMobile]);

  return (
    <QuizWrapper $isMobile={isMobile}>
      <ButtonsWrapper>
        {step !== 0 && (
          <GoBackButton
            text={isMobile ? 'Previous' : 'Go back'}
            handleBack={handleBack}
            fixed={isMobile}
          />
        )}
        <CloseButton fixed={isMobile} />
      </ButtonsWrapper>
      <NSH1
        align='center'
        color={theme.cannonColors.primaryWhite}
        weight={900}
        $style={{ marginBottom: isMobile ? '30px' : '50px' }}
      >
        Find your Safe
      </NSH1>

      <CurrentStep step={step} isMobile={isMobile} />

      {showContinueBtn && (
        <ContinueWrapper>
          <ContinueButton onClick={handleContinue} $isMobile={isMobile}>
            Continue
          </ContinueButton>
        </ContinueWrapper>
      )}
    </QuizWrapper>
  );
};

export default FindYourSafeQuiz;
