import React from 'react';

import { Block } from 'baseui/block';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { Button, SHAPE } from 'baseui/button';
import InfoTooltip from '../../assets/info-tooltip.svg';
import { cannonStyled, useCannonStyletron } from '../../theme';

const MainFlex = cannonStyled('div', () => ({
  display: 'inline-flex',
  flexDirection: 'row',
  margin: 0,
  padding: 0,
  height: '15px',
  justifyContent: 'left',
  alignItems: 'center',
}));

export default () => {
  const [css, theme] = useCannonStyletron();

  return (
    <MainFlex>
      <StatefulPopover
        content={() => (
          <Block padding={'20px'}>
            The serial number is located on the shipping carton and in the top
            left corner of the safe’s right-side panel.
          </Block>
        )}
        placement={PLACEMENT.right}
        triggerType={TRIGGER_TYPE.hover}
        returnFocus
        showArrow
        autoFocus
        overrides={{
          Arrow: {
            style: { backgroundColor: theme.cannonColors.primaryBlue },
          },
          Inner: {
            style: () => ({
              backgroundColor: theme.cannonColors.primaryBlue,
              borderRadius: '8px',
              color: theme.cannonColors.primaryWhite,
              fontFamily: 'Titillium Web',
              fontSize: '12px',
              width: '220px',
              textAlign: 'justify',
            }),
          },
        }}
      >
        <Button
          onClick={(e) => e.preventDefault()}
          shape={SHAPE.circle}
          overrides={{
            BaseButton: {
              style: {
                padding: 0,
                margin: '0 8px',
                height: '14px',
                width: '14px',
              },
            },
          }}
        >
          <InfoTooltip />
        </Button>
      </StatefulPopover>
    </MainFlex>
  );
};
