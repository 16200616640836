import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query {
    strapiContact {
      id
      address
      description
      email
      telephone
      title
      footerContactDescription
    }
  }
`;

interface IColInfoResponse {
  strapiContact: {
    title: string;
    description: string;
    telephone: string;
    email: string;
    address: string;
    footerContactDescription: string;
  };
}

export const useCInfo = (): IColInfoResponse => useStaticQuery(query);
