import React from 'react';
import {
  cannonStyled,
  NSH1,
  TWParagraph3,
  useCannonStyletron,
} from '../../theme';
import { StarRating } from 'baseui/rating';
import { H1, Paragraph3, Paragraph4 } from 'baseui/typography';
import { useOrientation, useTableOrMobile } from '../../hooks';
import { StarRatingFn } from '..';

const ReviewContainerBase = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: '0 0 40%',
});

const TitleReviewContainer = cannonStyled(ReviewContainerBase, {
  justifyContent: 'center',
  alignItems: 'center',
});

const CardReviewContainer = cannonStyled(
  ReviewContainerBase,
  ({ isMobile, isRotated }: { isMobile: boolean; isRotated: boolean }) => ({
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '40px',
    padding: !isMobile
      ? '0px 20px 0px 20px'
      : !isMobile && isRotated
      ? '0px 0px 80px 0px'
      : '',
  })
);

interface IReviewCard {
  title: string;
  description: string;
  rating: number;
}

export const ReviewCard = (props: IReviewCard) => {
  const [css, theme] = useCannonStyletron();

  const [isMobile] = useTableOrMobile();
  const { isRotated } = useOrientation();

  return (
    <CardReviewContainer isMobile={isMobile} isRotated={isRotated}>
      <StarRatingFn ratingValue={props.rating} />
      <TWParagraph3
        style={{
          fontSize: isMobile ? '19px' : '24px',
          margin: '8px 0px 8px 0px',
        }}
        align='left'
        weight={700}
        color={theme.cannonColors.primaryBlue}
        margin={0}
      >
        {props.title}
      </TWParagraph3>
      <TWParagraph3
        style={{ fontSize: isMobile ? '17px' : '18px' }}
        align='left'
        weight={400}
        color={theme.cannonColors.primaryGray}
        margin={0}
      >
        {props.description}
      </TWParagraph3>
    </CardReviewContainer>
  );
};

interface ITitleReviewCard {
  title: string;
  description: string;
  rating: number;
}

export const TitleReviewCard = (props: ITitleReviewCard) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  return (
    <TitleReviewContainer>
      <NSH1 color='#111111' weight={700} align='center'>
        {props.title.replace(/ /g, ' ')}
      </NSH1>
      <StarRatingFn
        size={!isMobile ? '32px' : '23px'}
        ratingValue={props.rating}
      />
      <Paragraph4
        $style={{
          fontFamily: 'Titillium Web !important',
          fontSize: isMobile ? '14.5' : '18px',
        }}
        color={theme.cannonColors.secondarySilver}
        margin={0}
      >
        {props.description}
      </Paragraph4>
    </TitleReviewContainer>
  );
};
