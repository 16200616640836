import React from 'react';
import { Button, ButtonProps, SIZE } from 'baseui/button';
import { TWButtonDefault, TWButtonMini } from '../../theme';

type MainColor = 'primary' | 'secondary' | 'tertiary' | 'clear';

interface OutlinedButtonProps extends ButtonProps {
  fullWidth?: boolean;
  clean?: boolean;
  color: MainColor;
  isMobile?: boolean;
  isTablet?: boolean;
}

const OutlinedButton = (props: OutlinedButtonProps) => (
  <Button
    {...props}
    aria-label={props.children?.toString()}
    overrides={{
      BaseButton: {
        style: ({ $theme: theme }) => {
          const color = {
            primary: theme.cannonColors.primaryBlue,
            secondary: theme.cannonColors.secondaryOrange,
            tertiary: theme.cannonColors.primaryBone,
            clear: theme.cannonColors.primaryWhite,
          };

          const height = {
            [SIZE.compact]: '42px !important',
            [SIZE.default]: '',
            [SIZE.large]: '',
            [SIZE.mini]: '32px !important',
          };
          const hoverBackgroundColor = {
            primary: theme.cannonColors.transparentBlue,
            secondary: theme.cannonColors.transparentOrange,
            tertiary: theme.cannonColors.transparentBone,
            clear: theme.cannonColors.transparentWhite,
          };
          return {
            ...{
              borderRadius: '8px',
              backgroundColor: theme.cannonColors.primaryWhite,
              color: color[props.color],
              ':hover': {
                backgroundColor: hoverBackgroundColor[props.color],
              },
              ':disabled': {
                background: hoverBackgroundColor[props.color],
                boxShadow: 'none',
              },
              // padding: '12px 14px', This line is causing issues in older browsers
              border: `2px solid ${color[props.color]}`,
              boxShadow: props.clean
                ? 'none'
                : `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`,
              width: props.fullWidth ? '100%' : 'fit-content',
              height: height[props.size],
            },
            ...props.$style,
          };
        },
      },
    }}
  >
    {props.size === SIZE.mini && (
      <TWButtonMini color='inherit' weight={400} align='center'>
        {props.children}
      </TWButtonMini>
    )}
    {props.size === SIZE.compact && (
      <TWButtonDefault color='inherit' weight={600} align='center' isMobile={props.isMobile} isTablet={props.isTablet}>
        {props.children}
      </TWButtonDefault>
    )}
  </Button>
);

export default OutlinedButton;
