import React, { useEffect, useState, useRef } from 'react';
import { cannonStyled } from '../../theme';
import { useMediaQuery } from 'react-responsive';

import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../theme/CannonSafesTheme';
import { useTableOrMobile } from '../../hooks';
import { NSH5, ABH1, TWButtonMini } from '../../theme/Typography';

type ICardContainer = {
  isMobile?: boolean;
  isTablet?: boolean;
  isRotated?: boolean;
  $theme?: CannonSafeThemeT;
  cardHeight?: string;
  cardWidth?: string;
  isMinHeight?: boolean;
  responsiveHeight?: string;
  height?: string;
  isMedium?: boolean;
  isSmall?: boolean;
};

export const CardContainer = cannonStyled('div', (props: ICardContainer) => ({
  padding: props.$theme.sizing.scale600,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: props.isMobile
  ? props.isMinHeight
    ? '63vh'
    : '340px'
  : '580px',
  width: '95%',
}));

const TextWrap = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '10px' /* margin Bottom for text container */,
  width: '95%',
  minHeight: '73px',
  maxHeight: '200px',
  overflow: 'hidden',
});

const ImageContainer = cannonStyled(
  'div',
  (props: ICardContainer & { imgURL: string }) => ({
    height: props.isMobile
    ? props.isMinHeight
      ? '55vh'
      : '300px'
    : '75%',
    alignSelf: 'flex-end',
    margin: '0 auto',
    padding: 0,
    width: `calc(${props.cardWidth}px - 13%)`,
    backgroundImage: `url(${props.imgURL})`,
    backgroundSize:
      (props.cardWidth < props.cardHeight)
      ? props.isMobile
      ? 'auto 90%'
      : (props.cardWidth > props.cardHeight)
      ? '10% auto'
      : 'auto 90%'
      : 'auto 99%',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
  })
);

interface ISafeCardProps {
  displayImage: string;
  title: string;
  subTitle: string;
  slug: string;
  shortButton?: boolean;
  measures: string;
  id: string;
  action: () => {};
}

const Card: React.FC<ISafeCardProps> = ({
  displayImage,
  title,
  subTitle,
  measures,
  id,
  action,
  slug,
}) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  // Calculates width of each carousel Card
  const [cardWidth, setWidth] = useState(0);
  const containerWidth = useRef(null);

  useEffect(() => {
    setWidth(containerWidth.current.clientWidth);
    function handleResize() {
      const divWidth = containerWidth.current.clientWidth;
      setWidth(divWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  // Width of each Card in carousel
  let cardWidthStringify = JSON.stringify(cardWidth);

  const isXSmallWidth = useMediaQuery({ query: '(max-width: 350px)'})
  const isSmallWidth = useMediaQuery({ query: '(max-width: 450px)' });
  const isMediumWidth = useMediaQuery({ query: '(max-width: 600px)' });
  const isLargeWidth = useMediaQuery({ query: '(max-width: 899px)' });

  const minHeight = useMediaQuery({ query: '(max-height: 465px)' });

  const textResponsive = isXSmallWidth
    ? { title: '17px', series: '12px', measures: '8px' }
    : isSmallWidth
    ? { title: '22px', series: '17px', measures: '13px' }
    : isMediumWidth
    ? { title: '1.3em', series: '1em', measures: '0.8em' }
    : isLargeWidth
    ? { title: '1.5em', series: '1em', measures: '0.8em' }
    : { title: '28px', series: '20px', measures: '18px' };

  const headerStyle = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'flex-start',
    textTransform: 'uppercase',
  });

  return (
    <CardContainer
      isMobile={isMobile}
      isMinHeight={minHeight}
      ref={containerWidth}
      key={id}
    >
      <TextWrap>
        <NSH5
          className={headerStyle}
          weight={900}
          color={theme.cannonColors.primaryBlue}
          align='center'
          $style={{ fontSize: textResponsive.title }}
        >
          {title}
        </NSH5>
        <ABH1
          weight={300}
          align='center'
          margin={0}
          color={theme.cannonColors.primaryBlueDark}
          $style={{ fontSize: textResponsive.series }}
        >
          {subTitle} Series
        </ABH1>
        <TWButtonMini
          weight={300}
          align='center'
          margin={0}
          color={theme.cannonColors.primaryBlueDark}
          $style={{ fontSize: textResponsive.measures }}
        >
          {measures}
        </TWButtonMini>
      </TextWrap>
      <ImageContainer
        imgURL={displayImage}
        cardWidth={cardWidthStringify}
        isMobile={isMobile}
        isMinHeight={minHeight}
      >
        &nbsp;
      </ImageContainer>
    </CardContainer>
  );
};

export default Card;
