import React from 'react';
import { cannonStyled, useCannonStyletron } from '../../theme';
import { RoundedButton } from '../Button';
import Waterproof from '../../assets/waterproof.svg';
import Fireproof from '../../assets/fireproof.svg';
import ArrowRight from '../../assets/arrow-right.svg';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import { NSH5, TWH5, TWParagraph3 } from '../../theme/Typography';
import { useAPIURL, useTableOrMobile } from '../../hooks';
import { AspectRatioBox } from 'baseui/aspect-ratio-box';

interface ISeriesProps {
  height?: number;
  width?: number;
  depth?: number;
  weight?: number;
  displayImage?: string;
  displayName?: string;
  slug?: string;
  averagePrice?: number;
  firearms?: number;
  waterIcon?: boolean;
  $theme?: CannonSafeThemeT;
  isMobile?: boolean;
  isTablet?: boolean;
  action?: () => {};
}

const CardContainer = cannonStyled(AspectRatioBox, (props: ISeriesProps) => ({
  margin: props.$theme.sizing.scale300,
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: props.isTablet ? '310px' : props.isMobile ? '280px' : '350px',
}));

const GridContainer = cannonStyled('div', (props: ISeriesProps) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
}));

const CardContent = cannonStyled('div', (props: ISeriesProps) => ({
  display: 'grid',
  flexDirection: 'column',
  position: 'relative',
}));

const ImageContainer = cannonStyled('div', (props: ISeriesProps) => ({
  position: 'relative',
  width: '100%',
  height: props.isMobile ? '32vh' : '38vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
}));

const ImgSeries = cannonStyled('img', (props: ISeriesProps) => ({
  position: 'relative',
  maxHeight: '100%',
}));

const IconContainer = cannonStyled('div', () => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'end',
}));

const TextCard = cannonStyled('div', (props: ISeriesProps) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  paddingBottom: props.isMobile ? '6px' : '10px',
}));

const HeaderCard = cannonStyled('div', (props: ISeriesProps) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  textAlign: 'center',
  paddingBottom: props.isMobile ? '12px' : '30px',
}));

const Border = cannonStyled('div', (props: ISeriesProps) => ({
  width: '100%',
  height: '1px',
  marginTop: props.isMobile ? '6px' : '10px',
  backgroundColor: props.$theme.cannonColors.secondarySilver,
}));

const PriceContainer = cannonStyled('div', (props: ISeriesProps) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'start',
  marginTop: props.isMobile ? '6px' : '10px',
  marginBottom: props.isMobile ? '12px' : '30px',
}));

const WhiteSpace = cannonStyled('div', (props: ISeriesProps) => ({
  height: props.isMobile ? '27px' : '25px',
  paddingBottom: props.isMobile ? '6px' : '10px',
}));

export default (props: ISeriesProps) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  var waterIcon;
  var showFirearms;

  if (props.firearms <= 0) {
    showFirearms = <WhiteSpace isMobile={isMobile} />;
  } else {
    showFirearms = (
      <TextCard isMobile={isMobile}>
        <TWH5
          $style={{ fontSize: `min(4.2vw, 18px)` }}
          weight={700}
          color={theme.cannonColors.primaryBlue}
          align={'start'}
        >
          Firearms
        </TWH5>
        <TWH5
          $style={{ fontSize: `min(4.2vw, 18px)` }}
          weight={700}
          color={theme.cannonColors.primaryBlue}
          align={'start'}
        >
          Up to {props.firearms}
        </TWH5>
      </TextCard>
    );
  }

  if (props.waterIcon) {
    waterIcon = <Waterproof className={css({ margin: 0 })} />;
  } else {
    waterIcon = <></>;
  }

  return (
    <CardContainer
      isMobile={isMobile}
      isTablet={isTablet}
      aspectRatio={350 / 661}
    >
      <GridContainer isMobile={isMobile}>
        <ImageContainer isMobile={isMobile}>
          <ImgSeries isMobile={isMobile} src={API_URL + props.displayImage} />
        </ImageContainer>
        <CardContent isMobile={isMobile}>
          <HeaderCard isMobile={isMobile}>
            <NSH5
              weight={900}
              color={theme.cannonColors.primaryBlue}
              align={'start'}
              $style={{ fontSize: `min(5vw, 20px)` }}
            >
              {props.displayName}
            </NSH5>
            <IconContainer>
              {waterIcon}
              <Fireproof
                className={css({
                  margin: 0,
                })}
              />
            </IconContainer>
          </HeaderCard>
          {showFirearms}
          <TextCard isMobile={isMobile}>
            <TWH5
              $style={{ fontSize: `min(4.2vw, 18px)` }}
              weight={700}
              color={'#4E4E4E'}
              align={'start'}
            >
              Size
            </TWH5>
            <TWH5
              $style={{ fontSize: `min(4.2vw, 18px)` }}
              weight={400}
              color={'#4E4E4E'}
              align={'end'}
            >
              {!props.height ? '' : props.height + ' in '}
              {!props.width ? '' : props.width + ' in '}
              {!props.depth ? '' : props.depth + ' in'}
            </TWH5>
          </TextCard>
          <TextCard isMobile={isMobile}>
            <TWH5
              $style={{ fontSize: `min(4.2vw, 18px)` }}
              weight={700}
              color={'#4E4E4E'}
              align={'start'}
            >
              Weight
            </TWH5>
            <TWH5
              $style={{ fontSize: `min(4.2vw, 18px)` }}
              weight={400}
              color={'#4E4E4E'}
              align={'end'}
            >
              {!props.weight ? '' : props.weight + ' lb'}
            </TWH5>
          </TextCard>
          <Border isMobile={isMobile} />
          <PriceContainer isMobile={isMobile}>
            <TWH5
              $style={{ fontSize: `min(4.2vw, 18px)` }}
              weight={700}
              color={theme.cannonColors.primaryGreen}
              align={'start'}
            >
              {`$${props.averagePrice}`}
            </TWH5>
            <TWParagraph3
              weight={400}
              color={theme.cannonColors.secondarySilverDark}
              align={'start'}
            >
              *Average pricing
            </TWParagraph3>
          </PriceContainer>
          <RoundedButton
            size='mini'
            color='primary'
            href={`/product/${props.slug}`}
            $style={{ width: '100%' }}
            onClick={props.action}
            endEnhancer={
              <ArrowRight style={{ stroke: theme.cannonColors.primaryWhite }} />
            }
          >
            View details
          </RoundedButton>
        </CardContent>
      </GridContainer>
    </CardContainer>
  );
};
