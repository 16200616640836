import { graphql, useStaticQuery } from 'gatsby';
import { IDropZonePages } from '../../../templates/documentsPage';

const query = graphql`
  query {
    strapiCannonExperience {
      header
      dropZoneCannonExperience
    }
  }
`;

interface PageArray {
  title: string;
  href: string;
}

interface IColCanExp {
  strapiCannonExperience: {
    header: string;
    dropZoneCannonExperience: Array<IDropZonePages>;
    pages: Array<PageArray>;
  };
}

export const useCCE = (): IColCanExp => useStaticQuery(query);
