import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query {
    strapiFooterLogos {
      title
      firstLogoHref
      linksLogos {
        id
        href
        logo {
          id
          url
        }
      }
      firstLogo {
        url
        alternativeText
      }
    }
  }
`;

interface ImgFirst {
  id: string;
  url: string;
  alternativeText: string;
}

interface LogosLinks {
  id: string;
  href: string;
  logo: {
    url: string;
  };
}

interface IFooterLogos {
  strapiFooterLogos: {
    title: string;
    firstLogoHref: string;
    firstLogo: ImgFirst;
    linksLogos: Array<LogosLinks>;
  };
}

export const useFooterQ = (): IFooterLogos => useStaticQuery(query);
