import { AspectRatioBox } from 'baseui/aspect-ratio-box';
import { ParagraphSmall } from 'baseui/typography';
import React from 'react';
import { useAPIURL, useTableOrMobile } from '../../hooks';
import { useOrientation } from '../../hooks/useOrientation';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ICannonSafe } from '../../pages';
import {
  CannonSafeThemeT,
  cannonStyled,
  useCannonStyletron,
} from '../../theme/CannonSafesTheme';
import { NSH5, TWParagraph3 } from '../../theme/Typography';
import { itemOptions } from '../FindYourSafe/FindYourSafeQuiz/quiz-options.constants';
import './UserManualCard.css';

// STYLED COMPONENTS

interface ICardContainer {
  isMobile?: boolean;
  isTablet?: boolean;
  isRotated?: boolean;
  containerWidth?: number;
  $theme?: CannonSafeThemeT;
}

const CardContainer = cannonStyled(AspectRatioBox, (props: ICardContainer) => ({
  margin: props.isMobile ? '0px' : props.$theme.sizing.scale300,
  marginBottom: props.isMobile ? '' : '',
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: '260px',
  overflowX: 'clip',
  textAlign: 'center',
  maxWidth: props.isMobile ? '80vw' : '100%',
  justifySelf: props.isMobile ? 'center' : 'normal',
  cursor: 'pointer',
  ':hover': {},
  width:
    props.containerWidth <= 896
      ? '500px'
      : props.containerWidth < 629
      ? '80vh'
      : '100%',
}));

const Image = cannonStyled('img', ({ isMobile }: { isMobile: boolean }) => ({
  maxHeight: '200px',
  alignSelf: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const RetailersContainer = cannonStyled('div', () => ({
  width: '75%',
}));

interface Props {
  safe: ICannonSafe;
}

export const UserManualCard = ({ safe = {} as ICannonSafe }: Props) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const { isRotated } = useOrientation();
  const API_URL = useAPIURL();
  const { width } = useWindowDimensions();

  // STATE

  // EFFECTS

  // HANDLERS & FUNCTIONS

  function handleCardClick() {
    const { slug = '' } = safe;
    const _slug = slug.replace(/ /g, '-');
    window.location.href = `/user-manual/${_slug}`;
  }

  // RENDER

  const {
    displayImage = {} as any,
    displayName = '',
    series,
    retailers = [],
  } = safe;
  const { url = '' } = displayImage;

  // const { title: seriesTitle = '' } = series;

  const seriesTitle = series ? series.title : ' ';

  let tempFirstRetails = [];
  let tempLastRetails = [];

  let putComas = (items: any, index: number, arr: string[]) => {
    return index < arr.length - 1 ? `${items}, ` : items;
  };

  let countRetailers = (item, index) => {
    index <= 2
      ? tempFirstRetails.push(item.displayName)
      : tempLastRetails.push(item.displayName);
  };

  retailers.map((items, index) => countRetailers(items, index));
  const firstRetails =
    tempFirstRetails.length > 0
      ? tempFirstRetails
          .map((items, index) => putComas(items, index, tempFirstRetails))
          .join('')
      : tempFirstRetails.join('');

  const lastRetails =
    tempLastRetails.length > 0
      ? tempLastRetails
          .map((items, index) => putComas(items, index, tempLastRetails))
          .join('')
      : tempLastRetails.join('');

  return (
    <CardContainer
      onClick={handleCardClick}
      isMobile={isMobile}
      isTablet={isTablet}
      containerWidth={width}
    >
      <Image isMobile={isMobile} src={API_URL + url} alt='' />
      <TWParagraph3
        align='left'
        color='#111111'
        weight={400}
        $style={{ fontSize: '16px', paddingTop: '16px' }}
      >
        {seriesTitle} Series
      </TWParagraph3>
      <NSH5
        weight={900}
        margin={4}
        color={theme.cannonColors.primaryBlue}
        $style={{ fontSize: isMobile ? '18px' : '19px' }}
        align='left'
      >
        {displayName}
      </NSH5>
      <RetailersContainer>
        <TWParagraph3
          $style={{ fontSize: isMobile ? '12px' : '16px' }}
          color={theme.cannonColors.secondarySilver}
          weight={400}
          align='left'
        >
          Local retailer:
          {retailers.length === 0 && ' Not available'}
          {retailers.length <= 2 && ` ${firstRetails}`}
          {retailers.length > 2 && (
            <span>
              {` ${firstRetails}, `}
              <span title={lastRetails} className='tooltip'>
                {' '}
                + {retailers.length - 3} more{' '}
              </span>{' '}
            </span>
          )}
        </TWParagraph3>
      </RetailersContainer>
    </CardContainer>
  );
};

export default UserManualCard;
