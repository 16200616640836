import React, { useState } from 'react';
import { ICannonSafe } from '../../../pages';
import { useTableOrMobile } from '../../../hooks';
import { ICannonElMobile } from '../../../@types';
import {
  cannonStyled,
  NSH3,
  TWH5,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import StarRating from '../../StarRating';
import language from '../../../language';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const DescriptionWrapper = cannonStyled('div', (props: ICannonElMobile) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  ...(!props.$isMobile && { minWidth: '23vw', maxWidth: '24vw' }),
  ...(props.$isMobile && { marginTop: '20px', width: '90vw' }),
}));

const StarSpan = cannonStyled('span', (props: ICannonElMobile) => ({
  display: 'flex',
  margin: props.$isMobile ? '0.5vh 0px' : '16px 0px',
  color: props.$theme.cannonColors.primaryWhite,
  fontWeight: 400,
  fontSize: '16px',
  fontFamily: 'Titillium Web',
}));

const PriceSpan = cannonStyled('span', (props) => ({
  marginBottom: '1.5vh',
  color: props.$theme.cannonColors.primaryWhite,
  fontSize: '13px',
  fontWeight: 400,
  fontFamily: 'Titillium Web',
}));

const FeatureButton = cannonStyled('button', {
  padding: '5px 12px',
  background: '#E3D9A0',
  borderRadius: '100px',
  border: 'none',
  margin: '4px 9px 4px 0px',
  fontFamily: 'Titillium Web',
  fontSize: '15px',
  fontWeight: 600,
});

const SafeParagraph = cannonStyled('p', (props) => ({
  color: props.$theme.cannonColors.primaryWhite,
  fontSize: '16px',
  fontWeight: 400,
  fontFamily: 'Titillium Web',
  marginBottom: '32px',
}));

const ButtonsWrapper = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
});

interface IDescriptionButton extends ICannonElMobile {
  $colorStyle: 'white' | 'orange';
}

const DescriptionButton = cannonStyled(
  'button',
  (props: IDescriptionButton) => ({
    padding: '8px 12px',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Titillium Web',
    borderRadius: '8px',
    color: props.$theme.cannonColors.primaryWhite,
    cursor: 'pointer',
    ...(props.$colorStyle === 'white' && {
      background: 'transparent',
      border: `2px solid ${props.$theme.cannonColors.primaryWhite}`,
    }),
    ...(props.$colorStyle === 'orange' && {
      background: props.$theme.cannonColors.secondaryOrange,
      border: 'none',
    }),
  })
);

const getReviewValue = (safe: ICannonSafe): number => {
  return safe.reviews.length
    ? Number(
        (
          safe.reviews.reduce((sum, review) => sum + review.value, 0) /
          safe.reviews.length
        ).toFixed(1)
      )
    : 5;
};

const camelCaseToSenteceCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

interface ISafeDescriptionProps {
  safeResults: Array<ICannonSafe>;
  selectedSafe: number;
}

const SafeDescription: React.FC<ISafeDescriptionProps> = ({
  safeResults,
  selectedSafe,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 950;
  const [css, theme] = useCannonStyletron();
  return (
    <DescriptionWrapper $isMobile={isMobile}>
      <NSH3
        align='left'
        weight={900}
        color={theme.cannonColors.primaryWhite}
        $style={{ fontSize: 'min(7vw, 32px)' }}
      >
        {safeResults[selectedSafe].displayName}
      </NSH3>
      <StarSpan $isMobile={isMobile}>
        <StarRating
          ratingValue={getReviewValue(safeResults[selectedSafe])}
          halfStarStyle='gray'
        />
        <label style={{ marginLeft: isMobile ? '6px' : 'unset' }}>
          {getReviewValue(safeResults[selectedSafe])} / 5
        </label>
      </StarSpan>
      <PriceSpan>
        <TWParagraph3
          align='left'
          color={theme.cannonColors.primaryWhite}
          weight={400}
        >
          <b
            style={{
              fontSize: 'min(5vw, 18px)',
              color: theme.cannonColors.secondaryOrange,
            }}
          >
            ${safeResults[selectedSafe].averagePrice}
          </b>
          {language('en', 'averagePricing')}
        </TWParagraph3>
      </PriceSpan>
      <TWH5
        align='left'
        color={theme.cannonColors.primaryWhite}
        weight={700}
        $style={{ fontSize: '18px' }}
      >
        {language('en', 'perfectModelForYou')}
      </TWH5>
      <span>
        {Object.entries(safeResults[selectedSafe].features)
          .filter((e) => e[1] === true)
          .map((feature) => (
            <FeatureButton key={feature[0]}>
              {camelCaseToSenteceCase(feature[0].replace('Capable', ''))}
            </FeatureButton>
          ))}
      </span>

      <TWH5
        align='left'
        $style={{ marginTop: '25px', fontSize: '18px' }}
        color={theme.cannonColors.primaryWhite}
        weight={700}
      >
        Why the {safeResults[selectedSafe].displayName} is just for you?
      </TWH5>
      <SafeParagraph>{safeResults[selectedSafe].bestFor || ''}</SafeParagraph>

      <ButtonsWrapper>
        <DescriptionButton
          onClick={() =>
            (window.location.href = `/product/${safeResults[selectedSafe].slug}`)
          }
          $colorStyle='white'
          $isMobile={isMobile}
          style={{ marginRight: '10px' }}
        >
          See full specs
        </DescriptionButton>
        <DescriptionButton
          onClick={() => (window.location.href = `/where-to-buy`)}
          $colorStyle='orange'
          $isMobile={isMobile}
        >
          Where to buy
        </DescriptionButton>
      </ButtonsWrapper>
    </DescriptionWrapper>
  );
};

export default SafeDescription;
