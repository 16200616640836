import React from 'react';
import { useCannonStyletron } from '../../theme';
import { Button } from '../../atoms/';
import TwitterIcon from '../../assets/twitter.svg';
import FacebookIcon from '../../assets/fb.svg';
import InstagramIcon from '../../assets/instagram.svg';
import YoutubeIcon from '../../assets/youtube.svg';
import TiktokIcon from '../../assets/tiktok-footer.svg';
import { useTableOrMobile } from '../../hooks';
import { graphql, useStaticQuery } from 'gatsby';
import { ISocialMediaLinks } from '../AppBar/SocialBar';

const query = graphql`
  query {
    strapiSocialMediaLinks {
      facebook
      twitter
      youtube
      instagram
      tiktok
    }
  }
`;

export default () => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const data = useStaticQuery<ISocialMediaLinks>(query);

  // HANDLERS && FUNCTIONS

  function handleIconClicked(url: string) {
    window.open(url, '_blank');
  }

  return (
    <div
      className={css({
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
      })}
    >
      <Button.IconButton
        aria-label='facebook'
        onClick={() => handleIconClicked(data.strapiSocialMediaLinks.facebook)}
      >
        <FacebookIcon
          className={css({
            width: '14px',
            height: '25px',
            stroke: theme.cannonColors.primaryGray,
          })}
        />
      </Button.IconButton>
      <Button.IconButton
        aria-label='instagram'
        onClick={() => handleIconClicked(data.strapiSocialMediaLinks.instagram)}
      >
        <InstagramIcon
          className={css({
            width: '25px',
            height: '25px',
            stroke: theme.cannonColors.primaryGray,
          })}
        />
      </Button.IconButton>
      <Button.IconButton
        aria-label='twitter'
        onClick={() => handleIconClicked(data.strapiSocialMediaLinks.twitter)}
        className={css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingRight: 0,
        })}
      >
        <TwitterIcon
          className={css({
            width: '80%',
            height: '20px',
            stroke: theme.cannonColors.primaryGray,
          })}
        />
      </Button.IconButton>
      <Button.IconButton
        aria-label='youtube'
        onClick={() => handleIconClicked(data.strapiSocialMediaLinks.youtube)}
        className={css({
          //padding: isMobile ? '0px' : '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        })}
      >
        <YoutubeIcon
          className={css({
            width: '29px',
            height: '34px',
            stroke: theme.cannonColors.primaryGrayLight,
          })}
        />
      </Button.IconButton>
      <Button.IconButton>
        <TiktokIcon />
      </Button.IconButton>
    </div>
  );
};
