import { IThemeProp } from '../atoms/Cards/CapabilityCard';
import { cannonStyled } from './CannonSafesTheme';

interface ITypographyProps extends IThemeProp {
  color: string;
  weight: any;
  align: any;
  isMobile?: boolean;
  isTablet?: boolean;
  margin?: number;
}

export const ABH1 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Titillium Web',
  fontSize: props.isMobile ? '20px' : `min(3.4vw, 20px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const PBH1 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Noto Serif',
  fontSize: `min(10vw, 90px)`,
  lineHeight: 'min(10vw, 85px)',
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
  letterSpacing: '-1px',
}));

export const NSH1 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Noto Serif',
  fontSize: `min(8vw, 56px)`,
  lineHeight: 'min(8vw, 60px)',
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const NSH44 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Noto Serif',
  fontSize: 'min(8vw,44px)',
  lineHeight: 'min(8vw, 60px)',
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const NSH2 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Noto Serif',
  fontSize: `min(6vw, 42px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const NSH3 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Noto Serif',
  fontSize: `min(5vw, 32px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const NSH4 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Noto Serif',
  fontSize: `min(4vw, 25px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const NSH5 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Noto Serif',
  fontSize: `min(3.3vw, 19px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const TWH1 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Titillium Web',
  fontSize: `min(8vw, 56px)`,
  fontWeight: props.weight,
  color: props.color,
  fontStyle: 'normal',
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const TWH2 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Titillium Web',
  fontSize: `min(6vw, 42px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const TWH3 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Titillium Web',
  fontSize: `min(5vw, 32px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const TWH4 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Titillium Web',
  fontSize: props.isMobile ? '24px' : `min(4vw, 24px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const TWH5 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Titillium Web',
  fontSize: props.isMobile ? '14.5px' : `min(3vw, 18px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const TWParagraph3 = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Titillium Web',
  fontSize: `13px`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));

export const TWButtonDefault = cannonStyled(
  'h1',
  (props: ITypographyProps) => ({
    fontFamily: 'Titillium Web',
    fontSize: `${props.isTablet || props.isMobile ? '14px' : '18px'}`,
    fontWeight: props.weight,
    color: props.color,
    textAlign: props.align,
    marginTop: props.margin ? `${props.margin}px` : '0px',
    marginBottom: props.margin ? `${props.margin}px` : '0px',
  })
);

export const TWButtonMini = cannonStyled('h1', (props: ITypographyProps) => ({
  fontFamily: 'Titillium Web',
  fontSize: `min(3.2vw, 16px)`,
  fontWeight: props.weight,
  color: props.color,
  textAlign: props.align,
  marginTop: props.margin ? `${props.margin}px` : '0px',
  marginBottom: props.margin ? `${props.margin}px` : '0px',
}));
