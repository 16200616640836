import { navigate } from 'gatsby';
import React from 'react';
import { useCannonStyletron, cannonStyled } from '../../../theme';
import { CenteredContainer, LeftContainer } from '../../Container';

import Underlined from '../../Label/Underlined';
import { useCOC } from '../querys/queryCOwnCen';
import Underline from './Underline';

const ContainerInfo = cannonStyled(CenteredContainer, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
  alignSelf: 'flex-start',
});

export default () => {
  const [css, theme] = useCannonStyletron();
  const data = useCOC();

  // ARRAYS FROM STRAPI
  const dropArray = data.strapiOwnerCenter.dropZoneOwnerCenter;
  const pagesArray = data.strapiOwnerCenter.pages;

  //NEW ARRAY WITH BOTH PAGES & DROPZONE
  const duplicateArray = dropArray.concat(pagesArray);

  //REMOVING DUPLICATES
  const uniqueArray = duplicateArray.filter(
    (dropArray, index, a) =>
      a.findIndex(
        (t) => t.title === dropArray.title && t.href === dropArray.href
      ) === index
  );

  return (
    <ContainerInfo>
      <LeftContainer>
        <CenteredContainer className={css({ justifyContent: 'left' })}>
          <Underlined color={theme.cannonColors.primaryWhiteLight}>
            {data.strapiOwnerCenter.header}
          </Underlined>
        </CenteredContainer>
        {uniqueArray.map((id) => (
          <Underline onClick={() => (window.location.href = `/${id.href}`)}>
            {id.title}
          </Underline>
        ))}
      </LeftContainer>
    </ContainerInfo>
  );
};
