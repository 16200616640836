import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Button } from '../../atoms';
import { Button as ButtonDef, KIND, SIZE } from 'baseui/button';

import TwitterIcon from '../../assets/twitter.svg';
import FacebookIcon from '../../assets/fb.svg';
import InstagramIcon from '../../assets/instagram.svg';
import YoutubeIcon from '../../assets/youtube.svg';
import TiktokIcon from '../../assets/tiktok.svg';
import { cannonStyled, TWButtonMini, useCannonStyletron } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import { ResponsiveContainer } from '../Container';

const query = graphql`
  query {
    strapiSocialMediaLinks {
      facebook
      twitter
      youtube
      instagram
      tiktok
    }
  }
`;

export interface ISocialMediaLinks {
  strapiSocialMediaLinks: StrapiSocialMediaLinks;
}
export interface StrapiSocialMediaLinks {
  facebook: string;
  instagram: string;
  twitter: string;
  youtube: string;
  tiktok: string;
}

const Container = cannonStyled(
  'div',
  (props: any & { $theme: CannonSafeThemeT }) => ({
    backgroundColor: props.$theme.cannonColors.primaryBlueDark,
    height: '38px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  })
);

const Delimiter = cannonStyled(
  'div',
  (props: { $theme: CannonSafeThemeT }) => ({
    height: '22px',
    marginLeft: '10px',
    marginRight: '10px',
    backgroundColor: '#F4F6FB',
    width: '1px',
  })
);

export default ({ isTabletOrMobile = false, hide }) => {
  const data = useStaticQuery<ISocialMediaLinks>(query);
  const [useCss, theme] = useCannonStyletron();

  // TODO: Add links to social media when they are available to us

  const iconClass = useCss({
    ':hover': {
      stroke: '#E3D9A0',
    },
    stroke: theme.cannonColors.primaryWhiteLight,
  });

  // RENDER

  const { strapiSocialMediaLinks = {} as StrapiSocialMediaLinks } = data;
  const {
    facebook = '',
    instagram = '',
    twitter = '',
    youtube = '',
    tiktok = '',
  } = strapiSocialMediaLinks;

  return !hide ? (
    <Container>
      <ResponsiveContainer>
        <div
          className={useCss({
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            alignItems: 'center',
            width: '50%',
          })}
        >
          <Button.IconButton
            aria-label='facebook'
            onClick={() => {
              window.open(facebook, '_blank');
            }}
          >
            <FacebookIcon className={iconClass} />
          </Button.IconButton>
          <Button.IconButton
            aria-label='instagram'
            onClick={() => {
              window.open(instagram, '_blank');
            }}
          >
            <InstagramIcon className={iconClass} />
          </Button.IconButton>
          <Button.IconButton
            aria-label='twitter'
            onClick={() => {
              window.open(twitter, '_blank');
            }}
            className={iconClass}
          >
            <TwitterIcon />
          </Button.IconButton>
          <Button.IconButton
            aria-label='youtube'
            onClick={() => {
              window.open(youtube, '_blank');
            }}
          >
            <YoutubeIcon className={iconClass} />
          </Button.IconButton>
          <Button.IconButton
            aria-lable='tiktok'
            onClick={() => {
              window.open(tiktok, '_blank');
            }}
          >
            <TiktokIcon className={useCss({':hover': { stroke: '#E3D9A0' }})} />
          </Button.IconButton>
        </div>
        <div
          className={useCss({
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
            width: '50%',
          })}
        >
          <ButtonDef
            aria-label='blank'
            onClick={() => (window.location.href = '/sign-in')}
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: 'transparent',
                  fontFamily: 'Titillium Web',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '22px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                },
              },
            }}
            kind={KIND.primary}
            size={SIZE.mini}
          >
            {/* <TWButtonMini color='white' weight={400} align='center'>
              Sign In
            </TWButtonMini> */}
          </ButtonDef>
          {/* <Delimiter /> */}
          <ButtonDef
            aria-label='blank'
            onClick={() => (window.location.href = '/sign-up')}
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: 'transparent',
                },
              },
            }}
            kind={KIND.primary}
            size={SIZE.mini}
          >
            {/* <TWButtonMini color='white' weight={400} align='center'>
              Sign Up
            </TWButtonMini> */}
          </ButtonDef>
        </div>
      </ResponsiveContainer>
    </Container>
  ) : null;
};
