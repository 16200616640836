import { H1, H6 } from 'baseui/typography';
import React from 'react';
import { NSH2, TWButtonMini, TWH2 } from '../../theme';
import {
  CannonSafeThemeT,
  cannonStyled,
  useCannonStyletron,
} from '../../theme/CannonSafesTheme';

export interface IThemeProp {
  $theme?: CannonSafeThemeT;
}

interface ICapabilityCard extends IThemeProp {
  imgSrc: string;
  title: string;
  isMobile?: boolean;
  description: string;
}

const Container = cannonStyled('div', (props: { isMobile?: boolean }) => ({
  width: '100%',
  height: '100%',
  display: props.isMobile ? 'flex' : 'grid',
  flexDirection: 'column',
  alignItems: props.isMobile ? 'center' : '',
  marginTop: '25px',
  gridTemplateColumns: props.isMobile ? '1fr' : 'min(15vw, 90px) auto',
  gridTemplateRows: props.isMobile ? '10vh auto auto' : 'auto auto',
  gridTemplateAreas: props.isMobile
    ? `
    'image'
    'title'
    'content'
  `
    : `
    'image title'
    'image content'
  `,
}));

const ImageContainer = cannonStyled('div', {
  gridArea: 'image',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

const Image = cannonStyled('img', (props: { isMobile?: boolean }) => ({
  [props.isMobile ? 'height' : 'width']: '100%',
}));

const Title = cannonStyled('div', {
  gridArea: 'title',
});

const Content = cannonStyled('div', {
  gridArea: 'content',
});

const CapabilityCard = (props: ICapabilityCard) => {
  const [css, theme] = useCannonStyletron();

  return (
    <Container isMobile={props.isMobile}>
      <ImageContainer aria-label={props.title}>
        <Image src={props.imgSrc} isMobile={props.isMobile} alt={props.title} />
      </ImageContainer>
      <div>
        <Title>
          <TWH2
            color={theme.cannonColors.primaryBlueLight}
            align={props.isMobile ? 'center' : 'left'}
            weight={700}
          >
            {props.title}
          </TWH2>
        </Title>
        <Content>
          <TWButtonMini
            $style={{ textTransform: 'uppercase' }}
            color={theme.cannonColors.secondarySilver}
            align={props.isMobile ? 'center' : 'left'}
            weight={400}
          >
            {props.description}
          </TWButtonMini>
        </Content>
      </div>
    </Container>
  );
};

export default CapabilityCard;
