import React from 'react';
import {
  cannonStyled,
  TWH5,
  TWH4,
  TWParagraph3,
  useCannonStyletron,
} from '../../theme';
import { IContactProps } from '../../components/sections/ContactForm';
import { useNewLineText } from '../../hooks';

interface IContactCard {
  telephone: string;
  address: string;
  hoursDays: string;
}

const ContactContainer = cannonStyled('div', (props: IContactProps) => ({
  margin: '60px 0',
  padding: '30px',
  borderRadius: '10px',
  backgroundColor: props.$theme.cannonColors.primaryWhiteDark,
}));

const TextWrapper = cannonStyled('div', {
  margin: '24px 0',
});

const ContactUsCard = (props: IContactCard) => {
  const [css, theme] = useCannonStyletron();
  const address = useNewLineText(props.address);
  return (
    <ContactContainer>
      <TWH4
        color={theme.cannonColors.primaryBlue}
        margin={0}
        align='left'
        weight={700}
        $style={{
          fontSize: `min(6vw, 24px)`,
        }}
      >
        Other ways to get in touch with us:
      </TWH4>

      <TextWrapper>
        <TWH5
          color={theme.cannonColors.secondarySilver}
          margin={0}
          align='left'
          weight={400}
          $style={{
            fontSize: `min(5vw, 18px)`,
          }}
        >
          Customer Service
        </TWH5>
        <TWH5
          color={theme.cannonColors.primaryGray}
          margin={0}
          align='left'
          weight={700}
        >
          {props.telephone}
        </TWH5>
        <TWParagraph3
          color={theme.cannonColors.primaryGray}
          margin={0}
          align='left'
          weight={400}
        >
          {props.hoursDays}
        </TWParagraph3>
      </TextWrapper>
    </ContactContainer>
    )
};

export default ContactUsCard;
