import React from 'react';
import { H6, Paragraph3, ParagraphSmall } from 'baseui/typography';
import { cannonStyled } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import { HorizontalFlex } from '../Container';

interface IRetailerProps {
  $theme: CannonSafeThemeT;
}

const RetailerCard = cannonStyled('div', (props: IRetailerProps) => ({
  borderRadius: props.$theme.sizing.scale300,
  border: `1px solid ${props.$theme.cannonColors.primaryGray}`,
  padding: '15px',
  margin: '5px',
  minWidth: '250px',
}));

export default () => {
  return (
    <RetailerCard>
      <HorizontalFlex>
        <Paragraph3 $style={{ fontWeight: 'bold' }} margin={0}>
          Tractor Supply
        </Paragraph3>
        <Paragraph3 $style={{ fontWeight: 'bold' }} margin={0}>
          2.5 miles
        </Paragraph3>
      </HorizontalFlex>
      <ParagraphSmall margin={0}>Open until 9:30pm</ParagraphSmall>
      <ParagraphSmall margin={0}>
        1101 Hillview LN Franklin, TN 37064
      </ParagraphSmall>
    </RetailerCard>
  );
};
