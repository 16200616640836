import { useContext } from 'react';
import { FindYourSafeContext } from '../../components/layout/ContextProviders/context';

const useFindYourSafe = () => {
  const findYourSafeProps = useContext(FindYourSafeContext);

  return findYourSafeProps;
};

export default useFindYourSafe;
