import React from 'react';
import SocialBar from './SocialBar';
import NavBar from './NavBar';
import { useOrientation, useTableOrMobile } from '../../hooks';

export default ({ hideSocialBar, hideNavBar }) => {
  const [isMobile] = useTableOrMobile();
  const { isRotated } = useOrientation();

  return (
    <div style={{ display: hideNavBar && isRotated ? 'none' : 'block' }}>
      <SocialBar hide={hideSocialBar} isTabletOrMobile={isMobile} />
      <NavBar hidden={hideSocialBar} isTabletOrMobile={isMobile} />
    </div>
  );
};
