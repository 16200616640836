import React from 'react';
import {
  fadeInUp,
  slideInDown,
  slideInUp,
  fadeOutDown,
} from 'react-animations';
import Radium, { StyleRoot } from 'radium';

export const FadeOutDown = {
  animation: 'x 0.8s',
  opacity: '0',
  animationName: Radium.keyframes(fadeOutDown, 'fadeOutDown'),
};

export const AppearUp = {
  animation: 'x 0.8s',
  animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
};

export const SlideInDown = {
  animation: 'x 0.3s',
  animationName: Radium.keyframes(slideInDown, 'slideInDown'),
};
export const SlideInUp = {
  animation: 'x 0.4s',
  animationName: Radium.keyframes(slideInUp, 'slideInUp'),
};
export const _SlideInUp = {
  animation: 'x 0.5s',
  transform: 'translateY(-10px)',
  animationName: Radium.keyframes({
    '0%': {
      transform: 'translateY(-1px)',
    },
    '10%': {
      transform: 'translateY(-2px)',
    },
    '20%': {
      transform: 'translateY(-3px)',
    },
    '30%': {
      transform: 'translateY(-4px)',
    },
    '40%': {
      transform: 'translateY(-5px)',
    },
    '50%': {
      transform: 'translateY(-6px)',
    },
    '60%': {
      transform: 'translateY(-7px)',
    },
    '70%': {
      transform: 'translateY(-8px)',
    },
    '80%': {
      transform: 'translateY(-9px)',
    },
    '90%': {
      transform: 'translateY(-9.5px)',
    },
    '100%': {
      transform: 'translateY(-10px)',
    },
  }),
};
export const _SlideInDown = {
  animation: 'x 0.5s',
  animationName: Radium.keyframes({
    '0%': {
      transform: 'translateY(-10px)',
    },
    '10%': {
      transform: 'translateY(-9px)',
    },
    '20%': {
      transform: 'translateY(-8px)',
    },
    '30%': {
      transform: 'translateY(-7px)',
    },
    '40%': {
      transform: 'translateY(-6px)',
    },
    '50%': {
      transform: 'translateY(-5px)',
    },
    '60%': {
      transform: 'translateY(-4px)',
    },
    '70%': {
      transform: 'translateY(-3px)',
    },
    '80%': {
      transform: 'translateY(-2px)',
    },
    '90%': {
      transform: 'translateY(-1px)',
    },
    '100%': {
      transform: 'translateY(0px)',
    },
  }),
};

const AnimationContainer = (props) => <StyleRoot>{props.children}</StyleRoot>;

export default AnimationContainer;
