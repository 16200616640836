import React from 'react';
import ReactMarkdown from 'react-markdown';

import { cannonStyled, useCannonStyletron } from '../../../theme';
import { Button } from '../..';

import { CenteredContainer, LeftContainer } from '../../Container';

import PhoneIcon from '../../../assets/phone.svg';
import MailIcon from '../../../assets/mail.svg';
import LocationAdress from '../../../assets/location-adress.svg';

import { Paragraph4 } from 'baseui/typography';
import { useCInfo } from '../querys/queryCInfo';
import Underlined from '../../Label/Underlined';
import { useOrientation } from '../../../hooks';
import { TWParagraph2, TWA } from '../../DocumentParser';

const ContainerInfo = cannonStyled('div', {
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
  paddingRight: '10px',
});

export default () => {
  const [css, theme] = useCannonStyletron();
  const data = useCInfo();
  const { isRotated } = useOrientation();

  return (
    <ContainerInfo>
      <LeftContainer style={{ width: 'fit-content' }}>
        <CenteredContainer className={css({ justifyContent: 'left' })}>
          <Underlined color={theme.cannonColors.primaryWhiteLight}>
            {data.strapiContact.title}
          </Underlined>
        </CenteredContainer>
        <ReactMarkdown
          components={{
            a: ({ node, ...props }) => (
              <TWA
                margin={0}
                color={theme.cannonColors.secondaryOrange}
                style={{ fontSize: '14px' }}
                {...props}
              />
            ),
            p: ({ node, ...props }) => (
              <TWParagraph2
                color={theme.cannonColors.primaryWhiteLight}
                style={{
                  fontSize: '14px',
                }}
                {...props}
              />
            ),
            b: ({ node, ...props }) => (
              <TWParagraph2
                color={theme.cannonColors.primaryWhiteLight}
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
                {...props}
              />
            ),
          }}
        >
          {data.strapiContact.footerContactDescription}
        </ReactMarkdown>
        <CenteredContainer
          className={css({ justifyContent: 'left', alignItems: 'center' })}
        >
          <PhoneIcon
            className={css({
              fill: theme.cannonColors.primaryWhite,
              paddingRight: '14px',
            })}
          />
          <Paragraph4
            color={theme.cannonColors.primaryWhiteLight}
            className={css({
              fontFamily: 'Titillium Web !important',
              fontWidth: '400',
              fontSize: '14px',
            })}
          >
            {data.strapiContact.telephone}
          </Paragraph4>
        </CenteredContainer>

        <CenteredContainer
          className={css({ justifyContent: 'left', alignItems: 'center' })}
        >
          <MailIcon
            className={css({
              paddingRight: '14px',
            })}
          />
          <a
            href={`/contact-us`}
            aria-label={'/contact-us'}
            className={css({ textDecoration: 'none', width: 'auto' })}
          >
            <Paragraph4
              color={theme.cannonColors.primaryWhiteLight}
              className={css({
                fontFamily: 'Titillium Web !important',
                fontWidth: '400',
                fontSize: '14px',
                textDecoration: 'underline',
                textDecorationColor: theme.cannonColors.primaryWhiteLight,
                cursor: 'pointer',
              })}
            >
              {data.strapiContact.email}
            </Paragraph4>
          </a>
        </CenteredContainer>

        <CenteredContainer
          className={css({ justifyContent: 'left', alignItems: 'center' })}
        >
          <LocationAdress
            className={css({
              paddingRight: '14px',
              width: isRotated ? '28px' : '40px',
            })}
          />
          <ReactMarkdown
            components={{
              p: ({ node, ...props }) => (
                <TWParagraph2
                  color={theme.cannonColors.primaryWhiteLight}
                  style={{
                    fontSize: '14px',
                    textAlign: 'left',
                    display: 'column',
                  }}
                  {...props}
                />
              ),
            }}
          >
            {data.strapiContact.address}
          </ReactMarkdown>
        </CenteredContainer>
      </LeftContainer>
    </ContainerInfo>
  );
};
