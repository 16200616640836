import React, { useEffect, useState } from 'react';
import { Button } from '..';
import { initialRouteState } from '../../constants';
import { cannonStyled } from '../../theme';
import NavigationMenuOption from './NavigationMenuOption';
import { useTableOrMobile } from '../../hooks';
import useFindYourSafe from '../FindYourSafe/useFindYourSafe';
import CloseIcon from '../../assets/close.svg';

const MainContainer = cannonStyled('div', (props) => ({
  width: '100vw',
  height: '100vh',
  background: props.$theme.cannonColors.primaryBlue,
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'relative',
}));

const CloseButtonContainer = cannonStyled('div', {
  position: 'sticky',
  top: 0,
  right: '10px',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '15px',
});

export default ({ close }) => {
  const [isMobile] = useTableOrMobile();
  const { toggleOverlay } = useFindYourSafe();
  const [routes, setRoutes] = useState(initialRouteState);

  useEffect(() => {
    if (isMobile) {
      setRoutes([
        ...routes,
        {
          mobileOnly: true,
          title: 'Find your Safe',
          href: '/?openedOverlay=true',
          key: 'find-your-safe',
          action: () => {
            toggleOverlay();
          },
        },
      ]);
    } else {
      setRoutes(routes.filter((r) => r.title !== 'Find your Safe'));
    }
  }, [isMobile]);

  return (
    <MainContainer>
      <CloseButtonContainer>
        <Button.IconButton onClick={close}>
          <CloseIcon />
        </Button.IconButton>
      </CloseButtonContainer>
      <ul style={{ padding: '30px', paddingTop: '0px' }}>
        {routes.map((value: any) => {
          const { title = '', href = '', subitems = [], action } = value;
          const option = { title, href, subitems, action };
          return <NavigationMenuOption option={option} closeMenu={close} />;
        })}
      </ul>
    </MainContainer>
  );
};
