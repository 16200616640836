import React from 'react';
import { styled } from 'baseui';
import { Button } from 'baseui/button';

type PropsT = {};

const IconButton = styled('button', ({ $theme: theme }) => ({
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  position: 'relative',
  overflow: 'hidden',
  padding: '0.3em',
  appearance: 'none',
  border: 'none',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  ':hover': {
    color: '#E3D9A0',
  },
}));

export default IconButton;
