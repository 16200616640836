import React from 'react';
import { cannonStyled } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';

type ISpace = {
  isMobile?: boolean;
  direction: 'vertical' | 'horizontal';
  $theme?: CannonSafeThemeT;
};

export const SmallSpace = cannonStyled('div', (props: ISpace) => ({
  [props.direction === 'vertical' ? 'height' : 'width']:
    props.$theme.sizing.scale100,
}));

export const BigSpace = cannonStyled('div', (props: ISpace) => ({
  [props.direction === 'vertical' ? 'height' : 'width']: props.isMobile
    ? '15em'
    : '26vh',
}));
