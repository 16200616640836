import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import FacebookLogo from '../../assets/facebook-white-circle.svg';
import InstagramLogo from '../../assets/instagram-white-circle.svg';
import YoutubeLogo from '../../assets/youtube-white-circle.svg';
import { cannonStyled, TWH5, useCannonStyletron } from '../../theme';
import { useAPIURL } from '../../hooks';
import Underlined from '../Label/Underlined';

//Query from strapi
const query = graphql`
  query {
    strapiFooterMobile {
      FooterMobile {
        title
        subtitle
        id
        action
        href
        icon {
          url
        }
      }
    }
  }
`;

interface IFooterMobileSection {
  id: number;
  title: string;
  subtitle: string;
  action: string;
  href: string;
  icon: {
    url: string;
  };
}

interface IFooterMobileData {
  strapiFooterMobile: {
    FooterMobile: Array<IFooterMobileSection>;
  };
}

const MainFlex = cannonStyled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '30px',
  marginRight: '30px',
  paddingTop: '40px',
}));

const RowFlex = cannonStyled('div', () => ({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: '40px',
}));

const FooterMobile = () => {
  //Hooks
  const data = useStaticQuery<IFooterMobileData>(query);
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();

  //Handler for actions
  const handleClick = (value) => {
    if (value.includes('.com')) {
      value = window.open(`${value}`, '_blank');
    } else {
      value = window.location.href = `${value}`;
    }
  };

  return (
    <>
      <MainFlex>
        <TWH5
          color={theme.cannonColors.primaryWhite}
          weight={700}
          align={'left'}
          $style={{ fontSize: '18px', paddingBottom: '15px' }}
        >
          Follow our Updates!
        </TWH5>
        <RowFlex>
          <FacebookLogo
            onClick={() =>
              window.open(`https://www.facebook.com/cannonsafe`, '_blank')
            }
            className={css({
              width: '44px',
              height: '44px',
              marginRight: '12px',
            })}
          />
          <InstagramLogo
            onClick={() =>
              window.open(
                `https://www.instagram.com/cannonsafe/?hl=en`,
                '_blank'
              )
            }
            className={css({
              width: '44px',
              height: '44px',
              marginRight: '12px',
            })}
          />
          <YoutubeLogo
            onClick={() =>
              window.open(
                `https://www.youtube.com/channel/UCGRZsd6Ev5-a0dxTQ_nA0aQ/featured`,
                '_blank'
              )
            }
            className={css({ width: '44px', height: '44px' })}
          />
        </RowFlex>
        {data.strapiFooterMobile.FooterMobile.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <img
                src={API_URL + item.icon.url}
                className={css({ width: '23px', height: '23px' })}
                alt={item.title}
              />
              <Underlined
                className={css({ width: '150px', paddingBottom: '10px' })}
                color={theme.cannonColors.primaryWhiteLight}
              >
                {item.title}
              </Underlined>
              <TWH5
                color={theme.cannonColors.primaryWhite}
                weight={400}
                align={'left'}
                $style={{
                  fontSize: '17px',
                  paddingTop: '10px',
                  paddingBottom: '15px',
                }}
              >
                {item.subtitle}
              </TWH5>
              <TWH5
                onClick={() => handleClick(item.href)}
                color={theme.cannonColors.primaryWhite}
                weight={400}
                align={'left'}
                $style={{
                  fontSize: '15px',
                  paddingBottom: '50px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                {item.action}
              </TWH5>
            </React.Fragment>
          );
        })}
      </MainFlex>
    </>
  );
};

export default FooterMobile;
