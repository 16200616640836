import React from 'react';
import { Wrapper } from '../../components/layout';
import { useTableOrMobile } from '../../hooks';
import { cannonStyled, useCannonStyletron } from '../../theme';

export default ({ children, imageSrc, backgroundColor = '#586849', $alt }) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  const imgClass = css({
    minWidth: '100%',
    height: `auto`,
    objectFit: 'cover',
    // mixBlendMode: 'multiply',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0) 50.51%, #FFFFFF 73.59%), url(.jpg)',
    mixBlendMode: 'soft-light',
  });

  const imgWrapper = css({
    width: '100%',
    height: `calc(100vh - ${isMobile ? '75px' : '113px'})`,
    overflow: 'hidden',
  });

  const StyledWrapper = cannonStyled(Wrapper, {
    background: `linear-gradient(180deg, ${backgroundColor} 50.51%, #FFFFFF 73.59%)`,
    // background: `linear-gradient(180deg, ${backgroundColor} 17.71%, rgba(143, 119, 79, 0.791139) 53.84%, rgba(143, 119, 79, 0) 100%), #FFFFFF`,
  });

  return (
    <StyledWrapper noScroll>
      <div className={imgWrapper}>
        <img className={imgClass} src={imageSrc} alt={$alt}/>
      </div>
      {children}
    </StyledWrapper>
  );
};
