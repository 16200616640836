import React from 'react';
import { cannonStyled } from '../../theme';

export default cannonStyled('div', {
  //width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  alignContent: 'left',
});
