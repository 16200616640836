import { ABH1, TWH4 } from '../../theme';
import React from 'react';
import { cannonStyled, useCannonStyletron } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';

import CenteredContainer from '../Container/CenteredContainer';
import { useTableOrMobile } from '../../hooks';
import { AspectRatioBox } from 'baseui/aspect-ratio-box';

type ICardContainer = {
  $theme?: CannonSafeThemeT;
};

const CardContainer = cannonStyled(
  AspectRatioBox,
  (props: ICardContainer & { isMobile: boolean }) => {
    const { $theme: theme } = props;

    return {
      position: 'relative',
      width: '100%',
      borderRadius: theme.sizing.scale300,
      boxShadow: `0px 14px 26px rgba(0, 0, 0, 0.1)`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      overflow: 'hidden',
      maxHeight: '250px',
      minHeight: '100px',
      maxWidth: '640px',
      cursor: 'pointer',
    };
  }
);

const CardContentContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '45px' : '10px',
    bottom: props.isMobile ? '45px' : '10px',
    left: '30px',
    right: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  })
);

const IconStyle = cannonStyled('img', (props: { isMobile: boolean }) => ({
  textAlign: 'center',
  width: 'auto',
  height: props.isMobile ? '30px' : '40px',
}));

const ActionCard = ({ src, title, text, actionTitle, actionURL, onClick }) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  return (
    <CenteredContainer onClick={onClick}>
      <CardContainer
        isMobile={isMobile}
        aspectRatio={isMobile ? 25 / 15 : 2 / 1}
      >
        <CardContentContainer isMobile={isMobile}>
          <IconStyle src={src} isMobile={isMobile} alt={title}/>

          <TWH4
            color={theme.cannonColors.primaryBlue}
            weight={700}
            align={'center'}
            margin={10}
            $style={{
              fontSize: `min(5vw, 24px)`,
            }}
          >
            {title}
          </TWH4>

          <ABH1
            color={theme.cannonColors.primaryGrayLight}
            weight={300}
            align={'center'}
            $style={{
              fontSize: `min(4.4vw, 20px)`,
            }}
          >
            {text}
          </ABH1>
        </CardContentContainer>
      </CardContainer>
    </CenteredContainer>
  );
};

export default ActionCard;
