import { createContext } from 'react';

interface IFindYourSafeContext {
  openedOverlay: boolean;
  importantItem: string;
  importantProtection: string;
  priceRange: string;
  fireArmCapacity: string;
  searchResults: boolean;
  setYourSafeContext?: (key: string, newValue: any) => void;
  toggleOverlay?: () => void;
  clearParams?: () => void;
}

export const findYourSafeInitialContext: IFindYourSafeContext = {
  openedOverlay: false,
  importantItem: '',
  importantProtection: '',
  priceRange: '',
  fireArmCapacity: '',
  searchResults: false,
};

export const FindYourSafeContext = createContext(findYourSafeInitialContext);
