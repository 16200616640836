import React from 'react';
import { useCannonStyletron } from '../../theme/CannonSafesTheme';
import SocialIcons from './SocialIcons';
import BottomLinks from './BottomLinks';
import { CenteredContainer } from '../Container';
import { useTableOrMobile } from '../../hooks';

export default () => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  return (
    <CenteredContainer
      className={css({
        backgroundColor: theme.cannonColors.primaryBone,
        justifyContent: isMobile ? 'none' : 'space-between',
      })}
    >
      <CenteredContainer
        className={css({
          alignItems: 'center',
          paddingLeft: '4%',
        })}
      >
        <CenteredContainer
          className={css({
            display: 'inline',
            justifyContent: 'left',
          })}
        >
          <BottomLinks />
        </CenteredContainer>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingRight: isMobile ? '30px' : '60px',
          })}
        >
          {!isMobile && <SocialIcons />}
        </div>
      </CenteredContainer>
    </CenteredContainer>
  );
};
