import React from 'react';
import { ABH1, cannonStyled, useCannonStyletron } from '../../theme';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useTableOrMobile } from '../../hooks';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import { isVertical } from 'baseui/tabs-motion';

interface isVerticalProps {
  $isVertical?: boolean;
  $theme?: CannonSafeThemeT;
  $isMobile?: boolean;
  isActive?: boolean;
}

const OptionsDiv = cannonStyled('div', (props: isVerticalProps) => ({
  display: 'flex',
  flexDirection: props.$isVertical ? 'column' : 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignSelf: 'center',
  columnGap: '10px',
  padding: 0,
  marginTop: props.$isMobile ? '5px' : '18px',
  marginBottom: props.$isMobile ? '0px' : '30px',
  // height: '60px',
}));

const OptionButton = cannonStyled('button', (props: isVerticalProps) => ({
  border: props.isActive
    ? 'none'
    : `1px solid ${props.$theme?.cannonColors.primaryGrayLight}`,
  borderRadius: '4px',
  backgroundColor: props.isActive
    ? props.$theme?.cannonColors.primaryBlueLight
    : props.$theme?.cannonColors.primaryWhite,
  color: props.isActive
    ? props.$theme?.cannonColors.primaryWhite
    : props.$theme?.cannonColors.secondarySilver,
  fontFamily: 'Titillium Web',
  fontSize: 'min(4vw, 16px)',
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  minHeight: props.$isVertical ? 'auto' : '55px',
  width: '100%',
  minWidth: props.$isVertical && !props.$isMobile ? '160px' : 'unset',
  marginBottom: props.$isVertical ? '10px' : 0,
  padding:
    props.$isVertical ? '10px 5px' : '6px 14px 6px 10px',
  flexDirection: props.$isVertical ? 'column' : 'row',
  ':hover': {
    backgroundColor: props.$theme?.cannonColors.primaryBlueLight,
    color: props.$theme.cannonColors.primaryWhite,
  },
}));

const RadioInputRounded = cannonStyled(ABH1, (props: isVerticalProps) => ({
  fontSize: '18px',
  marginBottom: props.$isMobile ? '12px' : 'auto',
}));

interface SurveyOption {
  label: string;
}

interface IOptionsRowProps {
  isColumn?: boolean;
  verticalButton?: boolean;
  bigMarginTop?: boolean;
  question: string;
  options: Array<SurveyOption>;
  currentOption: string;
  setCurrentOption: (newOption: string) => void;
}

const RoundedButtonsGroup: React.FC<IOptionsRowProps> = ({
  isColumn,
  bigMarginTop,
  question,
  options,
  currentOption,
  setCurrentOption,
  verticalButton,
}) => {
  const [css, theme] = useCannonStyletron();
  const { width } = useWindowDimensions();
  const [isMobile] = useTableOrMobile();

  const VerticalCss = css({
    flexDirection: 'column',
    flexGrow: 0.7,
  });

  const marginTopCss = css({ marginTop: bigMarginTop ? '10vh' : 'unset' });

  return (
    <>
      <RadioInputRounded
        color={theme.cannonColors.primaryBlue}
        weight={400}
        align='left'
        className={marginTopCss}
        $isMobile={isColumn}
      >
        {question}
      </RadioInputRounded>
      <OptionsDiv
        $isMobile={isColumn}
        $isVertical={verticalButton}
        className={isColumn ? VerticalCss : ''}
      >
        {options.map((opt) => (
          <OptionButton
            $isMobile={isColumn}
            $isVertical={verticalButton}
            key={opt.label}
            onClick={() => setCurrentOption(opt.label)}
            isActive={currentOption === opt.label}
            type='button'
          >
            <label
              style={{
                ...(!verticalButton && {
                  marginLeft: '8px',
                  textAlign: isMobile ? 'left' : 'center',
                }),
                fontSize: isMobile ? '16px' : '18px',
                cursor: 'inherit',
              }}
            >
              {opt.label}
            </label>
          </OptionButton>
        ))}
      </OptionsDiv>
    </>
  );
};

export default RoundedButtonsGroup;
