import React from 'react';
import { Button, ButtonProps } from 'baseui/button';

import './underlined-button.css';
import { TWButtonDefault, useCannonStyletron } from '../../theme';

interface UnderlinedButtonProps extends ButtonProps {
  isActive?: boolean;
  isTablet?: boolean;
}

const UnderlinedButton = (props: UnderlinedButtonProps) => {
  const [useCss, theme] = useCannonStyletron();

  return (
    <Button
      {...props}
      kind='minimal'
      size='compact'
      overrides={{
        BaseButton: {
          style: {
            color: theme.cannonColors.primaryWhite,
            position: 'relative',
            ':hover': {
              backgroundColor: 'transparent',
            },
            ':active': {
              backgroundColor: 'transparent',
            },
          },
        },
      }}
    >
      <TWButtonDefault
        isTablet={props.isTablet}
        className={`menu-button-selector ${props.isActive ? 'active' : ''}`}
        style={{ '--var-color': theme.cannonColors.secondaryOrange }}
        weight={400}
        align='center'
      >
        {props.children}
      </TWButtonDefault>
    </Button>
  );
};

export default UnderlinedButton;
