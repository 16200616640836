import React, { useEffect } from 'react';
import { cannonStyled, TWH5, useCannonStyletron } from '../../theme';
import { useTableOrMobile } from '../../hooks';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import ErrorImg from '../../images/error-icon.png';

import scrollTo from 'gatsby-plugin-smoothscroll';

interface IContainers {
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
  bgColor?: string;
}

const ErrorContainer = cannonStyled('div', (props: IContainers) => ({
  backgroundColor: props.bgColor 
  ? props.bgColor
  : props.$theme.cannonColors.transparentOrange,
  borderRadius: '8px',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: props.$theme.cannonColors.secondaryCarmine,
  height: props.isMobile ? '60px' : '85px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxShadow: '0px 7px 18px rgba(147, 56, 49, 0.1)',
  marginBottom: '20px',
}));

const ErrorIcon = cannonStyled('div', (props: IContainers) => ({
  width: props.isMobile ? '85px' : '80px',
  textAlign: 'center',
}));

const IconImg = cannonStyled('img', {
  height: '45px',
  marginTop: '8px',
});

const ErrorText = cannonStyled('div', {
  width: 'auto',
  marginRight: '10px',
});

const SpaceDiv = cannonStyled('div', {
  height: '20px',
});

const errorForm = (props: {bgColor?: string, noScroll?: boolean}) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  !props.noScroll && (
  useEffect(() => {
    setTimeout(() => {
      scrollTo('#show');
    }, 500);
  }, []))

  return (
    <>
      <SpaceDiv/>
      <ErrorContainer 
      isMobile={isMobile}
      bgColor={props.bgColor}
      id='show'>
        <ErrorIcon isMobile={isMobile}>
          <IconImg src={ErrorImg} alt='error' />
        </ErrorIcon>
        <ErrorText>
          <TWH5
            color={theme.cannonColors.primaryGray}
            weight={400}
            align='left'
            $style={{
              fontSize: isMobile ? '0.9em' : `min(1.6vw, 18px)`,
            }}
          >
            An error ocurred while sending your data. <br />
            Please try again in a few minutes.
          </TWH5>
        </ErrorText>
      </ErrorContainer>
    </>
  );
};

export default errorForm;
