import { AspectRatioBox } from 'baseui/aspect-ratio-box';
import React from 'react';
import { useOrientation, useTableOrMobile } from '../../hooks';
import { cannonStyled, TWH5, useCannonStyletron } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import { IThemeProp } from './CapabilityCard';

interface IContainer extends IThemeProp {
  isMobile?: boolean;
  $theme?: CannonSafeThemeT;
  isRotated?: boolean;
}

const Container = cannonStyled(AspectRatioBox, (props: IContainer) => ({
  position: 'relative',
  width: props.isMobile ? '320px' : `min(30vw, 350px)`,
}));

const ContainerContent = cannonStyled('div', (props: IContainer) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: 'auto',
  bottom: props.isMobile ? 'none' : 0,
  padding: props.isMobile ? '20px' : '26px', 
  margin: props.isMobile ? '10px' : 'normal',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: props.isMobile ? 'center' : 'flex-start',
  backgroundColor: props.$theme.cannonColors.primaryWhite,
  boxShadow: '0px 14px 26px rgba(3, 64, 120, 0.08)',
  borderRadius: '8px',
}));

const Image = cannonStyled('img', {
  height: '36px',
});

export default ({ title, description, iconSrc }) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const { isRotated } = useOrientation();

  return (
    <Container isMobile={isMobile} aspectRatio={350 / 230}>
      <ContainerContent isMobile={isMobile}>
        <Image src={iconSrc} alt={`${title} image`} />
        <TWH5
          margin={4}
          isMobile={isMobile}
          weight={700}
          color={theme.cannonColors.primaryBlue}
          align={isMobile ? 'center' : 'start'}
          className={css({
            fontSize: isMobile ? '15px' : `min(1.8vw, 20px)`,
          })}
        >
          {title}
        </TWH5>
        <TWH5
          weight={400}
          color={theme.cannonColors.secondarySilverDark}
          align={isMobile ? 'center' : 'start'}
          $style={
            isRotated
              ? { fontSize: `min(3.5vh, 16px)` }
              : { fontSize: `min(3.5vw, 16px)` }
          }
        >
          {description}
        </TWH5>
      </ContainerContent>
    </Container>
  );
};
