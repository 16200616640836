import React, { MutableRefObject, useEffect, useState } from 'react';

// const [isIntersecting, setIntersecting] = useState(false)

//   const observer = new IntersectionObserver(
//     ([entry]) => setIntersecting(entry.isIntersecting)
//   )

//   useEffect(() => {
//     observer.observe(ref.current)
//     // Remove the observer as soon as the component is unmounted
//     return () => { observer.disconnect() }
//   }, [])

//   return isIntersecting

export default (ref: MutableRefObject<any>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};
