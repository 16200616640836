import React, { useEffect, useRef } from 'react';
import { useTableOrMobile } from '../../hooks';
import { ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import {
  cannonStyled,
  cannonWithStyle,
  useCannonStyletron,
  CannonSafeThemeT,
} from '../../theme/CannonSafesTheme';
import {
  RadioGroup,
  Radio,
  ALIGN,
  StyledInput,
  StyledRadioMarkInner,
  StyledRadioMarkOuter,
  RadioProps,
} from 'baseui/radio';
import CheckInput from '../../assets/check-input.svg';
/** ************ USAGE *****************
 <RadioInput
   label=string
   radioMarginRight=string // Right Margin between radios
   To set the number of radio buttons in the same group do an array of Objects
   radioElements={[
     { radioValue: string, radioDescription?: string } //First radio
     { radioValue: string, radioDescription?: string } //Second radio
     { radioValue: string, radioDescription?: string } //...
   ]}
   errorMessage=string
  />
Note* It can receive all properties of a single input from base-web (https://baseweb.design/components/radio)
 */
const RadioOuterCircle = cannonWithStyle(StyledRadioMarkOuter, (props: any) => {
  const {
    $disabled,
    $isFocused,
    $isHovered,
    $isActive,
    $checked,
    $theme,
    $error,
  } = props;
  const stateColor = $disabled
    ? $theme.cannonColors.secondarySilver
    : $error
    ? $theme.cannonColors.secondaryCarmineLight
    : $isFocused
    ? $theme.cannonColors.secondaryOrange
    : $isHovered
    ? $theme.cannonColors.primaryBoneLight
    : $isActive
    ? $theme.cannonColors.primaryBlue
    : $checked
    ? $theme.cannonColors.secondaryOrange
    : $theme.cannonColors.secondarySilver;
  return {
    border: `2px solid ${stateColor}`,
    backgroundColor: $isFocused ? stateColor : 'transparent',
  };
});
const RadioInnerCircle = cannonStyled('div', (props: any) => {
  return props.$checked
    ? {
        background: props.$theme.cannonColors.secondaryOrange,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
      }
    : {};
});
const MainContainer = cannonStyled(
  'div',
  (props: { noMarginLeft: boolean }) => ({
    width: '100%',
    marginLeft: props.noMarginLeft ? 0 : '10px',
    marginTop: '20px',
    alignSelf: 'baseline',
  })
);
const Asterisk = cannonStyled(
  'span',
  (props: { $theme: CannonSafeThemeT }) => ({
    color: props.$theme.cannonColors.secondaryOrange,
  })
);
// INTERFACE PROPS
interface RadioElements {
  radioValue: string;
  radioDescription?: string;
}
interface IInput extends RadioProps {
  errorMessage?: string;
  label?: string;
  noMarginLeft?: boolean;
  radioElements: Array<RadioElements>;
  radioMarginRight?: string;
  requiredStyle?: boolean;
}
const RadioInput = ({
  label = 'Label',
  error,
  onChange = () => {},
  errorMessage = 'Error',
  noMarginLeft = false,
  radioElements = [],
  value = '',
  radioMarginRight = '',
  requiredStyle = false,
  ...other
}: IInput) => {
  // STATE
  const [checked, setChecked] = React.useState('');
  // HOOKS
  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const inputRef: any = useRef();
  // EFFECTS
  useEffect(() => {
    setChecked(value);
  }, [value]);
  // HANDLERS & FUNCTIONS
  function handleChange(e: any) {
    const { value: _value = '' } = e.currentTarget;
    setChecked(_value);
    onChange(e);
  }
  const labelStyle = css({
    paddingTop: '8px',
    paddingBottom: '8px',
    fontFamily: 'Titillium Web !important',
    fontSize: '17px',
    fontWeight: '700 !important',
    textAlign: 'left',
  });
  const errorMsgStyle = css({
    paddingTop: '8px',
    paddingBottom: '8px',
    fontWeight: 'bold',
    fontFamily: 'Titillium Web !important',
    fontSize: '16px',
    textAlign: 'left',
  });
  // EFFECTS
  // RENDER
  return (
    <MainContainer noMarginLeft={noMarginLeft}>
      <ParagraphSmall
        color={theme.cannonColors.primaryBlue}
        className={labelStyle}
        margin={0}
      >
        {requiredStyle && (
          <div>
            {label} <Asterisk>*</Asterisk>
          </div>
        )}
        {!requiredStyle && <div>{label}</div>}
      </ParagraphSmall>
      <RadioGroup
        value={value}
        name='number'
        align={ALIGN.horizontal}
        error={error}
        onChange={(e) => {
          handleChange(e);
        }}
        overrides={{
          RadioMarkOuter: {
            component: RadioOuterCircle,
          },
        }}
      >
        {radioElements.map((radio) => {
          return (
            <Radio
              overrides={{
                Root: {
                  style: {
                    marginRight: radioMarginRight,
                  },
                },
                RadioMarkInner: {
                  component: () => (
                    <RadioInnerCircle $checked={checked === radio.radioValue}>
                      {checked === radio.radioValue && <CheckInput />}
                    </RadioInnerCircle>
                  ),
                },
                Label: {
                  style: {
                    fontWeight: 400,
                    fontFamily: 'Titillium Web',
                    fontSize: isMobile ? '14px' : '16px',
                    color: theme.cannonColors.primaryGray,
                  },
                },
              }}
              value={radio.radioValue}
              description={radio.radioDescription}
            >
              {radio.radioValue}
            </Radio>
          );
        })}
        ;
      </RadioGroup>
      {error && (
        <ParagraphSmall
          color={theme.cannonColors.secondaryCarmineLight}
          className={errorMsgStyle}
          margin={0}
        >
          {errorMessage}
        </ParagraphSmall>
      )}
    </MainContainer>
  );
};
export default RadioInput;
