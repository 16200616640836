import React from 'react';
import { ABH1, cannonStyled, useCannonStyletron } from '../../../theme';
import { QuizOption } from './quiz-options.constants';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ICannonElMobile } from '../../../@types';
import OrangeChecked from '../../../assets/checked-orange.svg';
import { useTableOrMobile } from '../../../hooks';

interface isVerticalProps extends ICannonElMobile {
  $isVertical: boolean;
}

const OptionsDiv = cannonStyled('div', (props: isVerticalProps) => ({
  display: 'flex',
  justifyContent:
    props.$isVertical && !props.$isMobile ? 'center' : 'space-evenly',
  width: '90%',
  alignSelf: 'center',
  marginTop: props.$isMobile ? '5px' : '18px',
  marginBottom: props.$isMobile ? '0px' : '60px',
}));

const OptionButton = cannonStyled('button', (props: isVerticalProps) => ({
  background: 'rgba(3, 64, 120, 0.3)',
  borderRadius: '4px',
  color: props.$theme.cannonColors.primaryWhiteDark,
  fontFamily: 'Titillium Web',
  fontSize: 'min(4vw, 16px)',
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  minHeight: props.$isMobile ? '12%' : 'unset',
  minWidth: props.$isVertical && !props.$isMobile ? '160px' : 'unset',
  padding:
    props.$isVertical && !props.$isMobile ? '26px 16px' : '6px 14px 6px 10px',
  flexDirection: props.$isVertical ? 'column' : 'row',
  margin: props.$isVertical && !props.$isMobile ? '0px 24px' : props.$isMobile ? '9px 10px' : '0 10px',
}));

const CheckIconSpan = cannonStyled('span', {
  position: 'absolute',
  top: '-10px',
  right: '10px',
});

const OptionQuestionText = cannonStyled(ABH1, (props: ICannonElMobile) => ({
  fontSize: props.$isMobile ? '16px !important' : '20px',
  marginBottom: props.$isMobile ? '12px' : 'auto',
}));

interface IOptionsRowProps {
  isColumn?: boolean;
  verticalButton?: boolean;
  bigMarginTop?: boolean;
  question: string;
  options: Array<QuizOption>;
  currentOption: string;
  setCurrentOption: (newOption: string) => void;
}

const OptionsRow: React.FC<IOptionsRowProps> = ({
  isColumn,
  bigMarginTop,
  question,
  options,
  currentOption,
  setCurrentOption,
  verticalButton,
}) => {
  const [css, theme] = useCannonStyletron();
  const { width } = useWindowDimensions();
  const [isMobile] = useTableOrMobile();
  const selectedCss = (isSelected: boolean) =>
    css({
      border: `2px solid ${
        isSelected
          ? theme.cannonColors.secondaryOrange
          : 'rgba(3, 64, 120, 0.3)'
      }`,
    });

  const VerticalCss = css({
    flexDirection: 'column',
    flexGrow: 0.7,
  });

  const marginTopCss = css({ marginTop: bigMarginTop ? '10vh' : 'unset' });
  return (
    <>
      <OptionQuestionText
        color={theme.cannonColors.primaryWhite}
        weight={700}
        align='center'
        className={marginTopCss}
        $isMobile={isColumn}
      >
        {question}
      </OptionQuestionText>
      <OptionsDiv
        $isMobile={isColumn}
        $isVertical={verticalButton}
        className={isColumn && VerticalCss}
      >
        {options.map((opt) => (
          <OptionButton
            $isMobile={isColumn}
            $isVertical={verticalButton}
            key={opt.value}
            onClick={() => setCurrentOption(opt.value)}
            className={selectedCss(currentOption === opt.value)}
          >
            {React.createElement<{ style: any }>(opt.icon, {
              style: { 
                minWidth: '33px', 
                minHeight: '30px',
                marginBottom: isMobile ? '4px' : 0 
              },
            })}
            
            <label
              style={{
                ...(!verticalButton && {
                  marginLeft: '8px',
                  textAlign: 'left',
                }),
                fontSize: isMobile ? `min(4.5vw, 16px)` : '16px',
                cursor: 'inherit',
              }}
            >
              {opt.label}
            </label>
            {currentOption === opt.value && (
              <CheckIconSpan>
                <OrangeChecked />
              </CheckIconSpan>
            )}
          </OptionButton>
        ))}
      </OptionsDiv>
    </>
  );
};

export default OptionsRow;
