import { useState, useCallback } from 'react';

export function useQuery(locationSearch: string) {
  return new URLSearchParams(locationSearch);
}

const replaceLocationSearch = (newSearch: string = '') => {
  const newurl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    '?' +
    newSearch;
  window.history.pushState({ path: newurl }, '', newurl);
};

export const useQueryState = (
  locationSearch: string,
  key: string,
  initialValue: any
) => {
  const query = useQuery(locationSearch);
  const [value, _setValue] = useState(query.get(key) || initialValue);
  const setValue = useCallback(
    (newValue) => {
      _setValue(newValue);
      query.set(key, newValue);

      replaceLocationSearch(query.toString());
    },
    [key, query]
  );

  return [value, setValue];
};

function getInitialState<T>(initialObject: T, query: URLSearchParams): T {
  const entries = Object.entries(initialObject);
  const initialState = { ...initialObject };
  entries.forEach((entry) => {
    const [key, initialVal] = entry;
    const queryVal = query.get(key);
    let finalQueryVal: any = queryVal;
    if (queryVal && typeof initialVal === 'object') {
      finalQueryVal = query.get(key).split(',');
    }
    if (queryVal && typeof initialVal === 'boolean') {
      finalQueryVal = queryVal.toLowerCase() === 'true' ? true : false;
    }
    const finalValue = finalQueryVal || initialVal;
    initialState[key] = finalValue;
  });
  return initialState;
}

export function useQueryObjState<T>(
  locationSearch: string,
  initialValue: T
): [T, (key: string, newValue: any) => void, () => void] {
  const query = useQuery(locationSearch);
  const [value, _setValue] = useState<T>(getInitialState(initialValue, query)); // set for each entry based on key
  const setValue = useCallback(
    (key, newValue) => {
      _setValue((previousValues) => ({ ...previousValues, [key]: newValue }));
      const parsedValue =
        typeof newValue === 'object' ? newValue.join() : newValue;
      parsedValue === '' ? query.delete(key) : query.set(key, newValue);
      replaceLocationSearch(query.toString());
    },
    [query.toString()]
  );

  const resetValues = useCallback(() => {
    _setValue(initialValue);
    replaceLocationSearch('');
  }, []);

  return [value, setValue, resetValues];
}
