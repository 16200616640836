import React from 'react';
import { cannonStyled, NSH2, useCannonStyletron } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';

import CenteredContainer from '../Container/CenteredContainer';
import { useTableOrMobile } from '../../hooks';
import { AspectRatioBox } from 'baseui/aspect-ratio-box';

type ICardContainer = {
  $theme?: CannonSafeThemeT;
};

const CardContainer = cannonStyled(
  AspectRatioBox,
  (props: ICardContainer & { isMobile: boolean }) => {
    const { $theme: theme } = props;

    return {
      position: 'relative',
      width: '100%',
      borderRadius: theme.sizing.scale300,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      overflow: 'hidden',
      transition: props.isMobile ? '' : 'all 0.5s ease-in-out',
      cursor: 'pointer',
      ':hover': props.isMobile
        ? {}
        : {

            boxShadow: '0px 28px 39px rgba(0, 0, 0, 0.25)',
            transform: 'translate(1px, -3px)',
          },
    };
  }
);

const CardBackgroundImage = cannonStyled(
  'img',
  (props: ICardContainer & { isMobile: boolean }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    [props.isMobile ? 'height' : 'width']: '100%',
    zIndex: 0,
    height: '100%',
    borderRadius: props.$theme.sizing.scale300,
    backgroundSize: 'cover',
  })
);

const CardContentContainer = cannonStyled('div',(props) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  padding: '10px',
  display: 'flex',
  background:
    'linear-gradient(180deg, rgba(0, 0, 0, 0) 20.28%, rgba(0, 0, 0, 0.6) 81.93%)',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: props.$theme.sizing.scale300,
}));

const ImageCard = ({ src, title, actionTitle, actionURL, onClick }) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  return (
    <CenteredContainer onClick={onClick}>
      <CardContainer isMobile={isMobile} aspectRatio={isMobile ? 1 / 2 : 2 / 1}>
        <CardBackgroundImage isMobile={isMobile} src={src} alt={title} />
        <CardContentContainer>
          <NSH2
            color={theme.cannonColors.primaryWhiteLight}
            align='center'
            weight={900}
          >
            {title}
          </NSH2>
        </CardContentContainer>
      </CardContainer>
    </CenteredContainer>
  );
};

export default ImageCard;
