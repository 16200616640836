import React, { useState } from 'react';
import { Button } from '..';
import { ListItem, ListItemLabel } from 'baseui/list';
import { ChevronDown, ChevronRight, Search } from 'baseui/icon';
import { TWParagraph3, useCannonStyletron } from '../../theme';
import NavigationSubItemOption from './NavigationSubItemOption';

// INTERFACES

export interface Option {
  title: string;
  href: string;
  action: () => void;
  subitems: {
    title: string;
    href: string;
    subitems?: {
      title: string;
      href: string;
    }[];
  }[];
}
export interface Props {
  option: Option;
  closeMenu: () => void;
}

export const NavigationMenuOption = ({
  option = {} as Option,
  closeMenu,
}: Props) => {
  // HOOKS

  const [css, theme] = useCannonStyletron();

  // STATE
  const [subItemsOpened, setSubItemsOpened] = useState(false);

  // HANDLERS & FUNCTIONS

  function openLevel(e: any) {
    e.stopPropagation();
    setSubItemsOpened(!subItemsOpened);
  }
  function handleOptionClicked(action: () => void, url: string) {
    if (action) {
      closeMenu();
      action();
    } else {
      window.location.href = url;
    }
  }

  // RENDER
  const { subitems = [] as Option[] } = option;
  const subItems = [].concat.apply(
    [],
    subitems.map((si) => si.subitems)
  );
  return (
    <div style={{ borderBottom: '1px solid rgba(244,246,251, 0.4)' }}>
      <div
        onClick={(e) =>
          option.title !== 'Safes' && subItems.length > 0
            ? openLevel(e)
            : handleOptionClicked(option.action, option.href)
        }
      >
        <ListItem
          overrides={{
            Root: {
              style: {
                background: `${theme.cannonColors.primaryBlue} !important`,
              },
            },
          }}
          endEnhancer={() =>
            option.title !== 'Safes' &&
            subItems.length > 0 && (
              <Button.IconButton onClick={openLevel}>
                {!subItemsOpened && (
                  <ChevronRight
                    size='25px'
                    color={theme.cannonColors.primaryWhite}
                  />
                )}
                {subItemsOpened && (
                  <ChevronDown
                    size='25px'
                    color={theme.cannonColors.primaryWhite}
                  />
                )}
              </Button.IconButton>
            )
          }
        >
          <ListItemLabel>
            <TWParagraph3
              $style={{ fontSize: '19px' }}
              color={theme.cannonColors.primaryWhite}
              align='left'
              weight={subItemsOpened ? 700 : 400}
            >
              {option.title}
            </TWParagraph3>
          </ListItemLabel>
        </ListItem>
      </div>
      {subItems.length > 0 && option.title !== 'Safes' && subItemsOpened && (
        <ul style={{ padding: 0 }}>
          {subItems.map((subItem: any, index: number) => {
            const { title = '', href = '' } = subItem;
            return (
              <NavigationSubItemOption index={index} option={{ title, href }} />
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default NavigationMenuOption;
