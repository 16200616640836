import React from 'react';
import { cannonStyled } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';

type ISpace = {
  isMobile: boolean;
  direction: 'vertical' | 'horizontal';
  $theme?: CannonSafeThemeT;
};

export const SmallSpaceMobile = cannonStyled('div', (props: ISpace) => {
  if (props.isMobile)
    return {
      [props.direction === 'vertical' ? 'height' : 'width']: '20px',
    };
  else
    return {
      [props.direction === 'vertical' ? 'height' : 'width']:
        props.$theme.sizing.scale1000,
    };
});
