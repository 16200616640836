import React, { useEffect, useState } from 'react';
import { ABH1, cannonStyled, useCannonStyletron } from '../../../theme';
import { Button } from '../..';
import Carousel, {
  arrowsPlugin,
  slidesToShowPlugin,
  slidesToScrollPlugin,
} from '@brainhubeu/react-carousel';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { ICannonElMobile } from '../../../@types';
import { AspectRatioBox } from 'baseui/aspect-ratio-box';
import ArrowLeft from '../../../assets/arrow-left.svg';
import ArrowRight from '../../../assets/arrow-right.svg';
import '@brainhubeu/react-carousel/lib/style.css';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

interface ISafeGalleryWrapperProps extends ICannonElMobile {
  $isSelected: boolean;
}

const SafeGalleryWrapper = cannonStyled(
  AspectRatioBox,
  (props: ISafeGalleryWrapperProps) => ({
    position: 'relative',
    background: props.$theme.cannonColors.primaryBlueLight,
    width: '45%',
    height: props.$isMobile ? 'max(40vh, 250px)' : 'max(68vh, 400px)',
    borderRadius: '8px',
    border: `4px solid ${
      props.$isSelected
        ? props.$theme.cannonColors.secondaryOrange
        : props.$theme.cannonColors.primaryBlueBg
    }`,
  })
);

interface ImageWrapperProps {
  $usePointer?: boolean;
}
const ImageWrapper = cannonStyled('div', (props: ImageWrapperProps) => ({
  height: '100%',
  width: '100%',
  ...(props.$usePointer && { cursor: 'pointer' }),
}));

const FloatingSafeName = cannonStyled(ABH1, {
  position: 'absolute',
  top: '16px',
  left: '16px',
  zIndex: 100,
  textShadow: '0px 0px 8px rgba(0, 0, 0, 0.7)',
});

interface ISafeGalleryProps {
  safeName: string;
  imgSrcs: Array<string>;
  isSelected: boolean;
  handleSelect: () => void;
}

const SafeGallery: React.FC<ISafeGalleryProps> = ({
  safeName,
  imgSrcs,
  isSelected,
  handleSelect,
}) => {
  const [css, theme] = useCannonStyletron();
  const { width } = useWindowDimensions();
  const isMobile = width <= 950;
  const API_URL_HOOK = useAPIURL();
  const [gallerySlide, setGallerySlide] = useState(0);

  useEffect(() => setGallerySlide(0), [isSelected]);
  const handleChangeSlide = (val) => {
    setGallerySlide(val);
  };
  return (
    <SafeGalleryWrapper
      $isMobile={isMobile}
      $isSelected={isSelected}
      onClick={handleSelect}
      aspectRatio={325 / 520}
    >
      <FloatingSafeName
        align='center'
        color={theme.cannonColors.primaryWhite}
        weight={700}
      >
        {safeName}
      </FloatingSafeName>
      {isSelected ? (
        <>
          <Carousel
            value={gallerySlide}
            onChange={handleChangeSlide}
            plugins={[
              {
                resolve: arrowsPlugin,
                options: {
                  arrowLeft: (
                    <Button.IconButton
                      aria-label='Previous'
                      $style={{
                        backgroundColor: theme.cannonColors.primaryBlueLight,
                        borderRadius: '4px',
                        zIndex: 99,
                        position: 'absolute',
                      }}
                    >
                      <ArrowLeft style={{ stroke: 'white', width: '80%' }} />
                    </Button.IconButton>
                  ),
                  arrowRight: (
                    <Button.IconButton
                      aria-label='Next'
                      $style={{
                        backgroundColor: theme.cannonColors.primaryBlueLight,
                        borderRadius: '4px',
                        zIndex: 99,
                        position: 'absolute',
                        right: '0',
                      }}
                    >
                      <ArrowRight style={{ stroke: 'white', width: '80%' }} />
                    </Button.IconButton>
                  ),
                  addArrowClickHandler: true,
                },
              },
              {
                resolve: slidesToShowPlugin,
                options: {
                  numberOfSlides: 1,
                },
              },
              {
                resolve: slidesToScrollPlugin,
                options: {
                  numberOfSlides: 1,
                },
              },
            ]}
          >
            {imgSrcs.map((safeImg, idx) => (
              <ImageWrapper>
                <img
                  src={API_URL_HOOK + safeImg}
                  style={{
                    objectFit: idx === 0 ? 'contain' : 'cover',
                    width: '100%',
                    height: '100%',
                    zIndex: 98,
                  }}
                />
              </ImageWrapper>
            ))}
          </Carousel>
        </>
      ) : (
        <ImageWrapper $usePointer>
          <img
            src={API_URL_HOOK + imgSrcs[0]}
            style={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
              zIndex: 98,
            }}
          />
        </ImageWrapper>
      )}
    </SafeGalleryWrapper>
  );
};

export default SafeGallery;
