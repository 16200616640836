import React from 'react';
import { cannonStyled, TWH5, useCannonStyletron } from '../../theme';
import { useTableOrMobile } from '../../hooks';
import { ICannonElMobile } from '../../@types';
import useFindYourSafe from './useFindYourSafe';
import CloseIcon from '../../assets/close-icon-small.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface FixedProps extends ICannonElMobile {
  $isFixed?: boolean;
}

const CloseModalDiv = cannonStyled('div', (props: FixedProps) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ...(!props.$isFixed && {
    top: props.$isMobile ? '20px' : '60px',
    right: props.$isMobile ? '5px' : '50px',
    position: 'absolute',
  }),
  ...(props.$isFixed && { marginLeft: 'auto', marginRight: '8px' }),
}));

const CloseText = cannonStyled(TWH5, {
  fontSize: '18px !important',
  marginRight: '10px',
});

interface GoBackButtonProps {
  text?: String;
  refProp?: any;
  fixed?: boolean;
}

const CloseButton: React.FC<GoBackButtonProps> = ({
  text = 'Close',
  refProp = null,
  fixed = false,
}) => {
  const [css, theme] = useCannonStyletron();
  const { width } = useWindowDimensions();
  const isMobile = width <= 950;
  const { toggleOverlay, clearParams } = useFindYourSafe();

  const handleClose = () => {
    toggleOverlay();
    clearParams();
  };

  return (
    <CloseModalDiv
      ref={refProp}
      $isMobile={isMobile}
      onClick={handleClose}
      $isFixed={fixed}
    >
      <CloseText
        align='center'
        color={theme.cannonColors.primaryWhite}
        weight={400}
      >
        {text}
      </CloseText>
      <CloseIcon />
    </CloseModalDiv>
  );
};

export default CloseButton;
