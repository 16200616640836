import React from 'react';
import { cannonStyled, TWH5, useCannonStyletron } from '../../theme';
import { useTableOrMobile } from '../../hooks';
import { ICannonElMobile } from '../../@types';
import BackArrowIcon from '../../assets/arrow-left.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface FixedProps extends ICannonElMobile {
  $isFixed?: boolean;
}
const GoBackDiv = cannonStyled('div', (props: FixedProps) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ...(!props.$isFixed && {
    top: props.$isMobile ? '20px' : '60px',
    left: props.$isMobile ? '5px' : '50px',
    position: 'absolute',
  }),
  ...(props.$isFixed && { marginRight: 'auto', marginLeft: '8px' }),
}));

const GoBackText = cannonStyled(TWH5, {
  fontSize: '18px !important',
  marginRight: '10px',
});

interface GoBackButtonProps {
  text: String;
  handleBack: () => void;
  fixed?: boolean;
}

const GoBackButton: React.FC<GoBackButtonProps> = ({
  text,
  handleBack,
  fixed,
}) => {
  const [css, theme] = useCannonStyletron();
  const { width } = useWindowDimensions();
  const isMobile = width <= 950;
  return (
    <GoBackDiv $isMobile={isMobile} onClick={handleBack} $isFixed={fixed}>
      <BackArrowIcon />
      <GoBackText
        align='center'
        color={theme.cannonColors.primaryWhite}
        weight={400}
        $style={{ marginLeft: '10px' }}
      >
        {text}
      </GoBackText>
    </GoBackDiv>
  );
};

export default GoBackButton;
