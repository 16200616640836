import React, { useEffect, useMemo, useState } from 'react';
import { SelectProps } from 'baseui/select';
import { Select } from 'baseui/select';
import { ParagraphSmall } from 'baseui/typography';
// @ts-ignore
import ArrowDown from '../../assets/arrow-down-gray.svg';
import CloseIcon from '../../assets/close-icon.svg';
import {
  cannonStyled,
  useCannonStyletron,
  CannonSafeThemeT,
} from '../../theme/CannonSafesTheme';
import { useTableOrMobile } from '../../hooks';

// STYLED COMPONENTS

const CustomLabel = cannonStyled('div', (props: any) => {
  return {
    display: 'flex',
    flex: 1,
    padding: '10px',
    fontSize: props.isMobile ? '14.5px' : '18px',
    fontFamily: 'Titillium Web',
    fontWeight: 400,
    backgroundColor: props.$selected
      ? props.$theme.cannonColors.secondaryOrange
      : 'transparent',
    color: props.$disabled ? '#CDCFD2' : props.$selected ? 'white' : 'black',
    ':hover': {
      backgroundColor: props.$theme.cannonColors.secondaryOrange,
      color: props.$disabled ? '#CDCFD2' : 'white',
      opacity: props.$disabled ? 0.9 : 1,
    },
  };
});

const ClearContainer = cannonStyled('div', {
  display: 'flex',
  padding: '5px',
  cursor: 'pointer',
});

const MainContainer = cannonStyled(
  'div',
  (props: { noMarginLeft: boolean }) => ({
    width: '100%',
    marginLeft: props.noMarginLeft ? 0 : '10px',
    marginTop: '10px',
    alignSelf: 'baseline',
  })
);

const Asterisk = cannonStyled(
  'span',
  (props: { $theme: CannonSafeThemeT }) => ({
    color: props.$theme.cannonColors.secondaryOrange,
  })
);

// INTERFACES
export interface IDropdownOption {
  id: string;
  label: string;
  disabled?: boolean;
}
interface IDropdown extends SelectProps {
  onChange: (value: any) => void;
  options: IDropdownOption[];
  background?: string;
  border?: string;
  label?: string;
  noMarginLeft?: boolean;
  requiredStyle?: boolean;
  positive?: boolean;
  errorMessage?: string;
}

// COMPONENT
const CustomDropdown = ({
  options = [],
  onChange,
  value = {} as any,
  background,
  border,
  label = 'Label',
  noMarginLeft = false,
  requiredStyle = false,
  positive = false,
  error,
  errorMessage = 'Error',
  ...other
}: IDropdown) => {
  // STATE
  const [selectedValue, setSelectedValue] = useState(value);
  // HOOKS
  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  // HANDLERS & FUNCTIONS
  function handleChange(e: any) {
    const { value } = e;
    setSelectedValue(value);
    onChange(value);
  }
  function handleClear(e: any) {
    e.stopPropagation();
    setSelectedValue({} as any);
    onChange({} as any);
  }
  // EFFECTS
  useEffect(() => {
    if (value.length > 0) {
      setSelectedValue(value);
    }
  }, [value]);

  // RENDER
  const labelStyle = css({
    paddingTop: '8px',
    paddingBottom: '8px',
    fontFamily: 'Titillium Web !important',
    fontSize: '16px',
    fontWeight: 'normal',
    textAlign: 'left',
  });

  const errorMsgStyle = css({
    paddingTop: '8px',
    paddingBottom: '8px',
    fontWeight: 'bold',
    fontFamily: 'Titillium Web !important',
    fontSize: '16px',
    textAlign: 'left',
  });

  return (
    <MainContainer noMarginLeft={noMarginLeft}>
      <ParagraphSmall
        color={theme.cannonColors.secondarySilverDark}
        className={labelStyle}
        margin={0}
      >
        {requiredStyle && (
          <div>
            {label} <Asterisk>*</Asterisk>
          </div>
        )}
        {!requiredStyle && <div>{label}</div>}
      </ParagraphSmall>
      <Select
        id={label}
        labelKey='label'
        valueKey='id'
        value={selectedValue}
        onChange={handleChange}
        options={options}
        overrides={{
          Root: {
            style: {
              backgroundColor: `${
                background ? background : theme.cannonColors.primaryWhiteDark
              }!important`,
              borderRadius: '4px',
              maxWidth: '100%',
              flex: 1,
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: positive
                ? theme.cannonColors.primaryGreen
                : theme.cannonColors.secondarySilver,
            },
          },
          ControlContainer: {
            style: {
              borderStyle: 'none',
              border: border ? border : '',
              borderRadius: '4px',
              alignItems: 'center',
              cursor: 'pointer',
              background: `${
                background ? background : theme.cannonColors.primaryWhiteDark
              }!important`,
            },
          },
          Placeholder: {
            style: {
              fontSize: isMobile ? '14.5px' : '18px',
              fontFamily: 'Titillium Web',
              fontWeight: 400,
              color: '#4E4E4E',
            },
          },
          Popover: {
            style: {
              borderRadius: '8px',
            },
            props: {
              overrides: {
                Body: {
                  style: () => ({
                    marginTop: '6px',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    color: $theme.colors.white,
                  }),
                },
              },
            },
          },
          DropdownContainer: {
            style: {
              borderRadius: '8px',
            },
          },
          Dropdown: {
            style: {
              borderRadius: '8px',
              marginLeft: '0px',
              marginRight: '0px',
            },
          },
          DropdownListItem: {
            style: {
              padding: '0px',
              margin: '0px',
            },
          },
          SingleValue: {
            style: ({ $theme: theme }) => ({
              fontSize: isMobile ? '14.5px' : '18px',
              color: theme.cannonColors.secondarySilverDark,
              fontFamily: 'Titillium Web',
            }),
          },
          ClearIcon: {
            component: () =>
              value && (
                <ClearContainer onClick={handleClear}>
                  <CloseIcon />
                </ClearContainer>
              ),
          },
          SelectArrow: { component: () => <ArrowDown /> },
        }}
        getOptionLabel={(e) => {
          const { $disabled, $selected } = e.optionState;
          return (
            <>
              <CustomLabel
                isMobile={isMobile}
                $disabled={$disabled}
                $selected={$selected}
              >
                {e.option.label}
              </CustomLabel>
            </>
          );
        }}
        {...other}
      />
      {error && (
        <ParagraphSmall
          color={theme.cannonColors.secondaryCarmineLight}
          className={errorMsgStyle}
          margin={0}
        >
          {errorMessage}
        </ParagraphSmall>
      )}
    </MainContainer>
  );
};

export default CustomDropdown;
