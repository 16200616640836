import React from 'react';
import { cannonStyled } from '../../theme';
import FullStar from '../../assets/full-star.svg';
import HalfStar from '../../assets/half-star.svg';
import HalfStarGray from '../../assets/half-star-gray.svg';
import EmptyStar from '../../assets/empty-star.svg';

const Container = cannonStyled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
interface IStarRating {
  ratingValue: number;
  halfStarStyle?: 'white' | 'gray';
  size?: string;
}

const StarRating: React.FC<IStarRating> = (props) => {
  const { size = '18px' } = props;
  return (
    <Container>
      {[0, 1, 2, 3, 4].map((starValue) => {
        const currentStarValue = props.ratingValue - starValue;
        if (currentStarValue >= 1)
          return (
            <FullStar style={{ width: size, height: size }} key={starValue} />
          );
        if (currentStarValue > 0)
          return props.halfStarStyle === 'gray' ? (
            <HalfStarGray
              style={{ width: size, height: size }}
              key={starValue}
            />
          ) : (
            <HalfStar style={{ width: size, height: size }} key={starValue} />
          );
        return (
          <EmptyStar style={{ width: size, height: size }} key={starValue} />
        );
      })}
    </Container>
  );
};
export default StarRating;
