import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ seo = {} }) => {
  
  const { title, keyword, description, defaultSeo, siteName, addedTitle } = {};

  const finalTitle = seo.addedTitle
  ? seo.addedTitle + ' | ' + seo.title
  : seo.title;

  return (
    <Helmet>
      <html lang='en' />
      <title>{finalTitle}</title>
      <meta name="keywords" content={seo.keyword} />
      <meta name='description' content={seo.description} />
    </Helmet>
  );
};

export default Seo;