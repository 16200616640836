import React, { useEffect, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactModal from 'react-modal';
import { useTableOrMobile } from '../../hooks';
import { cannonStyled } from '../../theme';
import { ICannonSafe } from '../../pages';
import { ICannonElMobile } from '../../@types';
import useFindYourSafe from './useFindYourSafe';
import FindYourSafeQuiz from './FindYourSafeQuiz/FindYourSafeQuiz';
import FindYourSafeResults from './FindYourSafeResults/FindYourSafeResults';
import './FindYourSafe.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import analyticsCustomEvent from '../../atoms/js/analyticsCustomEvent';

ReactModal.setAppElement('#___gatsby');

const query = graphql`
  query {
    allStrapiCannonSafes {
      edges {
        node {
          id
          displayName
          bestFor
          slug
          reviews {
            id
            title
            value
            description
          }
          safeCategory {
            title
          }
          series {
            title
          }
          averagePrice
          firearmCapacity
          specifications
          detailImages {
            image {
              formats {
                medium {
                  url
                }
                small {
                  url
                }
              }
              url
            }
          }
          coverImage {
            formats {
              medium {
                url
              }
              small {
                url
              }
            }
          }
          intDimensions {
            depth
            height
            width
          }
          features {
            basicSecureStorage
            electronicsCapable
            fireProtection
            firearmCapable
            floodProtection
            heirloomsCapable
            jewleryCapable
            memorabiliaCapable
            priceRange
            theftProtection
            waterproofText
            convertibleInteriorText
          }
          displayImage {
            alternativeText
            url
            formats {
              medium {
                url
              }
              small {
                url
              }
            }
          }
        }
      }
    }
  }
`;
const FindYourSafeModal = cannonStyled(ReactModal, {});

const FYSWrapper = cannonStyled('div', (props: ICannonElMobile) => ({
  maxWidth: '1200px',
  height: '90%',
  padding: props.$isMobile ? '10px 0px' : '50px 30px',
  position: 'relative',
  marginRight: 'auto',
  marginLeft: 'auto',
}));

interface IData {
  allStrapiCannonSafes: {
    edges: Array<{ node: ICannonSafe }>;
  };
}

const sortSafeByPriceDesc = (a: ICannonSafe, b: ICannonSafe): number => {
  return b.averagePrice - a.averagePrice;
};

const FindYourSafeOverlay: React.FC = () => {
  const data = useStaticQuery<IData>(query);
  const {
    importantItem,
    importantProtection,
    priceRange,
    fireArmCapacity,
    openedOverlay,
    searchResults,
    toggleOverlay,
    clearParams,
  } = useFindYourSafe();
  const { width } = useWindowDimensions();
  const isMobile = width <= 950;
  const isTablet = width >= 950 && width <= 1280;

  const handleClose = () => {
    toggleOverlay();
    clearParams();
  };

  const shouldSearchResults = useMemo(() => {
    if (!searchResults) {
      return false;
    }
    if (!importantProtection || !priceRange) return false;
    if (importantItem === 'firearms' && !fireArmCapacity) return false;
    return searchResults;
  }, [
    searchResults,
    importantItem,
    priceRange,
    importantProtection,
    fireArmCapacity,
  ]);

  const [safeResults, moreSafes] = useMemo(() => {
    if (!shouldSearchResults) return [[], []];
    const allCannonSafes = data.allStrapiCannonSafes.edges.map((el) => el.node);
    const importantProtectionFiltered = allCannonSafes.filter((s) => {
      //importantProtection filters
      if (importantProtection === 'flood' && !s.features.floodProtection) {
        return false;
      }
      if (importantProtection === 'fire' && !s.features.fireProtection) {
        return false;
      }
      if (importantProtection === 'theft' && !s.features.theftProtection) {
        return false;
      }
      if (importantProtection === 'basic' && !s.features.basicSecureStorage) {
        return false;
      }
      return true;
    });

    const filteredSafes = importantProtectionFiltered.filter((s) => {
      //importantItem filters
      if (importantItem === 'firearms' && !s.features.firearmCapable) {
        return false;
      }
      if (importantItem === 'heirlooms' && !s.features.heirloomsCapable) {
        return false;
      }
      if (importantItem === 'jewleryWatches' && !s.features.jewleryCapable) {
        return false;
      }
      if (
        importantItem === 'memorabiliaPhotos' &&
        !s.features.memorabiliaCapable
      ) {
        return false;
      }
      if (importantItem === 'electronics' && !s.features.electronicsCapable) {
        return false;
      }
      // priceRange filters
      if (s.features.priceRange !== priceRange) {
        return false;
      }
      //Firearm capacity filters
      if (importantItem === 'firearms' && fireArmCapacity.length > 0) {
        const safeFireArmCapacity = s.firearmCapacity;
        if (safeFireArmCapacity === null || safeFireArmCapacity === 0)
          return false;
        const validCapacities = [fireArmCapacity.split('-')];
        let validCapacity = false;

        validCapacities.forEach((element) => {
          if (
            safeFireArmCapacity > Number(element[0]) &&
            safeFireArmCapacity < Number(element[1])
          ) {
            validCapacity = true;
          }
        });
        if (!validCapacity) return false;
      }
      return true;
    });
    const sortedSafes = [
      ...new Set([
        ...filteredSafes.sort(sortSafeByPriceDesc),
        ...importantProtectionFiltered.sort(sortSafeByPriceDesc),
        ...allCannonSafes.sort(sortSafeByPriceDesc),
      ]),
    ];
    const initialSafeResultsIndex = 0;
    const lastSafeResultsIndex = 2;
    const safeResults = sortedSafes.slice(
      initialSafeResultsIndex,
      lastSafeResultsIndex
    );
    const initialMoreSafesIndex = lastSafeResultsIndex;
    const lastMoreSafesIndex =
      initialMoreSafesIndex + (isMobile ? 2 : isTablet ? 3 : 4);
    const moreSafes = sortedSafes.slice(
      initialMoreSafesIndex,
      lastMoreSafesIndex
    );
    return [safeResults, moreSafes];
  }, [shouldSearchResults]);

  useEffect(() => {
    if (openedOverlay) {
      analyticsCustomEvent('find_your_safe', { page_view: location.pathname });
    }
  }, [openedOverlay]);

  return (
    <FindYourSafeModal
      isOpen={openedOverlay}
      onRequestClose={handleClose}
      className='find-your-safe-modal modal-available-height'
      overlayClassName='React-Modal-Overlay'
    >
      <FYSWrapper $isMobile={isMobile}>
        {shouldSearchResults ? (
          <FindYourSafeResults
            safeResults={safeResults}
            moreSafes={moreSafes}
          />
        ) : (
          <FindYourSafeQuiz />
        )}
      </FYSWrapper>
    </FindYourSafeModal>
  );
};

export default FindYourSafeOverlay;
