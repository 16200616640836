import React, { useEffect, useState } from 'react';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { CannonSafeTheme } from '../../theme';
import { AppBar } from '../../atoms/AppBar';
import { Footer } from '../../atoms/Footer';
import Wrapper from './Wrapper';
import ContextProviders from './ContextProviders';
import FindYourSafeOverlay from '../../atoms/FindYourSafe/FindYourSafeOverlay';
import './index.css';
import ScrollTopButton from '../../atoms/ScrollTopButton';
import SEO from '../../components/seo';
import { Description } from 'joi';
import { metadata } from 'core-js/library/es6/reflect';
import { graphql, useStaticQuery, PageProps } from 'gatsby';
import _ from 'lodash';
import { useCoreAPIURL, useTableOrMobile } from '../../hooks';

const query = graphql`
  query {
    strapiSeo {
      seoComponent {
        description
        keywords
        title
        pageType
        id
      }
    }
    allStrapiSafeCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    allStrapiLifeStyleVariation {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`;

interface seoData {
  title?: string;
  content?: string;
  keywords?: string;
}

declare interface LayoutProviderProps {
  seo: boolean;
  children: any;
  locationSearch?: string;
  metadata?: SeoArray;
  titleExt?: string;
}

interface SeoArray {
  description: string;
  keywords: string;
  title: string;
  pageType: string;
}

interface strapiSeo {
  strapiSeo: { seoComponent: SeoArray };
}

export default (props: LayoutProviderProps) => {
  const data = useStaticQuery<strapiSeo>(query);
  const CORE_APIURL = useCoreAPIURL();

  // Replacement Parts

  function clearLocalStorage(orderID: string) {
    let itemsLocalStorage = [];
    itemsLocalStorage = JSON.parse(
      localStorage.getItem('replcacement-parts-need-to-save-array')
    );
    itemsLocalStorage = itemsLocalStorage.filter(
      (i) => i.paypalOrderID === orderID
    );
    localStorage.setItem(
      'replcacement-parts-need-to-save-array',
      JSON.stringify(itemsLocalStorage)
    );
  }

  async function handleRPSendData(data: any) {
    const response = await fetch(CORE_APIURL + '/replacement-parts', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((r) => r.json())
      .then((r) => r)
      .catch((e) => {
        console.log('Error Replacmenet Parts Endpoint', e);
      });
    if (response.message.includes('Your answers are saved')) {
      console.log('success replacement parts');
      const { paypalOrderID = '' } = data.data;
      clearLocalStorage(paypalOrderID);
    } else {
      console.log('error');
    }
  }

  useEffect(() => {
    const itemsLeftToSendRP =
      JSON.parse(
        localStorage.getItem('replcacement-parts-need-to-save-array')
      ) || [];
    if (itemsLeftToSendRP.length > 0) {
      itemsLeftToSendRP.forEach((element: any) => {
        handleRPSendData(element);
      });
    }
  }, []);

  // select web section
  let pathname = typeof window !== 'undefined' ? window.location.pathname : '/';

  let currentPage =
    pathname === '/'
      ? 'index'
      : pathname === '/user-manuals/'
      ? 'usermanual'
      : pathname.split('/')[1].split('-').join('');

  // search and select metaData from strapi
  let currentMetatag = _.find(data.strapiSeo.seoComponent, {
    pageType: currentPage,
  });

  const finalCurrentMetatag = currentMetatag
    ? currentMetatag
    : _.find(data.strapiSeo.seoComponent, { pageType: 'defaultValue' });

  const [engine, setEngine] = useState(null);
  const [hideSocialBar, setHideSocialBar] = useState(false);
  const [isMobile] = useTableOrMobile();

  useEffect(() => {
    // Load the `styletron-engine-atomic` package dynamically.
    // Reason: It requires use of `document`, which is not available
    // outside the browser, so we need to wait until it successfully loads.
    // Source: https://www.gatsbyjs.org/docs/debugging-html-builds/
    import('styletron-engine-atomic').then((styletron) => {
      const clientEngine = new styletron.Client();
      setEngine(clientEngine);
    });
  }, []);

  if (!engine) return null;

  const onScroll = (e) => {
    if (e.target.scrollTop >= 38) {
      setHideSocialBar(true);
    } else {
      setHideSocialBar(false);
    }
  };

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={CannonSafeTheme}>
        <ContextProviders locationSearch={props.locationSearch}>
          <p aria-hidden='true' hidden style={{ display: 'none' }}>
            Hey Dan/TP Csydor here!
          </p>
          <p aria-hidden='true' hidden style={{ display: 'none' }}>
            Test for Gatsby Cloud Integration
          </p>
          <AppBar
            hideNavBar={isMobile && hideSocialBar}
            hideSocialBar={hideSocialBar}
          />
          <FindYourSafeOverlay />
          <Wrapper socialHidden={hideSocialBar} onScroll={onScroll} main={true}>
            {SEO && (
              <SEO
                seo={{
                  title: finalCurrentMetatag.title,
                  description: finalCurrentMetatag.description,
                  keyword: finalCurrentMetatag.keywords,
                  addedTitle: props.titleExt,
                }}
              />
            )}
            {props.children}
            <Footer />
          </Wrapper>
        </ContextProviders>
      </BaseProvider>
    </StyletronProvider>
  );
};
