import React from 'react';
import { useCannonStyletron, cannonStyled } from '../../../theme';
import { LeftContainer, CenteredContainer } from '../../Container';
import useFindYourSafe from '../../FindYourSafe/useFindYourSafe';

import Underlined from '../../Label/Underlined';
import { useCCE } from '../querys/queryCCanExp';
import Underline from './Underline';

const ContainerInfo = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
});

export default () => {
  const [css, theme] = useCannonStyletron();
  const { toggleOverlay } = useFindYourSafe();
  const data = useCCE();

  // ARRAYS FROM STRAPI
  const dropArray = data.strapiCannonExperience.dropZoneCannonExperience;
  const pagesArray = data.strapiCannonExperience.pages || [];

  //NEW ARRAY WITH BOTH PAGES & DROPZONE
  const duplicateArray = dropArray.concat(pagesArray);

  //REMOVING DUPLICATES
  const uniqueArray = duplicateArray.filter(
    (dropArray, index, a) =>
      a.findIndex(
        (t) => t.title === dropArray.title && t.href === dropArray.href
      ) === index
  );

  const handleClick = ({ title, href }) => {
    if (title === 'Find your safe ') {
      toggleOverlay();
    } else {
      window.location.href = href;
    }
  };

  return (
    <ContainerInfo>
      <LeftContainer>
        <CenteredContainer className={css({ justifyContent: 'left' })}>
          <Underlined color={theme.cannonColors.primaryWhiteLight}>
            {data.strapiCannonExperience.header}
          </Underlined>
        </CenteredContainer>
        {uniqueArray.map((id) => (
          <Underline onClick={() => handleClick(id)}>{id.title}</Underline>
        ))}
      </LeftContainer>
    </ContainerInfo>
  );
};
