import React, { useRef, useState } from 'react';
import { Button } from '..';
import { cannonStyled, NSH4, TWH5, useCannonStyletron } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import Animation, { AppearUp } from '../Animations';

import ArrowRight from '../  ../../../assets/arrow-right.svg';
import { useIsVisible, useTableOrMobile } from '../../hooks';

type ICardContainer = {
  isMobile: boolean;
  $theme?: CannonSafeThemeT;
};

const CardContainer = cannonStyled('div', (props: ICardContainer) => {
  const { $theme: theme } = props;

  return {
    cursor: 'pointer',
    position: 'relative',
    width: 'calc(100% - 20px)',
    ...(props.isMobile
      ? { paddingTop: '100%', marginLeft: '5px', marginRight: '5px' }
      : { height: 'calc(100% - 20px)' }),
    padding: '10px',
    borderRadius: theme.sizing.scale300,
    boxShadow: `0px 40px 29px -24px rgba(0, 0, 0, 0.12)`,
    backgroundColor: theme.cannonColors.primaryGray,
    alignSelf: 'center',
    overflow: 'hidden',
  };
});

const CardContentContainer = cannonStyled('div', {
  position: 'absolute',
  top: '0px',
  left: '0px',
  bottom: '0px',
  right: '0px',
  padding: 'min(4vw, 40px)',
  background:
    'radial-gradient(79.84% 50% at 12.38% 105.2%, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.7) 30.38%, rgba(0, 0, 0, 0.530656) 59.34%, rgba(0, 0, 0, 0) 100%), url(.jpg)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
});

const CardBackgroundImage = cannonStyled('img', (props) => ({
  minHeight: '100%',
  minWidth: '100%',
  objectFit: 'cover',
}));

const CardBackgroundImageContainer = cannonStyled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
});

const ImageCard = ({
  src,
  title,
  condensedText,
  actionTitle,
  category,
  slug,
  styled = null,
}) => {
  const [css, theme] = useCannonStyletron();
  const [hovered, setHovered] = useState(false);
  const contentRef = useRef();
  const isVisible = useIsVisible(contentRef);
  const [isMobile] = useTableOrMobile();

  const categoryUrl = category.trim().toLowerCase().replace(/\./g, '').replace(/ /g, '-');
  const textExtract = condensedText.length > 80
  ? condensedText.slice(0, 80) + ' ...'
  : condensedText;

  return (
    <CardContainer
      isMobile={isMobile}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={contentRef}
      className={styled}
      onClick={() => (window.location.href = `blog/${categoryUrl}/${slug}`)}
    >
      <Animation>
        <CardBackgroundImageContainer>
          <CardBackgroundImage src={src} alt={title} />
        </CardBackgroundImageContainer>
        <CardContentContainer>
          <div style={isVisible ? AppearUp : {}}>
            <NSH4
              $style={{ fontSize: 'min(6vw, 28px)' }}
              color={theme.cannonColors.primaryWhiteLight}
              weight={900}
              align='left'
            >
              {title}
            </NSH4>
            <TWH5
              $style={{ fontSize: 'min(5vw, 18px)' }}
              color={theme.cannonColors.primaryWhiteLight}
              weight={400}
              align='left'
            >
              {textExtract}
            </TWH5>
            <Button.RoundedButton
              size='mini'
              clean
              margin={15}
              color='none'
              padding='12px 14px 12px 1px'
              endEnhancer={<ArrowRight style={{ stroke: 'white' }} />}
            >
              {actionTitle}
            </Button.RoundedButton>
          </div>
        </CardContentContainer>
      </Animation>
    </CardContainer>
  );
};

export default ImageCard;
