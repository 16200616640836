import React from 'react';
import { cannonStyled, useCannonStyletron } from '../../theme';
import { IThemeProp } from '../Cards/CapabilityCard';

import { Paragraph3 } from 'baseui/typography';

interface borderColor extends IThemeProp {}

const Underlined = cannonStyled(Paragraph3, (props: borderColor) => ({
  fontFamily: 'Titillium Web !important',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: 2,
  borderBottom: `2px solid ${props.$theme.cannonColors.secondaryOrange}`,
  //borderBottomColor: props.$theme.cannonColors.secondaryOrange,
}));

export default Underlined;
