import React from 'react';
import ReactMarkdown from 'react-markdown';
import { API_URL } from '../../constants';
import { useTableOrMobile } from '../../hooks';
import { IDocumentsPage } from '../../templates/documentsPage';
import { cannonStyled, useCannonStyletron } from '../../theme';
import { RoundedButton } from '../Button';

const MainContainer = cannonStyled('div', (props: { isMobile: boolean }) => ({
  width: '64vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: '40px',
  paddingBottom: props.isMobile ? '10vh' : '15vh',
}));

const ContainerDropZone = cannonStyled('div', () => ({
  width: '100%',
  marginTop: '10vh',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

const NSH1 = cannonStyled(
  'h1',
  (props: { color: string; isMobile: boolean }) => ({
    fontFamily: 'Noto Serif',
    fontSize: props.isMobile ? '31px' : `44px`,
    fontWeight: 900,
    color: props.color,
    textAlign: props.isMobile ? 'left' : 'center',
    paddingBottom: props.isMobile ? '14px' : '60px',
  })
);

const TWH2 = cannonStyled(
  'h1',
  (props: { color: string; isMobile: boolean }) => ({
    fontFamily: 'Titillium Web',
    fontSize: props.isMobile ? '22px' : `30px`,
    fontWeight: 700,
    color: props.color,
    textAlign: 'left',
  })
);

const TWH3 = cannonStyled(
  'h1',
  (props: { color: string; isMobile: boolean }) => ({
    fontFamily: 'Titillium Web',
    fontSize: props.isMobile ? '19px' : `26px`,
    fontWeight: 700,
    color: props.color,
    textAlign: 'left',
  })
);

export const TWParagraph2 = cannonStyled(
  'p',
  (props: { color: string; isMobile?: boolean; margin?: number }) => ({
    fontFamily: 'Titillium Web',
    display: 'inline-block',
    margin: props.margin,
    fontSize: props.isMobile ? '17px' : `20px`,
    fontWeight: 400,
    color: props.color,
    textAlign: 'justify',
  })
);

export const TWA = cannonStyled(
  'a',
  (props: { color: string; isMobile?: boolean; margin?: number }) => ({
    fontFamily: 'Titillium Web',
    display: 'inline-block',
    margin: props.margin,
    fontSize: props.isMobile ? '17px' : `20px`,
    fontWeight: 400,
    color: props.color,
    textDecoration: 'underline',
    textAlign: 'justify',
  })
);

const DocumentParser = (props: IDocumentsPage) => {
  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();

  return (
    <>
      <MainContainer isMobile={isMobile}>
        <ReactMarkdown
          components={{
            h1: ({ node, ...props }) => (
              <NSH1
                isMobile={isMobile}
                color={theme.cannonColors.primaryBlue}
                {...props}
              />
            ),
            h2: ({ node, ...props }) => (
              <TWH2
                isMobile={isMobile}
                color={theme.cannonColors.primaryBlue}
                {...props}
              />
            ),
            h3: ({ node, ...props }) => (
              <TWH3
                isMobile={isMobile}
                color={theme.cannonColors.primaryBlue}
                {...props}
              />
            ),
            p: ({ node, ...props }) => (
              <TWParagraph2
                isMobile={isMobile}
                color={theme.cannonColors.secondarySilverDark}
                {...props}
              />
            ),
            ol: ({ node, ordered, ...props }) => (
              <TWParagraph2
                isMobile={isMobile}
                color={theme.cannonColors.secondarySilverDark}
                {...props}
              />
            ),
            a: ({ node, ...props }) => (
              <TWA
                margin={0}
                isMobile={isMobile}
                color={theme.cannonColors.secondaryOrange}
                {...props}
              />
            ),
          }}
        >
          {props.Body}
        </ReactMarkdown>
        <ContainerDropZone>
          {props.dropZonePages.map((item) => {
            if (item.enable)
              return (
                <RoundedButton
                  size='compact'
                  style={{ width: '37vw' }}
                  color='primary'
                  onClick={() => (window.location.href = item.href)}
                >
                  {item.title}
                </RoundedButton>
              );
          })}
        </ContainerDropZone>
      </MainContainer>
    </>
  );
};

export default DocumentParser;
