import React from 'react';
import { useTableOrMobile } from '../../hooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { cannonStyled } from '../../theme';

const FixedWrapper = cannonStyled('div', (props) => ({
  position: 'fixed',
  top: 0,
  width: '100vw',
  minHeight: '50px',
  display: 'flex',
  backgroundColor: props.$theme.cannonColors.primaryBlueBg,
  zIndex: 999,
}));

const ButtonsWrapper: React.FC = ({ children }) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 950;
  if (isMobile) return <FixedWrapper>{children}</FixedWrapper>;
  else return <>{children}</>;
};

export default ButtonsWrapper;
