import React from 'react';
import { Button, ButtonProps } from 'baseui/button';

import '../../Button/underlined-button.css';
import { useCannonStyletron } from '../../../theme';
import { useTableOrMobile } from '../../../hooks';

interface UnderlinedButtonProps extends ButtonProps {
  isActive?: boolean;
}

const UnderlinedButton = (props: UnderlinedButtonProps) => {
  const [css, theme] = useCannonStyletron();

  return (
    <Button
      {...props}
      kind='minimal'
      size='compact'
      overrides={{
        BaseButton: {
          style: {
            justifyContent: 'left',
            textAlign: 'left',
            paddingLeft: 0,
            fontFamily: 'Titillium Web',
            fontWeight: 400,
            color: theme.cannonColors.primaryWhite,
            position: 'relative',
            ':hover': {
              backgroundColor: 'transparent',
            },
            ':active': {
              backgroundColor: 'transparent',
            },
          },
        },
      }}
    >
      <span
        className={`menu-button-selector ${props.isActive ? 'active' : ''}`}
        style={{ '--var-color': theme.cannonColors.secondaryOrange }}
      >
        {props.children}
      </span>
    </Button>
  );
};

export default UnderlinedButton;
