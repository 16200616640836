import React from 'react';
import { cannonStyled } from '../../theme';

const HorizontalFlex = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export default HorizontalFlex;
