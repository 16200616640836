import FirearmsIcon from '../../../assets/firearm.svg';
import JewelryIcon from '../../../assets/jewlery.svg';
import HeirloomsIcon from '../../../assets/heirloom.svg';
import MemorabiliaIcon from '../../../assets/Memorabilia.svg';
import ElectronicsIcon from '../../../assets/camera.svg';
import FloodIcon from '../../../assets/flood.svg';
import FireIcon from '../../../assets/fire.svg';
import TheftIcon from '../../../assets/theft.svg';
import BasicSecurityIcon from '../../../assets/basic-security.svg';
import HighEndIcon from '../../../assets/high-end.svg';
import EssentialEndIcon from '../../../assets/essential-end.svg';
import BasicEndIcon from '../../../assets/thumbs-up.svg';
import TwoFirearmsIcon from '../../../assets/twoFirearms.svg';
import ThreeFirearmsIcon from '../../../assets/threeFirearms.svg';
import FourFirearmsIcon from '../../../assets/fourFirearms.svg';

export interface QuizOption {
  label: string;
  value: string;
  icon: React.FC;
}

export const itemOptions: Array<QuizOption> = [
  { label: 'Firearms', value: 'firearms', icon: FirearmsIcon },
  { label: 'Jewlery / Watches', value: 'jewleryWatches', icon: JewelryIcon },
  { label: 'Heirlooms', value: 'heirlooms', icon: HeirloomsIcon },
  {
    label: 'Memorabilia / Photos',
    value: 'memorabiliaPhotos',
    icon: MemorabiliaIcon,
  },
  { label: 'Electronics', value: 'electronics', icon: ElectronicsIcon },
];

export const protectOptions: Array<QuizOption> = [
  { label: 'Flood protection', value: 'flood', icon: FloodIcon },
  { label: 'Fire protection', value: 'fire', icon: FireIcon },
  { label: 'Theft protection', value: 'theft', icon: TheftIcon },
  {
    label: 'Basic secure storage',
    value: 'basic',
    icon: BasicSecurityIcon,
  },
];

export const priceOptions: Array<QuizOption> = [
  {
    label: 'High end model with extra features',
    value: 'high',
    icon: HighEndIcon,
  },
  {
    label: 'Essential features with a few addded items',
    value: 'mid',
    icon: EssentialEndIcon,
  },
  { label: 'Just the basics', value: 'basic', icon: BasicEndIcon },
];

export const firearmCapacityOptions: Array<QuizOption> = [
  {
    label: 'Up to 20 firearms',
    value: '0-20',
    icon: TwoFirearmsIcon,
  },
  {
    label: '21 to 40 firearms',
    value: '21-40',
    icon: ThreeFirearmsIcon,
  },
  { label: '41+ firearms', value: '40-999', icon: FourFirearmsIcon },
];
