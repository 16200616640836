import { Paragraph3, Paragraph4 } from 'baseui/typography';
import React from 'react';
import {
  cannonStyled,
  useCannonStyletron,
  CannonSafeThemeT,
} from '../../theme/CannonSafesTheme';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';
import { useTableOrMobile } from '../../hooks';
import Underline from '../Footer/components/Underline';

export interface IThemeProp {
  $theme?: CannonSafeThemeT;
}

interface INewsCard extends IThemeProp {
  imgSrc: string;
  title: string;
  description: string;
}

const CardContainer = cannonStyled(
  'div',
  ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => ({
    display: 'grid',
    height: 'auto',
    gridTemplateColumns: isMobile ? '1fr 10fr' : '1fr 2fr',
    columnGap: '10px',
    marginBottom: '15px',
    padding: isMobile ? '0px 30px 10px 30px' : '',
  })
);

const PostLabel = cannonStyled('div', {});

const PostText = cannonStyled('div', {});

const ImgThumbnail = cannonStyled('img', {
  width: '100px',
  height: '100px',
  borderRadius: '3px',
  objectFit: 'cover',
  //aspectRatio: 1/1,
});

const NewsCard = (props: INewsCard) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  return (
    <Underline>
      <CardContainer isMobile={isMobile} isTablet={isTablet}>
        <ImgThumbnail src={props.imgSrc} alt={props.title}/>
        <div>
          <PostLabel>
            <Paragraph3
              margin={0}
              color={theme.cannonColors.primaryWhite}
              className={css({
                fontFamily: 'Titillium Web !important',
                fontWeight: 700,
                fontSize: '14px',
              })}
            >
              {props.title}
            </Paragraph3>
          </PostLabel>
          <PostText>
            <Paragraph4
              margin={0}
              color={theme.cannonColors.primaryWhite}
              className={css({
                fontFamily: 'Titillium Web !important',
                fontWeight: 400,
                fontSize: '12px',
              })}
            >
              {props.description
                ? !isMobile && !isTablet
                  ? props.description.slice(0, 30)
                  : '' 
                : ''}
              {props.description ? !isMobile && !isTablet && props.description.length > 30
                ? 'Read More ...'
                : ''
              : ''}

              {isTablet ? props.description.slice(0, 100) : ''}
              {isTablet && props.description.length > 100 ? '...' : ''}

              {isMobile ? props.description.slice(0, 80) : ''}
              {isMobile && props.description.length > 80 ? '...' : ''}
            </Paragraph4>
          </PostText>
          <span className={css({ paddingBottom: '10px' })}></span>
        </div>
      </CardContainer>
    </Underline>
  );
};

export default NewsCard;
