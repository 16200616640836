import React from 'react';
import { useCannonStyletron } from '../../theme';
import { Link } from 'gatsby';
import { useTableOrMobile } from '../../hooks';
import StyledBorder from './components/StyledBorder';
import { useLegalLinks } from './querys/queryLegalLinks';

export default function BottomLinks() {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const data = useLegalLinks();

  const arr = data.strapiLegalLinks.pages.map((id) => [
    <span
      className={css({
        display: 'inline-block',
        justifyContent: 'center',
        alignItems: 'center',
        textDecorationLine: 'none',
        color: theme.cannonColors.primaryGray,
        fontSize: 'min(3vw,14px)',
        cursor: 'pointer',
      })}
      onClick={() => (window.location.href = `/${id.href}`)}
    >
      {id.title}
    </span>,
  ]);

  const components = arr.map((item, index) => {
    if (index != arr.length - 1 && arr.length > 0) {
      return (
        <>
          <StyledBorder
            isMobile={isMobile}
            isRight={false}
            color={theme.cannonColors.secondaryOrange}
          >
            {item}
          </StyledBorder>
        </>
      );
    } else {
      return (
        <StyledBorder
          isMobile={isMobile}
          isRight={true}
          color={theme.cannonColors.secondaryOrange}
        >
          {item}
        </StyledBorder>
      );
    }
  });

  return <div style={{ padding: '8px 0px 8px 0px' }}>{components}</div>;
}
