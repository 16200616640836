import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query {
    strapiLegalLinks {
      pages {
        title
        href
      }
    }
  }
`;

interface LegalArray {
  title: string;
  href: string;
}

interface ILegalLinks {
  strapiLegalLinks: {
    pages: Array<LegalArray>;
  };
}

export const useLegalLinks = (): ILegalLinks => useStaticQuery(query);
