import React from 'react';
import useFindYourSafe from '../useFindYourSafe';
import {
  firearmCapacityOptions,
  itemOptions,
  priceOptions,
  protectOptions,
} from './quiz-options.constants';
import OptionsRow from './OptionsRow';
import { cannonStyled } from '../../../theme';

const OptionsWrapper = cannonStyled('div', {
  width: '100%',
  flexGrow: 0.25,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

interface CurrentStepProps {
  step: number;
  isMobile: boolean;
}

const CurrentStep: React.FC<CurrentStepProps> = ({ step, isMobile }) => {
  const {
    importantItem,
    importantProtection,
    priceRange,
    fireArmCapacity,
    setYourSafeContext,
  } = useFindYourSafe();

  if (isMobile) {
    return (
      <>
        {step === 0 && (
          <OptionsRow
            isColumn
            verticalButton
            currentOption={importantItem}
            options={itemOptions}
            question='1. What Items do you want to protect the most?'
            setCurrentOption={(newOption) => {
              setYourSafeContext('importantItem', newOption);
            }}
          />
        )}
        {step === 1 && (
          <OptionsRow
            isColumn
            verticalButton
            currentOption={importantProtection}
            options={protectOptions}
            question='2. What type of protection is most important to you?'
            setCurrentOption={(newOption) => {
              setYourSafeContext('importantProtection', newOption);
            }}
          />
        )}

        {step === 2 && (
          <OptionsRow
            isColumn
            verticalButton
            currentOption={priceRange}
            options={priceOptions}
            question='3. What do you look for when you buy something that will last long?'
            setCurrentOption={(newOption) => {
              setYourSafeContext('priceRange', newOption);
            }}
          />
        )}

        {step === 3 && (
          <OptionsRow
            isColumn
            verticalButton
            currentOption={fireArmCapacity}
            options={firearmCapacityOptions}
            question='Lastly, how many firearms do you need to store in your safe?'
            setCurrentOption={(newOption) => {
              setYourSafeContext('fireArmCapacity', newOption);
            }}
          />
        )}
      </>
    );
  } else {
    return (
      <OptionsWrapper>
        {step < 3 && (
          <>
            <OptionsRow
              currentOption={importantItem}
              options={itemOptions}
              question='1. What Items do you want to protect the most?'
              setCurrentOption={(newOption) => {
                setYourSafeContext('importantItem', newOption);
              }}
            />
            <OptionsRow
              currentOption={importantProtection}
              options={protectOptions}
              question='2. What type of protection is most important to you?'
              setCurrentOption={(newOption) => {
                setYourSafeContext('importantProtection', newOption);
              }}
            />
            <OptionsRow
              currentOption={priceRange}
              options={priceOptions}
              question='3. What do you look for when you buy something that will last long?'
              setCurrentOption={(newOption) => {
                setYourSafeContext('priceRange', newOption);
              }}
            />
          </>
        )}
        {step >= 3 && (
          <OptionsRow
            bigMarginTop
            currentOption={fireArmCapacity}
            verticalButton
            options={firearmCapacityOptions}
            question='Lastly, how many firearms do you need to store in your safe?'
            setCurrentOption={(newOption) => {
              setYourSafeContext('fireArmCapacity', newOption);
            }}
          />
        )}
      </OptionsWrapper>
    );
  }
};

export default CurrentStep;
