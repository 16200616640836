import { graphql, useStaticQuery } from 'gatsby';
import { IDropZonePages } from '../../../templates/documentsPage';

const query = graphql`
  query {
    strapiOwnerCenter {
      header
      dropZoneOwnerCenter
      pages {
        title
        href
      }
    }
  }
`;

interface PagesArray {
  title: string;
  href: string;
}

interface IColOwnCen {
  strapiOwnerCenter: {
    header: string;
    dropZoneOwnerCenter: Array<IDropZonePages>;
    pages: Array<PagesArray>;
  };
}

export const useCOC = (): IColOwnCen => useStaticQuery(query);
