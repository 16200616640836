import en from './en';
import LanguageT from './index.t';

type SupportedLanguage = 'en';

type LanguageEl = keyof LanguageT;

export default (language: SupportedLanguage, key: LanguageEl) => {
  let languagePackage = en;
  switch (language) {
    default:
      languagePackage = en;
      break;
  }

  return languagePackage[key];
};
