import React from 'react';
import { StyleObject } from 'styletron-standard';
import { cannonStyled, useCannonStyletron } from '../../theme';

interface IGridContainer {
  isMobile: boolean;
  isTablet: boolean;
  spacing: string;
  items?: number;
  defaultItems?: number;
  children?: React.ReactNode;
  carrousel?: boolean;
  width: string;
  direction: 'vertical' | 'horizontal';
}

const GridContainer = cannonStyled('div', (props: IGridContainer) => ({
  display: 'grid',
  gridRowGap: props.spacing,
  gridColumnGap: props.spacing,
  width: props.width,
  maxWidth: '1280px',
  [props.direction === 'vertical' ? 'gridTemplateRows' : 'gridTemplateColumns']:
    props.items
      ? ' 1fr'.repeat(props.items)
      : props.isMobile
      ? '1fr'
      : props.isTablet
      ? ' 1fr'.repeat(2)
      : ' 1fr'.repeat(props.defaultItems || 4),
}));

export default GridContainer;
