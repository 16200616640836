import React from 'react';
import { cannonStyled } from '../../theme';
import { useCannonStyletron } from '../../theme';
import { useTableOrMobile } from '../../hooks';

const BlackOverlay = ({ alignIt = 'center', bottom = 0, children }) => {
  const [css, theme] = useCannonStyletron();

  const blackOverlay = css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `rgba(0,0,10,0.6)`,
    zIndex: 0,
    margin: 0,
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: alignIt,
    justifyContent: 'center',
  });

  const contentContainer = css({
    zIndex: 1,
    textAlign: 'center',
    paddingBottom: `${bottom}px`,
    paddingTop: '',
    paddingLeft: '20px',
    paddingRight: '20px',
  });

  return (
    <div className={blackOverlay}>
      <div className={contentContainer}>{children}</div>
    </div>
  );
};

export default BlackOverlay;
