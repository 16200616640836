import React from 'react';
import { useCannonStyletron, cannonStyled } from '../../theme';
import FamilyLogos from './FamilyLogos';
import BottomFooter from './BottomFooter';
import FooterColumns from './FooterColumns';
import ColumnsNews from './components/ColumnsNews';
import { useTableOrMobile } from '../../hooks';
import FooterMobile from './FooterMobile';

const WhiteBreak = cannonStyled('hr', {
  width: '90%',
  opacity: '20%',
});

export default () => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  return (
    <div
      className={css({
        backgroundColor: theme.cannonColors.primaryBlue,
      })}
    >
      {/* TO DO */}
      {isMobile ? (
        <React.Fragment>
          <FooterMobile />
          <ColumnsNews />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <FooterColumns />
        </React.Fragment>
      )}
      <WhiteBreak
        className={css({ color: theme.cannonColors.primaryBoneLight })}
      />
      <FamilyLogos />
      <BottomFooter />
    </div>
  );
};
