import React from 'react';
import { useCannonStyletron, cannonStyled } from '../../../theme';

import Underline from './Underline';
import Underlined from '../../Label/Underlined';
import { CenteredContainer, LeftContainer } from '../../Container';
import { useCPL } from '../querys/queryCProdLin';
import { navigate } from 'gatsby';

const ContainerInfo = cannonStyled(CenteredContainer, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
  alignSelf: 'flex-start',
});

export default () => {
  const [css, theme] = useCannonStyletron();
  const data = useCPL();

  return (
    <ContainerInfo>
      <LeftContainer>
        <CenteredContainer className={css({ justifyContent: 'left' })}>
          <Underlined color={theme.cannonColors.primaryWhiteLight}>
            {data.strapiProductLines.header}
          </Underlined>
        </CenteredContainer>
        {data.strapiProductLines.cannonSafeSeries.map((id) => (
          <Underline
            onClick={() => (window.location.href = `/product-lines/${id.slug}`)}
            href={id.slug}
          >
            {id.title + ' Series'}
          </Underline>
        ))}
      </LeftContainer>
    </ContainerInfo>
  );
};
