import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { cannonStyled } from '../../theme';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../theme/CannonSafesTheme';
import { useTableOrMobile } from '../../hooks';
import { AspectRatioBox } from 'baseui/aspect-ratio-box';
import { NSH4, ABH1, TWH5 } from '../../theme/Typography';
import { Button } from '../../atoms';

import FireArms from '../../assets/fire-arms.svg';
import WaterDrop from '../../assets/water-drop.svg';
import Convertible from '../../assets/convertible-interior.svg';

type ISafesCardContainer = {
  isMobile?: boolean;
  isTablet?: boolean;
  src?: string;
  $theme?: CannonSafeThemeT;
  isFindYourSafe?: boolean;
};

const CardContainer = cannonStyled(
  AspectRatioBox,
  (props: ISafesCardContainer) => ({
    margin: props.isMobile ? 'unset' : props.$theme.sizing.scale300,
    padding: props.$theme.sizing.scale600,
    position: 'relative',
    width: props.isFindYourSafe ? 'auto' : '268px',
  })
);

const SafeCardContent = cannonStyled('div', (props: ISafesCardContainer) => {
  return {
    position: 'relative',
    ...(props.isMobile ? { paddingTop: '40px' } : { paddingTop: '14px' }),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
  };
});

const SafeCardImage = cannonStyled('div', {
  position: 'relative',
  maxWidth: '268px',
  width: '100%',
  margin: '0 auto',
});

const SafeCardGridContainer = cannonStyled(
  'div',
  (props: ISafesCardContainer) => ({
    height: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: props.isMobile ? 'unset' : props.$theme.sizing.scale600,
  })
);

const SafeFeaturesDiv = cannonStyled('div', (props: ISafesCardContainer) => ({
  height: props.isMobile ? '240px' : '270px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '25px 0 0 0 ',
  padding: 0,
}));

const SafeNameDiv = cannonStyled('div', (props: ISafesCardContainer) => ({
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: props.isMobile ? '1px' : '16px',
}));

const ButtonDiv = cannonStyled('div', (props: ISafesCardContainer) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  margin: props.isMobile ? '12px 15px 0 15px' : '24px 15px 0 15px',
  paddingBottom: props.isMobile ? '18px' : '24px',
  borderBottom: '2px solid #F6F2DF',
}));

const SpecsContainer = cannonStyled('div', (props: ISafesCardContainer) => ({
  width: '100%',
  marginBottom: props.isMobile ? '6px' : '19px',
}));

const Card = ({
  displayImage,
  title,
  series,
  measures,
  features,
  action,
  safeCategory,
  style = 'blue',
  isFindYourSafe = false,
}) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  const isTextDoubleLine = useMediaQuery({ query: '(max-width: 1235px)' });

  const imageContainer = css({
    height: `${isMobile ? '240px' : '300px'}`,
    backgroundSize: 'auto 90%',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${displayImage})`,
    backgroundPosition: 'center center',
  });

  const gun = css({
    display: 'block',
    margin: '0 auto',
    width: '33px',
    height: '36px',
  });

  const drop = css({
    display: 'block',
    margin: '0 auto',
    height: '28px',
  });

  const convertible = css({
    display: 'block',
    margin: '0 auto',
    height: '34px',
  });

  const primaryfontColor =
    style === 'white'
      ? theme.cannonColors.primaryWhite
      : theme.cannonColors.primaryBlue;

  const secondaryfontColor =
    style === 'white' ? theme.cannonColors.primaryWhite : '#111';

  const thirdfontColor =
    style === 'white' ? theme.cannonColors.primaryWhite : '#4E4E4E';

  return (
    <CardContainer
      isMobile={isMobile}
      isTablet={isTablet}
      aspectRatio={302 / 533}
      isFindYourSafe={isFindYourSafe}
    >
      <SafeCardGridContainer isMobile={isMobile}>
        <SafeCardImage className={imageContainer}></SafeCardImage>

        <SafeCardContent>
          <SafeNameDiv isMobile={isMobile}>
            <NSH4
              weight={900}
              margin={0}
              color={primaryfontColor}
              align='center'
            >
              {title}
            </NSH4>
          </SafeNameDiv>
          <ABH1
            align='center'
            color={secondaryfontColor}
            weight={300}
            margin={3}
          >
            {series} Series
          </ABH1>
          <TWH5
            align='center'
            color={secondaryfontColor}
            weight={300}
            style={{ fontSize: `min(3.4vw, 14px)` }}
          >
            {measures}
          </TWH5>
          <ButtonDiv isMobile={isMobile}>
            <Button.RoundedButton
              size='compact'
              color={style === 'white' ? 'greenLight' : 'primary'}
              clean
              smallBtn
              isMobile={isMobile}
              onClick={action}
            >
              See all features
            </Button.RoundedButton>
          </ButtonDiv>

          <SafeFeaturesDiv isMobile={isMobile}>
            <SpecsContainer isMobile={isMobile}>
              <FireArms
                className={gun}
                style={{ fill: style === 'white' ? '#E3D9A0' : '#034078' }}
              />

              <TWH5
                align='center'
                color={thirdfontColor}
                weight={300}
                margin={5}
                $style={{ fontSize: `min(2.8vw, 16px)` }}
              >
                {safeCategory.includes('home')
                  ? 'Firearm capacity'
                  : features.firearmCapacity + ' Firearm capacity'}
              </TWH5>
            </SpecsContainer>
            <SpecsContainer isMobile={isMobile}>
              <WaterDrop
                className={drop}
                style={{ fill: style === 'white' ? '#E3D9A0' : '#034078' }}
              />
              <TWH5
                align='center'
                color={thirdfontColor}
                weight={300}
                margin={5}
                $style={{ fontSize: `min(2.8vw, 16px)` }}
              >
                {features.waterproofText ? 'Yes' : 'No'}
              </TWH5>
            </SpecsContainer>
            <SpecsContainer isMobile={isMobile}>
              <Convertible
                className={convertible}
                style={{ fill: style === 'white' ? '#E3D9A0' : '#034078' }}
              />
              <TWH5
                align='center'
                color={thirdfontColor}
                weight={300}
                margin={5}
                $style={{ fontSize: `min(2.8vw, 16px)` }}
              >
                {features.convertibleInterior ? 'Convertible' : 'No'}
              </TWH5>
            </SpecsContainer>
          </SafeFeaturesDiv>
        </SafeCardContent>
      </SafeCardGridContainer>
    </CardContainer>
  );
};

export default Card;
