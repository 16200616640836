import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ButtonGroup } from 'baseui/button-group';
import { UnderlinedButton } from '../Button';
import { useTableOrMobile } from '../../hooks';
import {
  cannonStyled,
  useCannonStyletron,
  TWH5,
  TWButtonMini,
} from '../../theme';
import { IThemeProp } from '../Cards/CapabilityCard';
import { initialRouteState } from '../../constants';
import { IRoute } from '../../@types';
import ArrowDown from '../../assets/accordion-arrow-down.svg';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';

const query = graphql`
  query {
    allStrapiSafeCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    allStrapiLifeStyleVariation {
      edges {
        node {
          title
          id
        }
      }
    }
  }
`;

interface ISupportResponse {
  allStrapiSafeCategory: {
    edges: Array<{
      node: {
        id: string;
        title: string;
      };
    }>;
  };
  allStrapiLifeStyleVariation: {
    edges: Array<{
      node: {
        id: string;
        title: string;
      };
    }>;
  };
}

interface IContentContainer extends IThemeProp {
  open?: boolean;
  socialHiden?: boolean;
  $theme?: CannonSafeThemeT;
}

const ContentContainer = cannonStyled('div', (props: IContentContainer) => ({
  position: 'absolute',
  top: props.socialHiden ? '75px' : '113px',
  left: 0,
  right: 0,
  height: 'auto',
  zIndex: 9999,
  backdropFilter: 'blur(4px)',
}));

const MenuButton = cannonStyled('div', (props: IContentContainer) => ({
  backgroundColor: props.$theme.cannonColors.secondaryOrange,
  borderRadius: '4px',
  padding: '5px 10px',
  textAlign: 'center',
}));

const ContentColumn = cannonStyled('div', (props: IContentContainer) => ({
  margin: '0px 35px',
}));

export default ({ hidden = false, inside = false }) => {
  const data = useStaticQuery<ISupportResponse>(query);
  const [css, theme] = useCannonStyletron();
  const [_, isTablet] = useTableOrMobile();

  const [urlState, setUrlState] = useState(initialRouteState);
  const [openIndex, setOpenIndex] = useState(-1);

  const flexContainer = css({
    background: theme.cannonColors.primaryBlue,
    width: '100%',
    opacity: 0.92,
    backdropFilter: 'blur(20px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 'auto',
    padding: '24px 0px 24px 0px',
  });

  useEffect(() => {
    let copy: Array<IRoute> = [...urlState];
    const safesIndex = copy.findIndex((item) => item.key === 'safes');

    if (safesIndex !== -1) {
      copy[safesIndex].subitems[1].subitems =
        data.allStrapiSafeCategory.edges.map((li) => ({
          title: li.node.title,
          key: li.node.id,
          href: `/safes/?type=${li.node.title}`,
        }));
      copy[safesIndex].subitems[2].subitems =
        data.allStrapiLifeStyleVariation.edges.map((li) => ({
          title: li.node.title,
          key: li.node.id,
          href: `/safes/?bestFor=${li.node.title}`,
        }));
    }
    const copyFiltered = copy.filter((li) => li.title !== 'Our product lines');
    setUrlState(copyFiltered);
  }, [data]);

  useEffect(() => {
    if (!inside) {
      setOpenIndex(-1);
    }
  }, [inside]);

  const onHoverButton = (index: number) => {
    setOpenIndex(index);
  };

  const buttonShow = (title) => {
    return <MenuButton>{title}</MenuButton>;
  };

  return (
    <>
      <ButtonGroup overrides={{ Root: { style: { height: '100%' } } }}>
        {urlState.map((route, index) => (
          <div onMouseEnter={() => onHoverButton(index)}>
            <UnderlinedButton
              onClick={() =>
                route.subitems?.length
                  ? setOpenIndex(index)
                  : (window.location.href = route.href)
              }
              isTablet={isTablet}
              endEnhancer={route.subitems?.length ? <ArrowDown /> : null}
            >
              {route.title}
            </UnderlinedButton>
            <ContentContainer socialHiden={hidden}>
              {openIndex === index && route.subitems?.length && (
                <div className={flexContainer}>
                  {route.subitems?.map((item) => (
                    <ContentColumn key={item.key}>
                      <TWH5
                        $style={{ textTransform: 'uppercase', margin: '0px' }}
                        margin={0}
                        align='left'
                        color={theme.cannonColors.primaryWhite}
                        weight='bold'
                      >
                        {item.title}
                      </TWH5>
                      {item.title === 'by type' && (
                        <TWButtonMini
                          onClick={() => {
                            window.location.href = '/safes/';
                          }}
                          margin={5}
                          align='left'
                          color={theme.cannonColors.primaryWhite}
                          weight={400}
                          style={{ cursor: 'pointer' }}
                        >
                          All Safes
                        </TWButtonMini>
                      )}
                      {item.subitems.map((subitem) => (
                        <TWButtonMini
                          onClick={() => {
                            window.location.href = subitem.href.replace(
                              /\s/g,
                              '+'
                            );
                          }}
                          margin={5}
                          align='left'
                          color={theme.cannonColors.primaryWhite}
                          weight={400}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          {subitem.title === 'Our product lines'
                            ? buttonShow(subitem.title)
                            : subitem.title}
                        </TWButtonMini>
                      ))}
                    </ContentColumn>
                  ))}
                </div>
              )}
            </ContentContainer>
          </div>
        ))}
      </ButtonGroup>
    </>
  );
};
