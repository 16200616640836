import React, { useCallback, useMemo } from 'react';
import { useQueryObjState } from '../../../hooks/useQueryState';
import { FindYourSafeContext, findYourSafeInitialContext } from './context';

interface IContextProvidersProps {
  locationSearch: string;
  children: any;
}

const ContextProviders: React.FC<IContextProvidersProps> = (
  props: IContextProvidersProps
) => {
  const [yourSafeContext, setYourSafeContext, clearParams] = useQueryObjState(
    props.locationSearch,
    findYourSafeInitialContext
  );

  const toggleOverlay = useCallback(() => {
    setYourSafeContext('openedOverlay', !yourSafeContext.openedOverlay);
  }, [yourSafeContext.openedOverlay]);

  const findYourSafeProvider = useMemo(
    () => ({
      ...yourSafeContext,
      toggleOverlay,
      setYourSafeContext,
      clearParams,
    }),
    [yourSafeContext, toggleOverlay, setYourSafeContext, clearParams]
  );

  return (
    <FindYourSafeContext.Provider value={findYourSafeProvider}>
      {props.children}
    </FindYourSafeContext.Provider>
  );
};

export default ContextProviders;
