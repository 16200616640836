import React, { useEffect, useRef, useState } from 'react';
import { useTableOrMobile } from '../../hooks';
import { ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import {
  cannonStyled,
  cannonWithStyle,
  useCannonStyletron,
  CannonSafeThemeT,
} from '../../theme/CannonSafesTheme';
import { ThemeProvider, createTheme, lightThemePrimitives } from 'baseui';
import {
  Textarea,
  TextareaProps,
  StyledTextarea,
  StyledTextareaContainer,
} from 'baseui/textarea';

/** ************ USAGE *****************
 *
<TextAreaInput
  noMarginLeft 
  rows={number}
  type='file' //if not declared is just a text input of one line
  errorMessage={firstName === '' && submitClicked} //props
  label='Email'
  placeholder='Enter an email that you constanly check'
  value={email} //props
  onChange={(e: any) =>
    handleChange(e.currentTarget.value, 'email')
  } //props
  required //if not declared * on label will disappear
/>
 *Note* It can receive all properties of a single input from base-web (https://baseweb.design/components/textarea/)
 */

const TextAreaStyle = cannonWithStyle(StyledTextarea, (props: any) => {
  return {
    fontFamily: 'Titillium Web',
    fontWeight: '400',
    fontSize: '18px',
    width: '100%',
    flex: 1,
    background: 'white !important',
  };
});

const MainContainer = cannonStyled(
  'div',
  (props: { noMarginLeft: boolean }) => ({
    width: '100%',
    marginLeft: props.noMarginLeft ? 0 : '10px',
    marginTop: '10px',
    alignSelf: 'baseline',
  })
);

const Asterisk = cannonStyled(
  'span',
  (props: { $theme: CannonSafeThemeT }) => ({
    color: props.$theme.cannonColors.secondaryOrange,
  })
);

// INTERFACE PROPS
interface IInput extends TextareaProps {
  errorMessage?: string;
  label?: string;
  value?: any;
  noMarginLeft?: boolean;
  requiredStyle?: boolean;
}

const TextAreaInput = ({
  label = 'Label',
  error,
  onChange = () => {},
  errorMessage = 'Error',
  value = '',
  noMarginLeft = false,
  requiredStyle = false,
  ...other
}: IInput) => {
  // STATE
  const [inputValue, setInputValue] = useState(value);

  // HOOKS
  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const inputRef: any = useRef();

  // EFFECTS

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // HANDLERS & FUNCTIONS
  function handleChange(e: any) {
    const { value: _value = '' } = e.currentTarget;
    setInputValue(_value);
    onChange(e);
  }

  const labelStyle = css({
    paddingTop: '8px',
    paddingBottom: '8px',
    fontFamily: 'Titillium Web !important',
    fontSize: '16px',
    fontWeight: 'normal',
    textAlign: 'left',
  });
  const errorMsgStyle = css({
    paddingTop: '8px',
    paddingBottom: '8px',
    fontWeight: 'bold',
    fontFamily: 'Titillium Web !important',
    fontSize: '16px',
    textAlign: 'left',
  });

  // EFFECTS
  // RENDER
  return (
    <MainContainer noMarginLeft={noMarginLeft}>
      <ParagraphSmall
        color={theme.cannonColors.secondarySilverDark}
        className={labelStyle}
        margin={0}
      >
        {requiredStyle && (
          <div>
            {label} <Asterisk>*</Asterisk>
          </div>
        )}
        {!requiredStyle && <div>{label}</div>}
      </ParagraphSmall>
      <ThemeProvider
        theme={createTheme(lightThemePrimitives, {
          colors: {
            inputBorder: theme.cannonColors.secondarySilver,
            inputBorderError: theme.cannonColors.secondaryCarmineLight,
            inputBorderPositive: theme.cannonColors.primaryGreen,
            inputFill: theme.cannonColors.primaryWhiteLight,
          },
          borders: {
            inputBorderRadius: '4px'
          }
        })}
      >
        <Textarea
          inputRef={inputRef}
          error={error}
          value={inputValue}
          onChange={(e) => {
            handleChange(e);
          }}
          overrides={{
            Input: {
              component: TextAreaStyle,
            },
          }}
          {...other}
        />
      </ThemeProvider>
      {error && (
        <ParagraphSmall
          color={theme.cannonColors.secondaryCarmineLight}
          className={errorMsgStyle}
          margin={0}
        >
          {errorMessage}
        </ParagraphSmall>
      )}
    </MainContainer>
  );
};

export default TextAreaInput;