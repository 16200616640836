import React from 'react';
import { Button, ButtonProps, SIZE } from 'baseui/button';
import { TWButtonDefault, TWButtonMini, useCannonStyletron } from '../../theme';
import { useTableOrMobile } from '../../hooks';

type MainColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'clear'
  | 'none'
  | 'boneClear'
  | 'greenLight';

interface RoundedButtonProps extends ButtonProps {
  color: MainColor;
  clean?: boolean;
  margin?: number;
  marginBottom?: number;
  fontSize?: string;
  smallBtn?: boolean;
  fullWidth?: boolean;
  isMobile?: boolean;
  padding?: string;
  noBorderLeft?: boolean;
  customHeight?: string;
  noMargin?: boolean;
  isTablet?: boolean;
}

const RoundedButton = (props: RoundedButtonProps) => {
  const [css, theme] = useCannonStyletron();
  const { padding = '12px 14px' } = props;
  return (
    <Button
      {...props}
      overrides={{
        Root: {
          style: {
            background: 'red',
          },
        },
        BaseButton: {
          style: ({ $theme: theme }) => {
            const backgroundColor = {
              primary: theme.cannonColors.primaryBlue,
              secondary: theme.cannonColors.secondaryOrange,
              tertiary: theme.cannonColors.primaryBone,
              clear: theme.cannonColors.primaryWhite,
              boneClear: theme.cannonColors.primaryBoneClear,
              greenLight: theme.cannonColors.primaryGreenLight,
              none: 'transparent',
            };

            const color = {
              primary: theme.cannonColors.primaryWhite,
              greenLight: theme.cannonColors.primaryWhite,
              secondary: theme.cannonColors.primaryWhite,
              tertiary: theme.cannonColors.primaryGray,
              clear: theme.cannonColors.primaryGray,
              none: theme.cannonColors.primaryWhite,
            };

            const hoverBackgroundColor = {
              primary: theme.cannonColors.primaryBlueDark,
              secondary: theme.cannonColors.secondaryOrangeDark,
              tertiary: theme.cannonColors.primaryBone,
              clear: theme.cannonColors.primaryWhite,
              boneClear: theme.cannonColors.primaryBone,
              greenLight: theme.cannonColors.primaryGreenDark,
              none: 'transparent',
            };

            const height = {
              [SIZE.compact]: '42px !important',
              [SIZE.default]: '',
              [SIZE.large]: '',
              [SIZE.mini]: '32px !important',
            };

            return {
              ...{
                borderBottomLeftRadius: props.noBorderLeft ? 0 : '4px',
                borderBottomRightRadius: '4px',
                borderTopLeftRadius: props.noBorderLeft ? 0 : '4px',
                borderTopRightRadius: '4px',
                backgroundColor: backgroundColor[props.color],
                color: color[props.color],
                ':hover': {
                  backgroundColor: hoverBackgroundColor[props.color],
                },
                ':disabled': {
                  background: hoverBackgroundColor[props.color],
                  opacity: 0.4,
                  boxShadow: 'none',
                },
                // padding: padding, /* This propertie was causing an issue with safari 11 to 14 */
                marginTop: props.noMargin
                  ? 'none'
                  : props.margin
                  ? `${props.margin}px !important`
                  : 'auto',
                marginBottom: props.noMargin
                  ? 'none'
                  : props.margin
                  ? `${props.marginBottom}px !important`
                  : 'auto',
                height: height[props.size] && props.customHeight,
                boxShadow: props.clean
                  ? 'none !important'
                  : props.color !== 'clear'
                  ? `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`
                  : 'none !important',
                width: props.fullWidth ? '100% !important' : 'auto',
              },
              ...props.$style,
            };
          },
        },
      }}
    >
      {props.size === SIZE.mini && (
        <TWButtonMini
          color={
            props.color === 'clear' || props.color === 'tertiary'
              ? theme.cannonColors.primaryBlue
              : 'inherit'
          }
          weight={400}
          align='center'
        >
          {props.children}
        </TWButtonMini>
      )}

      {props.size === SIZE.compact && (
        <TWButtonDefault
          color={
            props.color === 'clear' || props.color === 'tertiary'
              ? theme.cannonColors.primaryBlue
              : 'inherit'
          }
          weight={600}
          align='center'
          isMobile={props.isMobile}
          isTablet={props.isTablet}
        >
          {props.children}
        </TWButtonDefault>
      )}

      {props.size === SIZE.default && (
        <TWButtonDefault
          color={
            props.color === 'clear' || props.color === 'tertiary'
              ? theme.cannonColors.primaryBlue
              : 'inherit'
          }
          weight={600}
          align='center'
          isMobile={props.isMobile}
          isTablet={props.isTablet}
          style={{ fontSize: '14px' }}
        >
          {props.children}
        </TWButtonDefault>
      )}
    </Button>
  );
};

export default RoundedButton;
