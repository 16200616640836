import React from 'react';

export default (text: string) => {
  const evalText = text.split('\n');

  return evalText.map((str: string) => (
    <>
      {str}
      <br />
    </>
  ));
};
