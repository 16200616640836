import React from 'react';
import { cannonStyled, useCannonStyletron } from '../../theme';
import { ResponsiveContainer, CenteredContainer } from '../Container';
import { Paragraph4 } from 'baseui/typography';
import { IThemeProp } from '../Cards/CapabilityCard';
import { useAPIURL, useTableOrMobile } from '../../hooks';
import { SmallSpaceMobile } from '../Container/SmallSpaceMobile';
import { useFooterQ } from './querys/queryFooterLogos';

interface IContainer extends IThemeProp {
  isMobile: boolean;
}
interface IFlexContainer extends IThemeProp {
  main?: boolean;
  isMobile: boolean;
}
interface ImgSize extends IThemeProp {
  isMobile: boolean;
  isTablet: boolean;
  src: string;
}

const ImgLogo = cannonStyled('img', (props: ImgSize) => ({
  width: props.isMobile ? '75px' : props.isTablet ? '80px' : '119px',
  height: props.isMobile ? '45px' : props.isTablet ? '30px' : '50px',
  opacity: '0.5',
  cursor: 'pointer',
}));

const Container = cannonStyled(CenteredContainer, (props: IContainer) => ({
  height: 'auto',
  backgroundColor: props.$theme.cannonColors.primaryBlue,
  flexDirection: 'row',
  paddingTop: props.isMobile ? '15px' : '30px',
  paddingBottom: props.isMobile ? '15px' : '30px',
}));

const FlexContainer = cannonStyled('div', (props: IFlexContainer) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: props.isMobile ? 'normal' : 'space-between',
  alignItems: 'center',
  width: props.main ? '100%' : 'auto',
  paddingLeft: props.isMobile ? '5px' : '15px',
  paddingRight: props.isMobile ? '7px' : '15px',
}));

export default ({ isTabletOrMobile = false }) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();
  const data = useFooterQ();
  //Validation for local links

  const redirectFunc = (value) => {
    if (value.includes('.com')) {
      value = window.open(`${value}`, '_blank');
    } else {
      value = window.location.href = `${value}`;
    }
  };

  return (
    <Container isMobile={isMobile}>
      <ResponsiveContainer>
        <FlexContainer
          $style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          main
          isMobile={isMobile}
        >
          {!isMobile && (
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              <ImgLogo
                isMobile={isMobile}
                isTablet={isTablet}
                src={API_URL + data.strapiFooterLogos.firstLogo.url}
                alt={data.strapiFooterLogos.firstLogo.alternativeText}
                onClick={() =>
                  redirectFunc(data.strapiFooterLogos.firstLogoHref)
                }
              />
              <Paragraph4
                color={theme.cannonColors.primaryWhiteDark}
                className={css({
                  paddingLeft: isMobile ? '7px' : '20px',
                  fontFamily: 'Titillium Web !important',
                  fontSize: isMobile ? theme.sizing.scale200 : 'normal',
                  lineHeight: isMobile ? 1.4 : '2.4',
                  verticalAlign: 'middle',
                })}
              >
                {data.strapiFooterLogos.title}
              </Paragraph4>
            </div>
          )}
          <FlexContainer isMobile={isMobile} aria-label={'Logo images'}>
            {data.strapiFooterLogos.linksLogos.map((item, id, arr) => {
              if (arr.length - 1 === id)
                return (
                  <>
                    <ImgLogo
                      src={API_URL + item.logo.url}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      onClick={() => redirectFunc(item.href)}
                      alt='logo bottom image'
                    />
                  </>
                );
              return (
                <>
                  <ImgLogo
                    src={API_URL + item.logo.url}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    onClick={() => redirectFunc(item.href)}
                    alt='logo bottom image'
                  />
                  <SmallSpaceMobile
                    direction='horizontal'
                    isMobile={isMobile}
                  />
                </>
              );
            })}
          </FlexContainer>
        </FlexContainer>
      </ResponsiveContainer>
    </Container>
  );
};
