import { H3, Paragraph1, Paragraph2 } from 'baseui/typography';
import React from 'react';
import { useTableOrMobile } from '../../hooks';
import { SpecificationItem } from '../../pages';
import { cannonStyled, useCannonStyletron } from '../../theme';
import { IThemeProp } from './CapabilityCard';

interface IDetailFeatureCard extends IThemeProp, IMobile {
  reversed?: boolean;
  imageSrc: any;
  items: SpecificationItem[];
  title: string;
}

interface IMobile extends IThemeProp {
  isMobile?: boolean;
}

const Container = cannonStyled('div', (props: IDetailFeatureCard) => ({
  display: 'flex',
  flexDirection: props.isMobile
    ? 'column'
    : props.reversed
    ? 'row-reverse'
    : 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '40px',
}));

const ImageContainer = cannonStyled('div', (props: IMobile) => ({
  height: '100%',
  width: props.isMobile ? '100%' : '45%',
  objectFit: 'cover',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const Image = cannonStyled('img', (props: IMobile) => ({
  width: '100%',
}));

const Content = cannonStyled('div', (props: IMobile) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: props.isMobile ? '100%' : '45%',
}));

const CenteredContainer = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '40px',
  width: '100%',
});

const Title = cannonStyled('span', {
  fontFamily: 'Titillium Web !important',
  fontSize: '16px !important',
  width: '100%',
  fontWeight: 700,
});

const Description = cannonStyled('span', {
  fontFamily: 'Titillium Web !important',
  fontSize: '16px !important',
  width: '100%',
  fontWeight: 'normal',
});

export default (props: IDetailFeatureCard) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();

  const bold = css({
    fontFamily: 'Noto Serif !important',
    fontize: '36px !important',
    fontWeight: 900,
    textAlign: 'left',
  });

  return (
    <Container {...props} isMobile={isMobile}>
      <ImageContainer isMobile={isMobile}>
        <Image src={props.imageSrc} alt={props.title} />
      </ImageContainer>
      <Content isMobile={isMobile}>
        <H3 className={bold} color={theme.cannonColors.primaryBlue}>
          {props.title}
        </H3>
        {props.items.map((item) => (
          <CenteredContainer key={item.name}>
            <Title color={theme.cannonColors.primaryBlue}>{item.name}</Title>
            <Description color={theme.cannonColors.primaryGray}>
              {item.value}
            </Description>
          </CenteredContainer>
        ))}
      </Content>
    </Container>
  );
};
