import { ListItem, ListItemLabel } from 'baseui/list';
import React, { useState } from 'react';
import { TWParagraph3, useCannonStyletron } from '../../theme';
import { Option } from './NavigationMenuOption';
import ListDecorator from '../../assets/list-decorator.svg';
import { Button } from '..';
import { ChevronDown, ChevronRight } from 'baseui/icon';

interface Props {
  option: any;
  index: number;
}

export const NavigationSubItemOption = ({
  option = {} as Option,
  index,
}: Props) => {
  // HOOKS

  const [css, theme] = useCannonStyletron();

  // STATE
  const [subItemsOpened, setSubItemsOpened] = useState(false);

  // HANDLERS & FUNCTIONS

  function handleOptionClicked(url: string) {
    if (url !== null) window.location.href = url;
    else alert('Page coming soon');
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation;
        handleOptionClicked(option.href);
      }}
    >
      <ListItem
        overrides={{
          Root: { style: { background: `${theme.cannonColors.primaryBlue} !important` } },
        }}
        sublist
      >
        <ListItemLabel
          overrides={{
            LabelContent: {
              style: {
                color: theme.cannonColors.primaryWhite,
                display: 'flex',
                alignItems: 'center',
                padding: '15px 0px 15px 15px',
              },
            },
          }}
        >
          <ListDecorator />
          <TWParagraph3
            $style={{ fontSize: '19px', paddingLeft: '10px' }}
            color={theme.cannonColors.primaryWhite}
            align='left'
            weight={400}
          >
            {option.title}
          </TWParagraph3>
        </ListItemLabel>
      </ListItem>
    </div>
  );
};

export default NavigationSubItemOption;
