import React from 'react';
import { useTableOrMobile } from '../../../hooks';
import { cannonStyled } from '../../../theme';
import { CannonSafeThemeT } from '../../../theme/CannonSafesTheme';
import { IThemeProp } from '../../Cards/CapabilityCard';

interface IStyle extends IThemeProp {
  isMobile: boolean;
  isRight: boolean;
  color: string;
}

const StyledBorder = cannonStyled('div', (props: IStyle) => ({
  display: 'inline',
  margin: props.isMobile ? '2px' : 'inherit',
  paddingRight: props.isMobile ? '3px' : '12px',
  paddingLeft: props.isMobile ? '3px' : '12px',
  lineHeight: props.isMobile ? '1px' : 'inherit',
  verticalAlign: props.isMobile ? 'middle' : 'auto',
  fontFamily: 'Titillium Web',
  fontWeight: 400,
  fontSize: props.isMobile ? '7px' : '16px',
  borderRight: props.isRight
    ? 'none'
    : props.isMobile
    ? '1px solid'
    : '2px solid',
  color: props.color,
}));

export default StyledBorder;
