import React from 'react';
import { cannonStyled } from '../../theme';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../theme/CannonSafesTheme';
import { StarRatingFn } from '..';
import { SmallSpace } from '../Space';
import { OutlinedButton } from '../Button';
import { useTableOrMobile } from '../../hooks';
import { AspectRatioBox } from 'baseui/aspect-ratio-box';
import { NSH5, TWParagraph3 } from '../../theme/Typography';
import { Review } from '../../pages';
import { IThemeProp } from './CapabilityCard';
import { useOrientation } from '../../hooks/useOrientation';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import ArrowRight from '../../assets/arrow-right.svg';
import { useMediaQuery } from 'react-responsive';

type ICardContainer = {
  isMobile?: boolean;
  isTablet?: boolean;
  isRotated?: boolean;
  height?: string;
  $theme?: CannonSafeThemeT;
};

export const CardContainer = cannonStyled(
  AspectRatioBox,
  (props: ICardContainer & { isSmallWidth: boolean }) => ({
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    margin: props.$theme.sizing.scale300,
    padding:
      props.isMobile && !props.isRotated
        ? 'unset'
        : props.$theme.sizing.scale600,
    position: 'relative',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    height: props.height,
    overflowX: 'clip',
    width: props.isSmallWidth ? '93%' : props.isMobile ? '95%' : '85%',
  })
);

const PopularBadge = cannonStyled('div', (props: IThemeProp) => ({
  position: 'absolute',
  top: '16px',
  right: '16px',
  backgroundColor: props.$theme.cannonColors.secondaryOrange,
  padding: '5px 12px',
  borderRadius: '41px',
}));

export const BestSellerImage = cannonStyled('img', () => ({
  maxHeight: '50%',
  alignSelf: 'flex-start',
  marginBottom: '5px',
}));

export const BestSellerGridContainer = cannonStyled(
  'div',
  (props: ICardContainer) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: props.isMobile ? 'space-evenly' : 'space-between',
    alignItems: 'stretch',
    padding: props.isMobile ? 'unset' : props.$theme.sizing.scale600,
  })
);

export const BestSellerContent = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-end',
  paddingTop: '5px',
  paddingBottom: '5px',
});

export const HorizontalFlex = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '18px',
});

const DetailsButtonContainer = cannonStyled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  marginTop: '16px',
});

const DetailsButton = cannonStyled(OutlinedButton, {
  marginBottom: 0,
  padding: 'auto 0',
  bottom: 0,
});

const MobileDesignContainer = cannonStyled('div', () => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  background: 'rgb(244, 246, 251)',
  height: '60%',
  zIndex: '-1',
  borderRadius: '8px',
}));

interface ISafeCardProps {
  displayImage: string;
  title: string;
  showSubtitle?: boolean;
  subTitle: string;
  showDescription?: boolean;
  price?: number;
  showPrice?: boolean;
  description: string;
  slug: string;
  popular?: boolean;
  reviews?: Array<Review>;
  shortButton?: boolean;
  showCaption?: boolean;
  caption?: string;
  applyMobileDesign?: boolean;
  action: () => {};
}

const Card: React.FC<ISafeCardProps> = ({
  displayImage,
  title,
  showSubtitle = false,
  subTitle,
  showDescription = false,
  price,
  showPrice = false,
  description,
  action,
  slug,
  reviews = [],
  shortButton = false,
  popular,
  showCaption = false,
  caption = '',
  applyMobileDesign = false,
}) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const { isRotated } = useOrientation();
  const { height } = useWindowDimensions();

  const headerStyle = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'flex-start',
    textTransform: 'uppercase',
  });

  const isSmallWidth = useMediaQuery({ query: '(max-width: 400px)' });

  const reviewValue = reviews.length
    ? (
        reviews.reduce((sum, review) => sum + review.value, 0) / reviews.length
      ).toFixed(1)
    : '5';

  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <CardContainer
      isMobile={isMobile}
      isTablet={isTablet}
      isRotated={isRotated}
      height={isMobile && isRotated ? '75vh' : height < 700 ? '66vh' : '44vh'}
      aspectRatio={302 / 533}
      isSmallWidth={isSmallWidth}
      onClick={action}
    >
      <BestSellerGridContainer isMobile={isMobile}>
        <BestSellerImage src={displayImage} alt={displayImage} />

        {popular && (
          <PopularBadge>
            <TWParagraph3
              $style={{ fontSize: '15px' }}
              color='white'
              margin={0}
              align='center'
              weight={400}
              aria-label='Popular badge'
            >
              Popular
            </TWParagraph3>
          </PopularBadge>
        )}
        {isMobile && applyMobileDesign ? <MobileDesignContainer /> : <></>}
        <BestSellerContent>
          <HorizontalFlex>
            <StarRatingFn ratingValue={parseFloat(reviewValue)} />
            <SmallSpace direction='horizontal' />
            <TWParagraph3
              aria-label='review value'
              align='left'
              color={theme.cannonColors.secondarySilver}
              weight={400}
            >
              {reviewValue} / 5
            </TWParagraph3>
          </HorizontalFlex>

          {showSubtitle && (
            <TWParagraph3
              aria-label={subTitle}
              weight={400}
              align='left'
              margin={0}
              color={theme.cannonColors.secondarySilverDark}
              $style={{ marginTop: '6px', marginBottom: '6px' }}
            >
              {subTitle}
            </TWParagraph3>
          )}

          <NSH5
            className={headerStyle}
            weight={900}
            margin={2}
            color={theme.cannonColors.primaryBlue}
            $style={{ fontSize: 'min(5.2vw, 19px)!important' }}
            align='left'
            aria-label={title}
          >
            {title}
          </NSH5>

          {showCaption && (
            <TWParagraph3
              weight={400}
              align='left'
              margin={0}
              color='#111111'
              aria-label={caption ? caption : 'caption text empty'}
            >
              {caption}
            </TWParagraph3>
          )}

          {/* {showPrice && (
            <TWParagraph3
              aria-label='Price'
              weight={400}
              align='left'
              margin={0}
              color={theme.cannonColors.secondarySilverDark}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  color: theme.cannonColors.primaryGreen,
                }}
              >
                {formatter.format(price)}
              </span>{' '}
              *Average pricing.
            </TWParagraph3>
          )} */}

          {showDescription && (
            <TWParagraph3
              aria-label={description ? description : 'description text empty'}
              weight={500}
              align='left'
              margin={0}
              color={theme.colors.black}
            >
              {description}
            </TWParagraph3>
          )}

          <DetailsButtonContainer>
            <DetailsButton
              clean
              size='mini'
              color='primary'
              href={`/product/${slug}`}
              fullWidth={!shortButton}
              onClick={action}
              $style={
                applyMobileDesign && isMobile
                  ? { borderRadius: '10px', padding: '18px 10px 18px 10px' }
                  : {}
              }
              endEnhancer={
                <ArrowRight
                  style={{ stroke: theme.cannonColors.primaryBlue }}
                />
              }
            >
              View details
            </DetailsButton>
          </DetailsButtonContainer>
        </BestSellerContent>
      </BestSellerGridContainer>
    </CardContainer>
  );
};

export default Card;
