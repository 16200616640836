import { IRoute } from './@types';

export const API_URL = process.env.API_URL;

export const initialRouteState: Array<IRoute> = [
  {
    title: 'Home',
    href: '/',
    key: 'home',
  },
  {
    title: 'Our product lines',
    href: '/product-lines',
    key: 'by-productline',
    subitems: [],
  },
  {
    title: 'Safes',
    href: '/safes',
    key: 'safes',
    subitems: [
      {
        title: 'product lines',
        href: null,
        key: 'by-productline',
        subitems: [
          {
            title: 'Our product lines',
            href: '/product-lines',
            key: 'by-productline',
            subitems: [],
          },
        ],
      },
      {
        title: 'by type',
        href: null,
        key: 'by-type',
        subitems: [],
      },
      {
        title: 'by lifestyle',
        href: null,
        key: 'by-lifestyle',
        subitems: [],
      },
    ],
  },
  // {
  //   title: 'Where to buy',
  //   href: '/where-to-buy',
  //   key: 'where-to',
  // },
  {
    title: 'We are Cannon',
    href: null,
    key: 'we-are',
    subitems: [
      {
        title: 'about us',
        href: null,
        key: 'about',
        subitems: [
          {
            title: 'Our Legacy',
            key: 'legacy',
            href: '/our-legacy',
          },
        ],
      },
      {
        title: 'learn',
        key: 'learn',
        href: null,
        subitems: [
          {
            title: 'Blog',
            key: 'blog',
            href: '/blog',
          },
          //         {
          //           title: 'Cannon Events',
          //           key: 'events',
          //           href: '/events',
          //         },
          //         {
          //           title: 'Safe tips',
          //           key: 'tips',
          //           href: '/tips',
          //         },
        ],
      },
    ],
  },
  {
    title: 'Support',
    key: 'support',
    href: null,
    subitems: [
      {
        title: 'help & support',
        href: null,
        key: 'help',
        subitems: [
          // {
          //   title: 'Safe tips',
          //   key: 'tips',
          //   href: '/tips',
          // },
          {
            title: 'Product manuals',
            key: 'product-manuals',
            href: '/user-manuals',
          },
          {
            title: `FAQ's`,
            key: 'faq',
            href: '/faq',
          },
          {
            title: 'Featured Videos',
            key: 'videos',
            href: '/videos',
          },
        ],
      },
      {
        title: 'owner center',
        href: null,
        key: 'owner-center',
        subitems: [
          {
            title: 'Battery change reminder',
            key: 'reminder',
            href: '/battery-change-reminder',
          },
          // {
          //   title: 'Lost combination assistance',
          //   key: 'combination-assistance',
          //   href: '/lost-combination-assistance',
          // },
          {
            title: 'Keys replacement',
            key: 'keys-replacement',
            href: '/keys-replacement',
          },
          {
            title: 'Register your safe',
            key: 'register-your-safe',
            href: 'https://customersupport.cannonsafe.com/portal/en/newticket',
          },
          {
            title: 'Replacement parts',
            key: 'replacement-parts',
            href: '/replacement-parts',
          },
          {
            title: 'Warranty',
            key: 'warranty',
            href: '/warranty',
          },
        ],
      },
    ],
  },
  {
    title: 'Contact us',
    href: '/contact-us',
    key: 'contact',
  },
];

export const glossary = [
  'i',
  'we',
  'you',
  'he',
  'she',
  'it',
  'they',
  'me',
  'us',
  'her',
  'him',
  'it',
  'them',
  'your',
  'mine',
  'ours',
  'yours',
  'hers',
  'his',
  'theirs',
  'my',
  'our',
  'his',
  'their',
  'myself',
  'yourself',
  'herself',
  'himself',
  'itself',
  'ourselves',
  'yourselves',
  'themselves',
  'myself',
  'yourself',
  'herself',
  'himself',
  'itself',
  'ourselves',
  'yourselves',
  'themselves',
  'all',
  'another',
  'any',
  'anybody',
  'anyone',
  'anything',
  'both',
  'each',
  'either',
  'everybody',
  'everyone',
  'everything',
  'few',
  'many',
  'most',
  'neither',
  'nobody',
  'none',
  'no',
  'nothing',
  'one',
  'other',
  'others',
  'several',
  'some',
  'somebody',
  'someone',
  'something',
  'such',
  'that',
  'these',
  'this',
  'those',
  'what',
  'whatever',
  'which',
  'whichever',
  'who',
  'whoever',
  'whom',
  'whomever',
  'whose',
  'as',
  'thou',
  'thee',
  'thy',
  'thine',
  'in',
  'out',
  'on',
  'a',
  'to',
  'is',
  'if',
  'the',
  'or',
  'do',
  'not',
  'of',
];
