import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  query {
    strapiLatestNews {
      header
    }
    allStrapiBlogEntries {
      nodes {
        title
        previewText
        slug
        previewImage {
          alternativeText
          url
        }
        blogCategory {
          Name
        }
      }
    }
  }
`;

interface ImgArray {
  alternativeText: string;
  url: string;
}

interface PageArray {
  title: string;
  previewText: string;
  slug: string;
  previewImage: Array<ImgArray>;
  blogCategory: {
    Name: string
  }
}

interface IColLasNew {
  strapiLatestNews: {
    header: string;
  };
  allStrapiBlogEntries: {
    nodes: Array<PageArray>;
  };
}

export const useCLN = (): IColLasNew => useStaticQuery(query);
