import React, { useEffect, useRef, useState } from 'react';
import { ICannonSafe } from '../../../pages';
import {
  cannonStyled,
  cannonWithStyle,
  NSH1,
  useCannonStyletron,
} from '../../../theme';
import { ICannonElMobile } from '../../../@types';
import { TopSafesCard } from '../../Cards';
import { ResponsiveContainer } from '../../Container';
import { useAPIURL, useOrientation, useTableOrMobile } from '../../../hooks';
import useFindYourSafe from '../useFindYourSafe';
import LoadingResults from './LoadingResults';
import SafeGallery from './SafeGallery';
import language from '../../../language';
import CloseButton from '../CloseButton';
import GoBackButton from '../GoBackButton';
import SafeDescription from './SafeDescription';
import ScrollUpIcon from '../../../assets/scroll-up.svg';
import ScrollDownIcon from '../../../assets/scroll-down.svg';
import ButtonsWrapper from '../ButtonsWrapper';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const ResultsWrapper = cannonStyled('div', {
  width: '100%',
  marginTop: '70px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Galleries = cannonStyled('div', {
  overflowX: 'hidden',
  overflowY: 'hidden',
  display: 'flex',
  justifyContent: 'space-around',
  minWidth: '70%',
});

const SelectionWrapper = cannonStyled('div', (props: ICannonElMobile) => ({
  height: props.$isMobile ? 'unset' : '80vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
}));

const MoreSafesWrapper = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    overflowX: 'clip',
    marginBottom: '10vh',
    marginTop: '10vh',
    display: 'grid',
    gridTemplateColumns: isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr',
  })
);

const MoreSafesButtonWrapper = cannonStyled('div', {
  position: 'fixed',
  bottom: '10px',
  width: 'fit-content',
  marginRight: 0,
  marginLeft: 0,
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
  fontFamily: 'Titillium Web',
  zIndex: 999,
});

const getMeasures = (safe: ICannonSafe): String => {
  const measures = safe.intDimensions; // could also use extDimensions if prefered
  const width = measures.width;
  const depth = measures.depth;
  const height = measures.height;
  return `${Math.trunc(width)} x 
          ${Math.trunc(depth)} x 
          ${Math.trunc(height)} in`;
};

const getSafeGalleryImgs = (safe: ICannonSafe): Array<string> => {
  const imgs = [
    safe.displayImage?.formats?.medium?.url || safe.displayImage.url,
    ...safe.detailImages
      .filter(
        (e) =>
          e.image &&
          e.image.url &&
          (e.image.url.includes('png') ||
            e.image.url.includes('jpg') ||
            e.image.url.includes('jpeg'))
      )
      .map((img) => img.image.formats?.medium?.url || img.image.url),
  ];
  return imgs;
};

interface IFindYourSafeResultsProps {
  safeResults: Array<ICannonSafe>;
  moreSafes: Array<ICannonSafe>;
}

const FindYourSafeResults: React.FC<IFindYourSafeResultsProps> = ({
  safeResults,
  moreSafes,
}) => {
  const [loading, setLoading] = useState(true);
  const [seeMoreSafes, setSeeMoreSafes] = useState(false);
  const [selectedSafe, setSelectedSafe] = useState(0);
  const [css, theme] = useCannonStyletron();
  const API_URL_HOOK = useAPIURL();
  const { setYourSafeContext } = useFindYourSafe();
  // const [isMobile] = useTableOrMobile();
  const { width } = useWindowDimensions();
  const isMobile = width <= 950;
  const resultsRef = useRef(null);
  const moreSafesRef = useRef(null);

  useEffect(() => {
    setTimeout(() => setLoading(false), 4000);
  }, []);

  if (loading) return <LoadingResults />;

  const handleBack = () => {
    setYourSafeContext('searchResults', false);
  };

  const handleScroll = () => {
    setSeeMoreSafes((seeMoreSafes) => {
      if (seeMoreSafes) {
        resultsRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      } else {
        moreSafesRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      return !seeMoreSafes;
    });
  };

  return (
    <ResultsWrapper className='find-your-safe-results-wrapper'>
      <ButtonsWrapper>
        <GoBackButton
          fixed={isMobile}
          text='Change responses'
          handleBack={handleBack}
        />
        <CloseButton fixed={isMobile} refProp={resultsRef} />
      </ButtonsWrapper>
      <NSH1
        align='center'
        color={theme.cannonColors.primaryWhite}
        weight={900}
        $style={{ marginBottom: '50px' }}
      >
        {language('en', 'selectionForYou')}
      </NSH1>

      <SelectionWrapper $isMobile={isMobile}>
        <Galleries>
          {safeResults.map((safe, idx) => (
            <SafeGallery
              key={safe.id}
              safeName={safe.displayName}
              imgSrcs={getSafeGalleryImgs(safe)}
              isSelected={selectedSafe === idx}
              handleSelect={() => setSelectedSafe(idx)}
            />
          ))}
        </Galleries>
        {!isMobile && (
          <SafeDescription
            safeResults={safeResults}
            selectedSafe={selectedSafe}
          />
        )}
      </SelectionWrapper>
      {isMobile && (
        <SafeDescription
          safeResults={safeResults}
          selectedSafe={selectedSafe}
        />
      )}
      {!isMobile && (
        <MoreSafesButtonWrapper onClick={handleScroll}>
          {seeMoreSafes ? 'See results' : 'See more Safes'}
          {seeMoreSafes ? <ScrollUpIcon /> : <ScrollDownIcon />}
        </MoreSafesButtonWrapper>
      )}

      <MoreSafesWrapper isMobile={isMobile} ref={moreSafesRef}>
        {moreSafes.map((safe) => (
          <TopSafesCard
            isFindYourSafe
            safeCategory={safe.safeCategory.title}
            key={safe.id}
            style='white'
            displayImage={API_URL_HOOK + safe.displayImage.url}
            title={safe.displayName}
            series={safe.series.title}
            measures={getMeasures(safe)}
            features={{
              firearmCapacity: safe.firearmCapacity,
              waterproofText: safe.features.waterproofText,
              convertibleInterior: safe.features.convertibleInteriorText,
            }}
            action={() => (window.location.href = `/product/${safe.slug}`)}
          />
        ))}
      </MoreSafesWrapper>
    </ResultsWrapper>
  );
};

export default FindYourSafeResults;
