import React, { useEffect, useRef, useState } from 'react';
import { Input, InputProps, StyledRoot, StyledInput } from 'baseui/input';
import { useTableOrMobile } from '../../hooks';
import { ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import {
  cannonStyled,
  cannonWithStyle,
  useCannonStyletron,
  CannonSafeThemeT,
} from '../../theme/CannonSafesTheme';
import FileUpload from '../../assets/file-upload.svg';
import CheckIcon from '../../assets/check-icon.svg';

import './input.css';
import InfoTool from '../Button/InfoTool';

/* ************ USAGE *****************
 *
<CustomInput
  noMarginLeft 
  type='file' //if not declared is just a text input of one line
  errorMessage={firstName === '' && submitClicked} //props
  label='Email'
  placeholder='Enter an email that you constanly check'
  value={email} //props
  onChange={(e: any) =>
    handleChange(e.currentTarget.value, 'email')
  } //props
  required //if not declared * on label will disappear
/>
 *Note* It can receive all properties of a single input from base-web (https://baseweb.design/components/input)
 */

// STYLED COMPONENTS

const InputWithStyle = cannonWithStyle(StyledInput, (props: any) => {
  const { $disabled, $isFocused, $positive, $theme, $error } = props;
  const stateColor = $disabled
    ? $theme.cannonColors.secondarySilver
    : $error
    ? $theme.cannonColors.secondaryCarmineLight
    : $isFocused
    ? $theme.cannonColors.primaryBlue
    : $positive
    ? $theme.cannonColors.primaryGreen
    : $theme.cannonColors.primaryBlue;
  return {
    color: `${stateColor} !important`,
    fontFamily: 'Titillium Web',
    fontWeight: '400',
    fontSize: '18px',
    width: '100%',
    flex: 1,
    background: 'white !important',
  };
});

const RootWithStyle = cannonWithStyle(StyledRoot, (props: any) => {
  const { $disabled, $isFocused, $positive, $theme, $error } = props;
  const stateColor = $disabled
    ? $theme.cannonColors.secondarySilver
    : $error
    ? $theme.cannonColors.secondaryCarmineLight
    : $isFocused
    ? $theme.cannonColors.primaryBlue
    : $positive
    ? $theme.cannonColors.primaryGreen
    : $theme.cannonColors.secondarySilver;
  return {
    borderLeftColor: stateColor,
    borderRightColor: stateColor,
    borderTopColor: stateColor,
    borderBottomColor: stateColor,
    borderRadius: '4px',
    width: '100%',
    flex: 1,
    background: 'white !important',
  };
});

const Asterisk = cannonStyled(
  'span',
  (props: { $theme: CannonSafeThemeT }) => ({
    color: props.$theme.cannonColors.secondaryOrange,
  })
);

const MainContainer = cannonStyled(
  'div',
  (props: { noMarginLeft: boolean }) => ({
    width: '100%',
    marginLeft: props.noMarginLeft ? 0 : '10px',
    marginTop: '10px',
    alignSelf: 'baseline',
    background: 'white',
  })
);

// INTERFACE PROPS
interface IInput extends InputProps {
  errorMessage?: string;
  label?: string;
  value?: any;
  noMarginLeft?: boolean;
  // showTooltip?: boolean;
  requiredStyle?: boolean;
  bottomLabel?: string;
  noLabel?: boolean;
}

// COMPONENT
const CustomInput = ({
  // showTooltip = false,
  label = 'Label',
  error,
  onChange = () => {},
  errorMessage = 'Error',
  type,
  value = '',
  noMarginLeft = false,
  requiredStyle = false,
  bottomLabel = '',
  noLabel = true,
  ...other
}: IInput) => {
  // STATE
  const [inputValue, setInputValue] = useState(value);
  const [inputFileValue, setInputFileValue] = useState('');
  // HOOKS
  const [isMobile] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const inputRef: any = useRef();

  //EFFECTS
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // HANDLERS & FUNCTIONS
  function handleChange(e: any) {
    const { value: _value = '' } = e.currentTarget;
    setInputValue(_value);
    onChange(e);
  }

  const labelStyle = css({
    paddingTop: '8px',
    paddingBottom: '8px',
    fontFamily: 'Titillium Web !important',
    fontSize: '16px',
    fontWeight: 'normal',
    textAlign: 'left',
  });
  const errorMsgStyle = css({
    paddingTop: '8px',
    paddingBottom: '8px',
    fontWeight: 'bold',
    fontFamily: 'Titillium Web !important',
    fontSize: '16px',
    textAlign: 'left',
  });
  const fileInputContainer = css({
    position: 'relative',
    maxWidth: '100%',
    flex: 1,
    minWidth: '250px',
    height: '160px',
    borderRadius: '4px',
    border: 'dashed 1px black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  });
  const fileInputTextStyle = css({
    fontWeight: 400,
    fontFamily: 'Titillium Web',
    fontSize: '18px',
  });
  const changeFileText = css({
    color: theme.cannonColors.primaryGreen,
    fontWeight: 400,
    fontFamily: 'Titillium Web',
    fontSize: '18px',
    textDecoration: 'underline',
  });

  let showTooltip = label.toLowerCase().includes('serial number') ? true : false
  // EFFECTS
  // RENDER
  return (
    <MainContainer noMarginLeft={noMarginLeft}>
      {noLabel && (
        <ParagraphSmall
          color={theme.cannonColors.secondarySilverDark}
          className={labelStyle}
          margin={0}
        >
          {requiredStyle && (
            <div id={label}>
              {label} <Asterisk>*</Asterisk>
            </div>
          )}
          {!requiredStyle && !showTooltip && <div>{label}</div>}
          {!requiredStyle && showTooltip && <div>{label}<InfoTool /></div>}
          
        </ParagraphSmall>
      )}

      <div
        className={type === 'file' && fileInputContainer}
        style={
          error && type === 'file'
            ? { borderColor: theme.cannonColors.secondaryCarmineLight }
            : {}
        }
      >
        {type === 'file' && (
          <div style={{ textAlign: 'center' }}>
            {inputRef.current?.files?.length > 0 ? (
              <CheckIcon />
            ) : (
              <FileUpload />
            )}
          </div>
        )}
        <Input
          aria-labelledby={label}
          type={type}
          inputRef={inputRef}
          error={error}
          value={inputValue}
          onChange={(e) => {
            handleChange(e);
          }}
          overrides={{
            After: {},
            StartEnhancer: {
              style: { background: 'white !important', padding: 0, margin: 0 },
            },
            Root: {
              style: type === 'file' && {
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 0,
                height: '100%',
                width: '100%',
              },
              component: RootWithStyle,
            },
            Input: {
              component: InputWithStyle,
              props: {
                value: inputValue,
              },
            },
            InputContainer: {
              style: { background: 'white' },
            },
          }}
          {...other}
        />
        {type === 'file' && (
          <div
            className={fileInputTextStyle}
            style={
              inputValue !== ''
                ? { color: theme.cannonColors.secondarySilver }
                : { color: theme.cannonColors.primaryBlue }
            }
          >
            {inputValue !== ''
              ? inputRef.current.files[0].name
              : 'Drag and drop or choose a file'}
          </div>
        )}
        {inputRef.current?.files?.length > 0 && (
          <div className={changeFileText}>Change File</div>
        )}
      </div>
      {bottomLabel !== '' && (
        <ParagraphSmall
          color={theme.cannonColors.secondarySilver}
          className={labelStyle}
          margin={0}
        >
          {bottomLabel}
        </ParagraphSmall>
      )}
      {error && (
        <ParagraphSmall
          color={theme.cannonColors.secondaryCarmineLight}
          className={errorMsgStyle}
          margin={0}
        >
          {errorMessage}
        </ParagraphSmall>
      )}
    </MainContainer>
  );
};

export default CustomInput;
