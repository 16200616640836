import * as React from 'react';
import { cannonStyled, useCannonStyletron } from '../../theme';
import ColumnsInfo from './components/ColumnsInfo';
import ColumnsLinkProductLines from './components/ColumnsLinkProductLines';
import ColumnsLinkCannonExperience from './components/ColumnsLinkCannonExperience';
import ColumnsLinkOwnerCenter from './components/ColumnsLinkOwnerCenter';
import { useTableOrMobile } from '../../hooks';
import { ResponsiveContainer } from '../Container';
import ColumnsNews from './components/ColumnsNews';

interface IGridContainerMain {
  isMobile: boolean;
}

const ContainerMain = cannonStyled('div', (props: IGridContainerMain) => ({
  height: 'undefined',
  display: 'grid',
  paddingTop: props.isMobile ? '30px' : '40px',
  paddingLeft: '15px',
  paddingRight: props.isMobile ? '5px' : '15px',
  paddingBottom: '40px',
  gridTemplateColumns: props.isMobile
    ? "'40% 60% / 1fr 1fr / 1fr 1fr '"
    : '1fr '.repeat(5),
  gridTemplateRows: '1fr ',
  columnGap: props.isMobile ? '0px' : '20px',
  gridTemplateAreas: props.isMobile
    ? `
                      'info line'
                      'exp cent'
                      'late late1' 
                    `
    : `'info line exp cent late late1'`,
}));

const MainContainer = cannonStyled(
  'div',
  ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => ({
    display: 'grid',
    gridTemplateColumns: isMobile || isTablet ? '1fr 1fr' : '1fr 1fr 1fr 1fr 1fr',
    padding: isMobile
      ? '30px 20px 30px 20px'
      : isTablet
      ? '30px 100px 30px 100px'
      : '60px',
  })
);

const FooterColumns = () => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  return (
    <div>
      <MainContainer isTablet={isTablet} isMobile={isMobile}>
        <ColumnsInfo />
        <ColumnsLinkProductLines />
        <ColumnsLinkCannonExperience />
        <ColumnsLinkOwnerCenter />
        {/* TO DO */}
        {!isMobile && (<ColumnsNews />)}
      </MainContainer>
    </div>
  );
};

export default FooterColumns;
