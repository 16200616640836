import React from 'react';
import { useCoreAPIURL, useOrientation, useTableOrMobile } from '../../hooks';
import { cannonStyled } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';

type IWrapper = {
  isCentered?: boolean;
  isPadded?: boolean;
  noScroll?: boolean;
  direction?: 'vertical' | 'horizontal';
  main?: boolean;
  autoHeight?: boolean;
  compact?: boolean;
  socialHidden?: boolean;
  $theme?: CannonSafeThemeT;
};

export default cannonStyled('div', (props: IWrapper) => {
  const [isMobile, isTablet] = useTableOrMobile();
  const { isRotated } = useOrientation();
  const CORE_APIURL = useCoreAPIURL();

  const hideNavBar = isMobile && isRotated;

  const padded = 'calc((100% - 1280px) / 2)';

  return {
    ...(isMobile || isTablet
      ? {
          paddingLeft: props.isPadded ? '5%' : '0px',
          paddingRight: props.isPadded ? '5%' : '0px',
        }
      : {
          marginRight: props.isPadded ? padded : '0px',
          marginLeft: props.isPadded ? padded : '0px',
        }),
    overflowY: props.noScroll ? 'hidden' : 'scroll',
    ...(!props.compact
      ? {
          [props.main ? 'height' : 'minHeight']: `calc(100vh - ${
            props.socialHidden && hideNavBar
              ? '0px'
              : props.socialHidden && !hideNavBar
              ? '75px'
              : '113px'
          })`,
        }
      : {}),
    position: 'relative',
    backgroundColor: props.$theme.cannonColors.primaryWhiteLight,
    ...(props.isCentered
      ? {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent: 'normal',
          flexDirection: props.direction === 'vertical' ? 'column' : 'row',
        }
      : {}),
    ...(props.autoHeight ? { height: 'auto' } : {}),
  };
});
