import React from 'react';

import Underline from './Underline';
import Underlined from '../../Label/Underlined';
import NewsCard from '../../Cards/NewsCard';
import { useCannonStyletron } from '../../../theme';
import { CenteredContainer } from '../../Container';
import { useCLN } from '../querys/queryCLasNew';
import { Link } from 'gatsby';
import { useAPIURL, useTableOrMobile } from '../../../hooks';

export default () => {
  const [css, theme] = useCannonStyletron();
  const [isMobile] = useTableOrMobile();
  const data = useCLN();
  const API_URL = useAPIURL();

  const itemsToMap =
    data.allStrapiBlogEntries.nodes.length > 3
      ? data.allStrapiBlogEntries.nodes.slice(0, 3)
      : data.allStrapiBlogEntries.nodes.slice(
          0,
          data.allStrapiBlogEntries.nodes.length
        );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          padding: isMobile ? '0px 0px 10px 30px' : '',
        }}
      >
        <Underlined color={theme.cannonColors.primaryWhiteLight}>
          {data.strapiLatestNews.header}
        </Underlined>
      </div>
      {itemsToMap.map((item) => { 
        const categoryUrl = item.blogCategory.Name.trim().toLowerCase().replace(/\./g, '').replace(/ /g, '-');
        return(
        <div onClick={()=> window.location.href=`/blog/${categoryUrl}/${item.slug}`} className={css({ textDecorationLine: 'none' })}>
          <NewsCard
            imgSrc={API_URL + item.previewImage[0]?.url}
            title={item.title}
            description={item.previewText}
          />
        </div>
        )})}
    </div>
  );
};
