import { H4 } from 'baseui/typography';
import React, { useState } from 'react';
import { Button } from '..';
import { cannonStyled, TWH3, useCannonStyletron } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import Animation, {
  AppearUp,
  FadeOutDown,
  _SlideInUp,
  _SlideInDown,
} from '../Animations';

import ArrowRight from '../  ../../../assets/arrow-right.svg';
import CenteredContainer from '../Container/CenteredContainer';
import { useTableOrMobile } from '../../hooks';
import { AspectRatioBox } from 'baseui/aspect-ratio-box';

type ICardContainer = {
  isMobile: boolean;
  $theme?: CannonSafeThemeT;
};

const CardContainer = cannonStyled(AspectRatioBox, (props: ICardContainer) => {
  const { $theme: theme } = props;

  if (props.isMobile)
    return {
      position: 'relative',
      height: '350px',
      width: '100%',
      borderRadius: theme.sizing.scale300,
      margin: '10px',
      backgroundColor: theme.cannonColors.primaryGray,
      alignSelf: 'center',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '10px',
      objectFit: 'cover',
      overflow: 'hidden',
      transition: 'box-shadow 2s ease',
      ':hover': {
        boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
      },
    };
  else
    return {
      position: 'relative',
      height: '376px',
      width: '100%',
      borderRadius: theme.sizing.scale300,

      backgroundColor: theme.cannonColors.primaryGray,
      alignSelf: 'center',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '10px',
      objectFit: 'cover',
      overflow: 'hidden',
      transition: 'box-shadow 1s ease',
      ':hover': {
        boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
      },
    };
});

const CardContentContainer = cannonStyled('div', {
  position: 'absolute',
  top: '0px',
  left: '0px',
  bottom: '0px',
  right: '0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(0,0,0,0.2)',
  transitionDuration: '1500ms',
  transitionProperty: 'background',
  transitionTimingFunction: 'ease',
  cursor: 'pointer',
  ':hover': {
    background: 'none',
  },
});

const CardBackgroundImage = cannonStyled('img', (props) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: props.isMobile ? '100%' : '400px',
  objectFit: 'cover',
  zIndex: 0,
}));

const ImageCard = ({ src, title = '', actionTitle, actionURL = '/safes' }) => {
  const [css, theme] = useCannonStyletron();
  const [hovered, setHovered] = useState(false);
  const [isMobile, isTablet] = useTableOrMobile();

  const centeredClass = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
    marginBottom: '-40px',
  });

  return (
    <CenteredContainer>
      <CardContainer
        isMobile={isMobile}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        aspectRatio={isMobile ? 144 / 320 : 2 / 30}
      >
        <CardBackgroundImage isMobile={isMobile} src={src} alt={title} />
        <CardContentContainer
          onClick={() => {
            window.location.href = actionURL;
          }}
        >
          <Animation>
            <div
              style={
                !isMobile && !isTablet && hovered
                  ? _SlideInUp
                  : isMobile || isTablet
                  ? {}
                  : _SlideInDown
              }
            >
              <TWH3
                color={theme.cannonColors.primaryWhiteLight}
                align='center'
                weight={700}
              >
                {title}
              </TWH3>
            </div>
            <div
              className={centeredClass}
              style={
                hovered && !isMobile && !isTablet
                  ? AppearUp
                  : !hovered && !isMobile && !isTablet
                  ? FadeOutDown
                  : isMobile
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <Button.RoundedButton
                size='mini'
                color='clear'
                endEnhancer={
                  <ArrowRight
                    style={{ stroke: theme.cannonColors.primaryBlue }}
                  />
                }
              >
                {actionTitle}
              </Button.RoundedButton>
            </div>
          </Animation>
        </CardContentContainer>
      </CardContainer>
    </CenteredContainer>
  );
};

export default ImageCard;
