import React from 'react';
import { cannonStyled } from '../../theme';
import {
  CannonSafeThemeT,
  useCannonStyletron,
} from '../../theme/CannonSafesTheme';
import { useTableOrMobile } from '../../hooks';
import { AspectRatioBox } from 'baseui/aspect-ratio-box';
import { NSH5, ABH1, TWButtonMini } from '../../theme/Typography';
import { useOrientation } from '../../hooks/useOrientation';
import { useMediaQuery } from 'react-responsive';
import useWindowDimensions from '../../hooks/useWindowDimensions';

type ICardContainer = {
  isMobile?: boolean;
  isTablet?: boolean;
  isRotated?: boolean;
  isMinHeight?: boolean;
  $theme?: CannonSafeThemeT;
  responsiveHeight?: string;
  height?: string;
  isMedium?: boolean;
  isSmall?: boolean;
};

export const CardContainer = cannonStyled(
  AspectRatioBox,
  (props: ICardContainer) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: props.$theme.sizing.scale300,
    padding: props.isMobile
      ? '14px 14px 0 14px'
      : `${props.$theme.sizing.scale600} ${props.$theme.sizing.scale600} 0`,
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: props.isMobile
      ? props.isMinHeight
        ? '55vh'
        : '300px'
      : '500px',
    overflowX: 'clip',
    width: '90%',
    backgroundColor: 'white',
    borderRadius: '8px',
  })
);

export const TextWrap = cannonStyled('div', (props: ICardContainer) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  width: '100%',
  overflow: 'hidden',
  marginTop: props.isMobile ? 0 : '10px',
}));

export const ElementsContainer = cannonStyled('div', {
  height: '100%',
  width: '97%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  padding: '5px auto 0',
});

export const FindYourSafeContainer = cannonStyled(
  'div',
  (props: ICardContainer) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: props.isMobile ? '40vh' : '72vh',
  })
);

export const HorizontalFlex = cannonStyled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '18px',
});

export const ImgWrap = cannonStyled(
  'div',
  (props: ICardContainer & { bgImage: string }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: props.isSmall
      ? '50%'
      : props.isMobile
      ? '30%'
      : props.isMedium
      ? '45%'
      : props.isTablet
      ? '40%'
      : '49%',
    width: '99%',
    paddingTop: '80px',
    overflow: 'hidden',
    margin: '0 auto',
    backgroundImage: `url(${props.bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: props.isMobile
      ? 'auto 115%'
      : props.isMedium
      ? 'auto 115%'
      : props.isTablet
      ? 'auto 135%'
      : 'auto 145%',
    backgroundPosition: props.isMobile
      ? 'center 10px'
      : props.isMedium
      ? 'center 20px'
      : props.isTablet
      ? 'center -13px'
      : 'center -15px',
  })
);

interface ISafeCardProps {
  displayImage: string;
  isMinHeight: boolean;
  title: string;
  subTitle: string;
  description: string;
  slug: string;
  shortButton?: boolean;
  count: number;
  colorText: string;
  action: () => {};
}

const Card: React.FC<ISafeCardProps> = ({
  displayImage,
  title,
  subTitle,
  isMinHeight,
  description,
  count,
  action,
  slug,
  colorText,
}) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const { isRotated } = useOrientation();

  const isMedium = useMediaQuery({ query: '(max-width: 1000px)' });
  const isSmall = useMediaQuery({ query: '(max-width: 390px)' });

  const finalDescription = description.substring(0, 150) + ' ...';
  const finalSubTitle = subTitle.substring(0, 26) + ' ...';

  return (
    <CardContainer
      isMobile={isMobile}
      isTablet={isTablet}
      isRotated={isRotated}
      isMinHeight={isMinHeight}
      aspectRatio={1 / 4}
    >
      <ElementsContainer>
        <TextWrap isRotated={isRotated} isMobile={isMobile}>
          <NSH5
            weight={900}
            color={colorText}
            align='left'
            margin={0}
            $style={{
              fontSize: isMobile ? `min(4vw, 24px)` : `min(3.6vw, 24px)`,
            }}
          >
            {title}
          </NSH5>
          <ABH1
            weight={700}
            align='left'
            margin={isMobile ? 8 : 11}
            color={theme.cannonColors.primaryGray}
            $style={{
              fontSize: isMobile ? '15px' : '17px',
              lineHeight: isMobile ? '12px' : 'auto',
            }}
          >
            {finalSubTitle}
          </ABH1>
          {!isMinHeight && !isSmall && (
            <TWButtonMini
              weight={400}
              align='left'
              margin={0}
              color='#4e4e4e'
              $style={{
                fontSize: isMobile ? '14px' : '16px',
                lineHeight: isMobile
                  ? '15px'
                  : isMedium
                  ? '19px'
                  : isTablet
                  ? '19px'
                  : '19px',
              }}
            >
              {finalDescription}
            </TWButtonMini>
          )}
        </TextWrap>
        <ImgWrap
          bgImage={displayImage}
          isMobile={isMobile}
          isTablet={isTablet}
          isMedium={isMedium}
          isSmall={isSmall}
        >
          &nbsp;
        </ImgWrap>
      </ElementsContainer>
    </CardContainer>
  );
};

export default Card;
