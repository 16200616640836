import { graphql, useStaticQuery } from 'gatsby';
import { ICannonSafeSeriesResponse } from '../../../templates/safesSeries';

//TODO: change in Strapi the relation between pages to cannon_safes_series

// const query = graphql`
//   query ProductLinesFooter {
//     strapiProductLines {
//       header
//       cannon_safe_series {
//         title
//         slug
//       }
//     }
//   }
// `;

const query = graphql`
  query ProductLinesFooter {
    strapiProductLines {
      header
      cannonSafeSeries {
        title
        slug
      }
    }
  }
`;

interface ProdArray {
  href: string;
  title: string;
}

interface IColProdLines {
  strapiProductLines: {
    header: string;
    cannonSafeSeries: Array<ICannonSafeSeriesResponse>;
    pages: Array<ProdArray>;
  };
}

export const useCPL = (): IColProdLines => useStaticQuery(query);
